//import _ from "lodash";
import { transform, isObject, isEmpty, isArray } from 'lodash';

// A class with common functions shared between all the components in the application:
export default class Utils {

    static getCurrentTime() {
        const d = new Date();
        return d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + ':' + d.getMilliseconds();
    }

    static validateIPaddress(ipaddress, nullable) {
        if (nullable && (ipaddress === null || ipaddress === "" || ipaddress === "...")) {
            return true;
        }
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return true;
        }
        return false;
    }

    static diff(obj1, obj2) {
        const result = {};
        if (Object.is(obj1, obj2)) {
            return undefined;
        }
        if (!obj2 || typeof obj2 !== 'object') {
            return obj2;
        }
        Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(key => {
            if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
                result[key] = obj2[key];
            }
            if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
                const value = Utils.diff(obj1[key], obj2[key]);
                if (value !== undefined) {
                    result[key] = value;
                }
            }
        });

        let retval = Utils.clean(result);
        return retval;
    }

    static clean(el) {
        function internalClean(el) {
            return transform(el, function (result, value, key) {
                var isCollection = isObject(value);
                var cleaned = isCollection ? internalClean(value) : value;

                if (isCollection && isEmpty(cleaned)) {
                    return;
                }

                isArray(result) ? result.push(cleaned) : (result[key] = cleaned);
            });
        }

        return isObject(el) ? internalClean(el) : el;
    }

    static isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    static hasString(str) {
        if (str !== undefined && str !== null && str !== '') {
            return true;
        } else {
            return false;
        }
	}

	static escapeSpecialChars(jsonString) {
		return jsonString.replace(/\n/g, "\\n")
			.replace(/\r/g, "\\r")
			.replace(/\t/g, "\\t")
			.replace(/\f/g, "\\f");
	}
}

export const CONNECTED = 'Connected';
export const DISCONNECTED = 'Disconnected';
export const HOMESCREEN_FULL = 'full';
export const HOMESCREEN_FULL_CENTER = 'full-center';
export const HOMESCREEN_FULL_LEFT = 'full-left';
export const HOMESCREEN_FULL_RIGHT = 'full-right';
export const HOMESCREEN_MINIMAL = 'minimal';
export const HOMESCREEN_NONE = 'none';
export const SESSION_DEFAULT = 'default';
export const SESSION_SINGLE = 'single';
export const SESSION_FOCUS = 'focus';
export const SESSION_GRID = 'grid';
export const TURN_DISPLAY_OFF_INACTIVE_NEVER = '0';
export const TURN_DISPLAY_OFF_INACTIVE_IMMEDIALLY = '1';
export const TURN_DISPLAY_OFF_INACTIVE_1_MINUTE = '2';
export const TURN_DISPLAY_OFF_INACTIVE_15_MINUTES = '3';
export const TURN_DISPLAY_OFF_INACTIVE_1_HOUR = '4';
export const TURN_DISPLAY_OFF_INACTIVE_3_HOURS = '5';
export const SAMPLE_RATE_44100 = '44100 Hz';
export const SAMPLE_RATE_48000 = '48000 Hz';
export const SAMPLE_RATE_96000 = '96000 Hz';
export const SAMPLE_RATE_192000 = '192000 Hz';
export const ENGLISH = 'english';
export const DEUTSCH = 'deutsch';
export const ICELANDIC = 'icelandic';
export const HOUR_12 = '12-hour';
export const HOUR_24 = '24-hour';
export const HOUR_OFF = 'off';
export const UTC = 'UTC';

export const dummyRoles = [
	{
		name: 'Cloud Admin',
		groups: 'ALL',
		canCreateUsers: true
	},
	{
		name: 'Device Admin',
		groups: 'ALL',
		canCreateUsers: false
	},
	{
		name: 'East Building Admin',
        groups: 'EB Floor 1, EB Floor 2, EB Floor 3, EB Floor 4',
		canCreateUsers: false
	},
	{
		name: 'West Building Admin',
		groups: 'WB Floor 1, WB Floor 2',
		canCreateUsers: false
	}
];

export const dummyUsers = [
	{
		name: 'Robert Watson',
		email: 'robert@walmart.com',
		role: 0,
		created: '27/03/2019'
	},
	{
		name: 'Charles Burns',
		email: 'charlie@walmart.com',
		role: 1,
		created: '06/07/2020'
	},
	{
		name: 'Susan Chambers',
		email: 'susan7@walmart.com',
		role: 2,
		created: '18/11/2020'
	},
	{
		name: 'Someone with a really really longerester name',
		email: 'someone_with_a_really_really_long_name@walmart.com',
		role: 0,
		created: '12/10/2020'
	}
];

export const dummyAADGroups = [
	{
		group: 'Team Bears',
		role: 'East Building Admin'
	},
	{
		group: 'Team Foxes',
		role: 'West Building Admin'
	}
];
