import React, { useState } from 'react';
import { Button, Container, Col, Row, Form } from 'react-bootstrap';
import '../main.css';
import authService from './api-authorization/AuthorizeService';
import { v4 as uuidv4 } from 'uuid';

export default function CreateGroupPane({ paneVisible, closeCreateGroupPane, displayNotification, displayError }) {
    const [groupName, set_groupName] = useState('');

    function handleCreateGroupClick() {
        let groupDeviceId = uuidv4();

        authService.getAccessToken()
            .then(token =>
                fetch(`CloudManagement/CreateGroup`,
                    {
                        body: JSON.stringify({ deviceId: groupDeviceId, groupName }),
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        method: 'POST'
                    })
            )
            .then(response => response.json())
            .then(data => {
                console.log(`CreateGroup returned: '${JSON.stringify(data)}'`);
                if (data.status !== undefined && data.status !== null) {
                    if (data.status === 409) {
                        displayError('Group already exists');
                        closeCreateGroupPane();
                        return;
                    } else if (data.status === 401) {
                        handleUnauthorized();
                        return;
                    }
                }

                if (data && data === '409') {
                    displayError(`${groupName} already exists`);
                    closeCreateGroupPane();
                    set_groupName('');
                    return;
                }

                closeCreateGroupPane();
                displayNotification(`Creating ${groupName}...`);
                set_groupName('');
            })
            .catch(error => {
                if (error.message.includes('401')) {
                    handleUnauthorized();
                    return;
                }
            });
    }

    function handleUnauthorized() {
        // Close Create Group pane and show a notification that the user should log in again:
        displayError('Please log in again');
        set_groupName('');
        closeCreateGroupPane();
    }

    function handleCreateGroupCancel() {
        set_groupName('');
        closeCreateGroupPane();
    }

    function handleGroupNameChange(e) {
        set_groupName(e.target.value);
    }

    function handleGroupNameKeyPress(e) {
        var code = e.keyCode || e.which;
        if (code === 13) { //13 is the enter keycode
            handleCreateGroupClick();
        }
    }

    if (!paneVisible) {
        return (
            <div id='createGroupPane' className='hide'>
            </div>
        );
    } else {
        return (
            <Container id='createGroupPane' className='show'>
                <Row>
                    <Col>
                        <h3>Create Group</h3>
                    </Col>
                    <Col className='text-right'>
                        <Button className='saveButton elementMargin' variant='primary' onClick={handleCreateGroupClick} disabled={groupName.length === 0 ? true : false} >APPLY</Button>
                        <Button className='saveButton' variant='secondary' onClick={handleCreateGroupCancel}>CANCEL</Button>
                    </Col>
                </Row>
                
                <Row className='rowEnterGroupName'>
                    <Col>
                        <Form.Control autoFocus type='text' placeholder='Enter group name' value={groupName} onChange={handleGroupNameChange} onKeyPress={handleGroupNameKeyPress} />
                    </Col>
                </Row>
            </Container>
        );
    }
}
