import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Modal } from "react-bootstrap";

export default function UserAddEdit({ showModal, handleAddUser, handleEditUser, hideModal, roles, type, userForEditing }) {
	const [roleId, set_roleId] = useState('');
	const [name, set_name] = useState('');
	const [email, set_email] = useState('');

	const initState = useCallback(() => {
		// When adding users, display Device Admins first in the list of roles:
		const deviceAdminRole = roles.find(r => r.name === 'Device Admin');
		if (deviceAdminRole) {
			set_roleId(deviceAdminRole.id);
		}
		else if (roles.length > 0) {
			set_roleId(roles[0].id);
		}
		set_name('');
		set_email('');
	}, [roles]);

	useEffect(() => {
		if (userForEditing !== null) {
			set_roleId(userForEditing.role.id);
			set_name(userForEditing.name);
			set_email(userForEditing.email);
		} else {
			initState();
        }
	}, [roles, userForEditing, initState]);

	function title() {
		if (type === 'add') return 'Add User';
		else return `Edit ${name}`;
	}

	async function actionButtonClicked() {
		if (type === 'add') await handleAddUser(roleId, name, email);
		else await handleEditUser(userForEditing.id, roleId, name, email);

		initState();
	}
	
	function cancelButtonClicked() {
		initState();
		hideModal();
	}

	return (
		<Modal show={showModal} onHide={hideModal} centered className='addUserModal'>
			<Modal.Header closeButton>
				<Modal.Title>{title()}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group>
					<Form.Label>Name</Form.Label>
					<Form.Control placeholder='Enter Name' value={name} onChange={e => set_name(e.target.value)} />
				</Form.Group>

				<Form.Group>
					<Form.Label>Email</Form.Label>
					<Form.Control type='email' placeholder='Enter Email' value={email} onChange={e => set_email(e.target.value)} />
				</Form.Group>

				<Form.Group>
					<Form.Label>Role</Form.Label>
					<Form.Control as='select' value={roleId} onChange={e => set_roleId(e.target.value)}>
						{
							roles.map(r => <option key={r.id} value={r.id}>{ r.name }</option>)
                        }
					</Form.Control>
				</Form.Group>

				{
					type === "add" &&
					<Form.Group>
						<p>{`Clicking on "APPLY" will send an email to the new user with a link from which he/she can register and use this site.`}</p>
					</Form.Group>
				}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={actionButtonClicked}>
					APPLY
				</Button>
				<Button variant="secondary" onClick={cancelButtonClicked}>
					CANCEL
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
