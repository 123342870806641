import React, { Fragment } from 'react';
import { Form, Col } from 'react-bootstrap';
import PlusX from '../PlusX';
import Switch from '../Switch';
import { HOMESCREEN_FULL, HOMESCREEN_FULL_CENTER, HOMESCREEN_FULL_LEFT, HOMESCREEN_FULL_RIGHT, HOMESCREEN_MINIMAL, HOMESCREEN_NONE, SESSION_DEFAULT, SESSION_GRID, SESSION_SINGLE, SESSION_FOCUS } from '../Utils';

export default function PersonalizationTab({ multipleEdit, name, handleChangeName, background, handleChangeBackground, checked_background, setChecked_background, backgroundValid,
    homescreenLayout, set_homescreenLayout, checked_homescreenLayout, setChecked_homescreenLayout,
    showHowToConnect, set_showHowToConnect, checked_showHowToConnect, setChecked_showHowToConnect,
    showWiFiQrCode, set_showWiFiQrCode, checked_showWiFiQrCode, setChecked_showWiFiQrCode,
    showGuestAccessWhileInUse, set_showGuestAccessWhileInUse, checked_showGuestAccessWhileInUse, setChecked_showGuestAccessWhileInUse,
    sessionLayout, set_sessionLayout, checked_sessionLayout, setChecked_sessionLayout,
    displayYourOwnNetwork, handleToggleDisplayYourOwnNetwork, checked_displayYourOwnNetwork, setChecked_displayYourOwnNetwork,
    wifiSsid, set_wifiSsid, wifiPassword, set_wifiPassword }) {

    function mapHomescreenLayout() {
        if (multipleEdit && !checked_homescreenLayout) return null;
        let retval = [];
        retval.push(<option key={HOMESCREEN_FULL} value={HOMESCREEN_FULL}>Automatic</option>);
        retval.push(<option key={HOMESCREEN_FULL_CENTER} value={HOMESCREEN_FULL_CENTER}>Center</option>);
        retval.push(<option key={HOMESCREEN_FULL_LEFT} value={HOMESCREEN_FULL_LEFT}>Left</option>);
        retval.push(<option key={HOMESCREEN_FULL_RIGHT} value={HOMESCREEN_FULL_RIGHT}>Right</option>);
        retval.push(<option key={HOMESCREEN_MINIMAL} value={HOMESCREEN_MINIMAL}>Minimal</option>);
        retval.push(<option key={HOMESCREEN_NONE} value={HOMESCREEN_NONE}>None</option>);
        return retval;
    }

    function dropdownChangeHomescreenLayout(e) {
        set_homescreenLayout(e.target.value);
    }

    function mapSessionLayout() {
        if (multipleEdit && !checked_sessionLayout) return null;
        let retval = [];
        retval.push(<option key={SESSION_DEFAULT} value={SESSION_DEFAULT}>Default</option>);
        retval.push(<option key={SESSION_GRID} value={SESSION_GRID}>Grid</option>);
        retval.push(<option key={SESSION_FOCUS} value={SESSION_FOCUS} disabled>Focus (Coming Soon)</option>);
        retval.push(<option key={SESSION_SINGLE} value={SESSION_SINGLE}>Single</option>);
        return retval;
    }

    function dropdownChangeSessionLayout(e) {
        set_sessionLayout(e.target.value);
    }

    function getBackgroundCss() {
        let retval = backgroundValid ? '' : 'formIsInvalid';
        if (multipleEdit && !checked_background) retval += ' form-control-disabled'
        return retval;
    }

    return (
        <Form autoComplete='off'>
            <Form.Label className='tabLabel'>Personalization</Form.Label>

            {/*Friendly Name*/}
            {!multipleEdit ? (
                <Form.Row>
                    <Col xs='6'>
                        <Form.Group controlId="form_name">
                            <Form.Label>Name/Room</Form.Label>
                            <Form.Control type='text' value={name} onChange={handleChangeName} maxLength='21'
                                placeholder={multipleEdit ? '' : 'Enter text'}
                                    className={!multipleEdit ? '' : 'short-form-control-disabled'} disabled={multipleEdit} />
                        </Form.Group>
                    </Col>
                </Form.Row>
            ) : null}

            {/*Background URL*/}
            <Form.Group controlId="form_background" className='groupBackgroundUrl'>
                <Form.Label className={!multipleEdit ? '' : 'labelWithPlusX'}>Background</Form.Label>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Control type='text' value={background} onChange={e => handleChangeBackground(e.target.value)}
                            placeholder={(multipleEdit && !checked_background) ? '' : 'Enter background url'} className={getBackgroundCss()} disabled={multipleEdit && !checked_background} maxLength={512} />
                    </Col>
                    <Col xs='1'></Col>
                    <Col xs='5'>
                        <PlusX name='plusX_background' checked={checked_background} setChecked={setChecked_background} multipleEdit={multipleEdit} className='plusX_background' />
                    </Col>
                </Form.Row>
                {
                    backgroundValid ? null : (<Form.Label className='errorInputLong'>Please provide a valid background URL</Form.Label>)
                }
            </Form.Group>

            {/*Homescreen Layout*/}
            <Form.Group>
                <Form.Row>
                    <Col>
                        <Form.Label>Homescreen Layout</Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Control as='select' onChange={dropdownChangeHomescreenLayout} value={homescreenLayout}>
                            {mapHomescreenLayout()}
                        </Form.Control>
                    </Col>
                    <Col xs='1'></Col>
                    <Col xs='5'>
                        <PlusX name='plusX_homescreenLayout' checked={checked_homescreenLayout} setChecked={setChecked_homescreenLayout} multipleEdit={multipleEdit} className='plusX_homescreenLayout' />
                    </Col>
                </Form.Row>
            </Form.Group>

            {/*Session Layout*/}
            <Form.Group>
                <Form.Row>
                    <Col>
                        <Form.Label>Session Layout</Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Control as='select' onChange={dropdownChangeSessionLayout} value={sessionLayout}>
                            {mapSessionLayout()}
                        </Form.Control>
                    </Col>
                    <Col xs='1'></Col>
                    <Col xs='5'>
                        <PlusX name='plusX_sessionLayout' checked={checked_sessionLayout} setChecked={setChecked_sessionLayout} multipleEdit={multipleEdit} className='plusX_sessionLayout' />
                    </Col>
                </Form.Row>
            </Form.Group>
            <Form.Group>
            {
                (homescreenLayout !== HOMESCREEN_MINIMAL) && (
                    <Fragment>
                        {/*Show How To Connect*/}
                        <Form.Row>
                            <Col className='switchCol' xs='6'>
                                <Switch isOn={showHowToConnect} handleToggle={() => { set_showHowToConnect(!showHowToConnect) }} labelText='Show How To Connect' visible={!(multipleEdit && !checked_showHowToConnect)} />
                            </Col>
                            <Col xs='1'></Col>
                            <Col xs='5'>
                                <PlusX name='plusX_showHowToConnect' checked={checked_showHowToConnect} setChecked={setChecked_showHowToConnect} multipleEdit={multipleEdit} className='plusX_showHowToConnect' />
                            </Col>
                        </Form.Row>

                        {/*Show QR Code*/}
                        <Form.Row>
                            <Col className='switchCol' xs='6'>
                                <Switch isOn={showWiFiQrCode} handleToggle={() => { set_showWiFiQrCode(!showWiFiQrCode) }} labelText='Show QR Code' visible={!(multipleEdit && !checked_showWiFiQrCode)} />
                            </Col>
                            <Col xs='1'></Col>
                            <Col xs='5'>
                                <PlusX name='plusX_showWiFiQrCode' checked={checked_showWiFiQrCode} setChecked={setChecked_showWiFiQrCode} multipleEdit={multipleEdit} className='plusX_showWiFiQrCode' />
                            </Col>
                        </Form.Row>
                    </Fragment>
                )
            }

            {/*Show Guest Access While In Use*/}
            <Form.Row>
                <Col className='switchCol' xs='7'>
                    <Switch isOn={showGuestAccessWhileInUse} handleToggle={() => { set_showGuestAccessWhileInUse(!showGuestAccessWhileInUse) }} labelText='Show Guest Access While In Use'
                        visible={!(multipleEdit && !checked_showGuestAccessWhileInUse)} />
                </Col>
                <Col xs='5'>
                    <PlusX name='plusX_showGuestAccessWhileInUse' checked={checked_showGuestAccessWhileInUse} setChecked={setChecked_showGuestAccessWhileInUse} multipleEdit={multipleEdit}
                        className='plusX_showGuestAccessWhileInUse' />
                </Col>
            </Form.Row>

			{/*Show Your Own Guest Network*/}
                <Form.Row>
                    <Col className='switchCol' xs='7'>
                        <Switch isOn={displayYourOwnNetwork} handleToggle={handleToggleDisplayYourOwnNetwork} labelText='Show Your Own Guest Network' visible={!(multipleEdit && !checked_displayYourOwnNetwork)} />
                    </Col>
                    <Col xs='5'>
                        <PlusX name='plusX_displayYourOwnNetwork' checked={checked_displayYourOwnNetwork} setChecked={setChecked_displayYourOwnNetwork} multipleEdit={multipleEdit} className='plusX_displayYourOwnNetwork' />
                    </Col>
                </Form.Row>
				{
					!(!displayYourOwnNetwork || (multipleEdit && !checked_displayYourOwnNetwork)) &&
					<Fragment>
						<Form.Row className='ssidRow'>
							<Col xs='6'>
								<Form.Group>
									<Form.Label>
										Network SSID
										</Form.Label>
									<Form.Control type='text' value={wifiSsid} onChange={(e) => { set_wifiSsid(e.target.value) }} placeholder="Enter ssid" maxLength="150" />
								</Form.Group>
							</Col>
						</Form.Row>
						<Form.Row>
							<Col xs='6'>
								<Form.Group>
									<Form.Label>
										Wi-Fi Password
									</Form.Label>
									<Form.Control type='text' value={wifiPassword === 0 ? '' : wifiPassword} onChange={(e) => { set_wifiPassword(e.target.value) }}
										placeholder="Enter password" maxLength="150" />
								</Form.Group>
							</Col>
						</Form.Row>
					</Fragment>
				}
            </Form.Group>


        </Form>
    );
}
