import React, { useState, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import Layout from './components/Layout';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import './main.css';
import MainPage from './components/MainPage';
import UserManagement from './components/UserManagement/UserManagement';
import FirstLogin from './components/UserManagement/FirstLogin';
import { DISCONNECTED } from './components/Utils';
import authService from './components/api-authorization/AuthorizeService';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function App() {
    const [detailsPaneVisible, set_detailsPaneVisible] = useState(false);
    const [createGroupPaneVisible, set_createGroupPaneVisible] = useState(false);
    const [addingDevice, set_addingDevice] = useState(false);
    const [selectedDeviceId, set_selectedDeviceId] = useState('');
    const [selectedDeviceConnectionState, set_selectedDeviceConnectionState] = useState(DISCONNECTED);
    const [numSelectedDevices, set_numSelectedDevices] = useState(0);
    const [multipleEdit, set_multipleEdit] = useState(false);
    const [loggedIn, set_loggedIn] = useState(false);
    const [noGroupsCreated, set_noGroupsCreated] = useState(true);
	const [selectedPage, set_selectedPage] = useState('Devices');
	const [canManageUsers, set_canManageUsers] = useState(false);

	const TIMEOUT = 20000; // Timeout in milliseconds.

    useEffect(() => {
        if (loggedIn) {
            async function checkIfUserCanManageOtherUsers() {
                try {
                    authService.getAccessToken().then(token => {
                        if (token == null || Object.keys(token).length === 0) {
                            // Token is empty.
                            return;
                        }

                        axios.get('UserManagement/CanManageUsers', {
                            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                            timeout: TIMEOUT
                        }).then(response => {
                            set_canManageUsers(response.data.canManageUsers);
                        })
                    });
                } catch (error) {
                    toast.error('Unexpected error while checking if this user can manage other users. Check with helpdesk or try again later.')
                }
            }

            checkIfUserCanManageOtherUsers();
        }
    }, [loggedIn, set_canManageUsers]);
    
    function numGroups(num) {
        set_noGroupsCreated(num === 0 ? true : false);
    }

    function online(yes) {
        set_loggedIn(yes);
    }

    function addDevice() {
        set_addingDevice(true);
    }

    function closeAddDevicePane() {
        set_addingDevice(false);
    }

    function createGroup() {
        set_createGroupPaneVisible(true);
    }

    function editMultipleDevices() {
        set_detailsPaneVisible(true);
        set_multipleEdit(true);
    }

    function closeCreateGroupPane() {
        set_createGroupPaneVisible(false);
    }

    function closeDetailsPane() {
        set_detailsPaneVisible(false);
        set_selectedDeviceId('');
        set_addingDevice(false);
        set_multipleEdit(false);
    }

    function openDetailsPane(deviceId, connectionState) {
        set_detailsPaneVisible(true);
        set_selectedDeviceId(deviceId);
        set_selectedDeviceConnectionState(connectionState);
    }

    function handleNumSelectedDevices(count) {
        set_numSelectedDevices(count);
	}

    return (
        <Layout editMultipleDevices={editMultipleDevices} createGroup={createGroup} addDevice={addDevice} detailsPaneVisible={detailsPaneVisible} numSelectedDevices={numSelectedDevices}
            loggedIn={loggedIn} noGroupsCreated={noGroupsCreated} selectedPage={selectedPage} set_selectedPage={set_selectedPage} canManageUsers={canManageUsers} >

            <Switch>
                <AuthorizeRoute exact path='/devices' component={MainPage} detailsPaneVisible={detailsPaneVisible} createGroupPaneVisible={createGroupPaneVisible} addingDevice={addingDevice}
                    closeDetailsPane={closeDetailsPane} openDetailsPane={openDetailsPane} selectedDeviceId={selectedDeviceId} closeCreateGroupPane={closeCreateGroupPane} handleNumSelectedDevices={handleNumSelectedDevices}
                    multipleEdit={multipleEdit} online={online} numGroups={numGroups} selectedDeviceConnectionState={selectedDeviceConnectionState}
                    closeAddDevicePane={closeAddDevicePane} canManageUsers={canManageUsers} />

                {
                    canManageUsers && <AuthorizeRoute exact path='/users' component={UserManagement} />
                }

                <Route exact path='/first-login' render={(props) => <FirstLogin {...props} />} />

                <Route path={ApplicationPaths.ApiAuthorizationPrefix} render={(props) => <ApiAuthorizationRoutes {...props} online={online} />} />

                <Redirect exact from="/" to="/devices" />
            </Switch>
        </Layout >
    );
}
