import authService from '../api-authorization/AuthorizeService';
import axios from "axios";
//import { toast } from 'react-toastify';

export default class IOTHandler {
	TIMEOUT = 20000; // Timeout in milliseconds.

	static async fetchGroups(set_iotGroups, set_error500, organizationId, set_fetchedIotGroups) {
		try {
			set_error500('');

			let token = await authService.getAccessToken();

			let response = await axios.get(`CloudManagement/GetGroups`, {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
				timeout: IOTHandler.TIMEOUT
			});

			const data = response.data;

			// Add a 'selected' attribute to each group that will be used in RoleAddEdit.js:
			for (const group of data) {
				group.selected = false;
			}

			// Save the data:
			set_iotGroups(data);
			set_fetchedIotGroups(true);
		} catch (error) {
			console.log('Error getting IOT Groups: ' + error);
			set_error500(error);
		}
	}
}
