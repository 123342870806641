import React from 'react';
import { Jumbotron, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPen, faUserTag, faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from 'react-tippy';

export default function Roles({ roles, handleShowAdd, handleShowEdit, getRoleClassByName, removeRoleClick, iotGroups }) {
	function getRoleGroupsClassByGroups(groups) {
		if (groups.length === 0) {
			return 'managesAllGroups';
		} else {
			return '';
        }
	}
	
	function getCanCreateData(role) {
		if (role.canManageUsers === true) {
			return <FontAwesomeIcon icon={faCheck} size='lg' className='canCreateUser' />;
		} else {
			return <FontAwesomeIcon icon={faTimes} size='lg' className='cantCreateUser' />;
        }
	}

	function getGroups(groupIds) {
		if (groupIds.length === 0) {
			return 'ALL';
		} else {
			let groupNames = [];
			for (const groupId of groupIds) {
				const group = iotGroups.find(g => g.deviceId === groupId);
				if (group) {
					groupNames.push(group.groupName);
				}
			}
			return groupNames.sort((n1, n2) => n1.localeCompare(n2)).join(', ');
        }
    }

	return (
		<Jumbotron>
			<Table className='rolesTable' hover>
				<thead>
					<tr class="userPageHeaderRow">
						<td colSpan='3' className='titleTd'>
							<FontAwesomeIcon icon={faUserTag} size='lg' />
							<h3>Roles</h3>
						</td>
						<td colSpan='3' className='addUserTd'><Button variant='primary' className='addUserButton' onClick={() => handleShowAdd()}>ADD ROLE</Button></td>
					</tr>
					<tr>
						<th>ROLE</th>
						<th>MANAGES THESE DEVICE GROUPS</th>
						<th>CAN MANAGE USERS</th>
						<th>EDIT</th>
						<th>REMOVE</th>
					</tr>
				</thead>
				<tbody>
					{
						roles && roles.map(role =>
							<tr key={role.name}>
								<td className={getRoleClassByName(role.name)}>{role.name}</td>
								<td className={getRoleGroupsClassByGroups(role.deviceGroupsManaged) + ' breakWord'}>{getGroups(role.deviceGroupsManaged)}</td>
								<td>{getCanCreateData(role)}</td>
								<td>
									<Tooltip title={'Edit ' + role.name} position='bottom' trigger='mouseenter' arrow='true'>
										<span>
											<FontAwesomeIcon icon={faPen} size='lg' className='editUserButton' onClick={() => { handleShowEdit(role); }} />
										</span>
									</Tooltip>
								</td>
								<td>
									<Tooltip title={'Remove ' + role.name} position='bottom' trigger='mouseenter' arrow='true'>
										<span>
											<FontAwesomeIcon icon={faTrashAlt} size='lg' className='removeUserButton' onClick={() => removeRoleClick(role)} />
										</span>
									</Tooltip>
								</td>
							</tr>
						)
					}
				</tbody>
			</Table>
		</Jumbotron>
	);
}
