import React from 'react';

export default function AutofillStopper() {
    return (
        <div>
            {/* fake fields are a workaround for chrome autofill getting the wrong fields */}
            <input style={{ display: 'none' }} type="text" name="fakeusernameremembered" />
            <input style={{ display: 'none' }} type="password" name="fakepasswordremembered" />
        </div>
    );
}
