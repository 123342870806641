import React from 'react';
import { Table } from 'react-bootstrap';
import '../main.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChromecast } from '@fortawesome/free-brands-svg-icons';
import { faTv, faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import airplayGreen from '../img/airplayGreen.svg';
import airplayWhite from '../img/airplayWhite.svg';
import { Tooltip, } from 'react-tippy';
import Utils, { DISCONNECTED } from './Utils';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

export default function AirServerList({ devices, detailsPaneVisible, openDetailsPane, groupId, isDeviceChecked, handleDeviceCheckChange, isGroupChecked,
    handleGroupCheckChange, expandedInUI }) {

    const INITIALIZING_COLUMN_TEXT = '';
    const INITIALIZING_TEXT = 'Initializing...';

    function handleRowClick(e, deviceId, connectionState) {
        if (deviceId && !detailsPaneVisible) {
            openDetailsPane(deviceId, connectionState);
        }

        e.stopPropagation();
    }

    function getProtocolIcons(device) {
        if (isInitializing(device)) {
            return INITIALIZING_COLUMN_TEXT;
        }

        let img0 = null, img1 = null, img2 = null;
        const secureColor = '#6ce9b5', insecureColor = 'white', offOpacity = 0.2;
        const services = device.config.services;

        if (services.airplay) {
            let title = 'AirPlay: ';
            let iconSrc;
            let customStyle = {}
            if (!services.airplay.enabled) {
                title += 'Off';
                iconSrc = airplayWhite;
                customStyle = { opacity: offOpacity };
            } else if (services.airplay.authMode === 0) {
                title += 'Everyone';
                iconSrc = airplayWhite;
            } else if (services.airplay.authMode === 1) {
                title += 'Code';
                iconSrc = airplayGreen;
            } else {
                title += 'Password';
                iconSrc = airplayGreen;
            }

            img0 = (<Tooltip title={title} position='bottom' trigger='mouseenter' arrow='true' className='airplayDiv'>
                <span>
                    <img src={iconSrc} className='airplayIcon' style={customStyle} height='20px' alt='' />
                        </span>
                    </Tooltip>);
        }

        if (services.googlecast) {
            let title = 'Google Cast: ';
            let iconColor;
            let customStyle = {}
            if (!services.googlecast.enabled) {
                title += 'Off';
                iconColor = insecureColor;
                customStyle = { opacity: offOpacity };
            } else if (services.googlecast.prompt) {
                title += 'Prompt';
                iconColor = secureColor;
            } else {
                title += 'Everyone';
                iconColor = insecureColor;
            }

            img1 = (<Tooltip title={title} position='bottom' trigger='mouseenter' arrow='true' className='googleCastDiv'>
                <span><FontAwesomeIcon icon={faChromecast} color={iconColor} className='googleCastIcon' style={customStyle} viewBox='50 20 380 380' /></span>
                    </Tooltip>);
        }

        if (services.miracast) {
            let title = 'Miracast: ';
            let iconColor;
            let customStyle = {}
            if (!services.miracast.enabled) {
                title += 'Off';
                iconColor = insecureColor;
                customStyle = { opacity: offOpacity };
            } else if (services.miracast.pin === true) {
                title += 'Code';
                iconColor = secureColor;
            } else {
                title += 'Everyone';
                iconColor = insecureColor;
            }

            img2 = (<Tooltip title={title} position='bottom' trigger='mouseenter' arrow='true' className='miracastDiv'>
                <span><FontAwesomeIcon icon={faTv} color={iconColor} className='miracastIcon' style={customStyle} viewBox='45 0 430 430' /></span>
                    </Tooltip>);
        }
 
        return (<div><nobr>{img0}{img1}{img2}</nobr></div>);
    }

    function isInitializing(device) {
        // See if perhaps the whole idea of "Initializing" state is not the best and not use it:
        return !device.config || !device.config.device;
    }

    function getCheckForUpdatesStr(device) {
        if (!isInitializing(device)) {
            if (!device.config.services || !device.config.services.updater) return "";

            if (device.config.services.updater.automaticUpdateEnabled) {
                return 'Automatic';
            } else {
                return 'Manual';
            }
        } else {
            return INITIALIZING_COLUMN_TEXT;
        }
    }

    function getVersion(device) {
        if (!isInitializing(device)) {
            if (!device.config.device.info) return "";
            return device.config.device.info.version ? device.config.device.info.version : '';
        } else {
            return INITIALIZING_COLUMN_TEXT;
        }
    }

    function getStatusText(device) {
        if (isInitializing(device)) {
            return INITIALIZING_TEXT;
        } else {
            return !deviceConnected(device) ? 'Offline' : 'Online'
        }
    }

    function getStatusTip(device) {
        if (!device.config || !device.config["$metadata"]) return "";
        return "Since " + new Date(device.config["$metadata"]["$lastUpdated"]).toLocaleString();
    }

    function getHotspot(device) {
        if (!isInitializing(device)) {
            if (!device.config.services.hotspot) return "";
            return device.config.services.hotspot.enabled ? 'Enabled' : 'Disabled';
        } else {
            return INITIALIZING_COLUMN_TEXT;
        }
    }

    function getModerator(device) {
        if (!isInitializing(device)) {
            if (!device.config.moderator) return "";
            return device.config.moderator.enabled === false ? 'Off' : 'On';
        } else {
            return INITIALIZING_COLUMN_TEXT;
        }
    }

    function getStatusClass(device) {
        if (isInitializing(device)) {
            return 'statusUpdating';
        } else {
            return !deviceConnected(device) ? 'statusOffline' : 'statusOnline';
        }
    }

    function deviceConnectionState(device) {
        return device.connectionState;
    }

    function deviceConnected(device) {
        return deviceConnectionState(device) !== DISCONNECTED ? true : false;
    }

    function getIpAddress(device) {
        if (isInitializing(device)) return "";
        if (!device.config.device.info) return "";
        if (Utils.hasString(device.config.device.info.activeIpv4EthernetAddresses) && device.config.device.info.activeIpv4EthernetAddresses !== '...') {
            return device.config.device.info.activeIpv4EthernetAddresses;
        } else if (Utils.hasString(device.config.device.info.activeIpv4Addresses) && device.config.device.info.activeIpv4Addresses !== '...') {
            return device.config.device.info.activeIpv4Addresses;
        } else if (Utils.hasString(device.config.device.info.activeIpv4WifiAddresses) && device.config.device.info.activeIpv4WifiAddresses !== '...') {
            return device.config.device.info.activeIpv4WifiAddresses;
        } else {
            return '';
        }
    }

    function renderAirserversTable() {
        let trClass = '', tableClass = 'table';
        if (detailsPaneVisible) {
            trClass = 'trNotSelectable';
            tableClass = 'table';
        }

        if (devices && devices.length === 0) {
            return (
                <div>
                    {
                        expandedInUI ?
                            (devices.fetching ? (<div style={{ margin: '1em' }}><Loader type="TailSpin" color="#00BFFF" height={30} width={30} timeout={60000} /></div>) :
                            (<p className='emptyRow'>No devices found</p>)) :
                            (<div>
                                <Table className={tableClass} bordered responsive>
                                    {tableHead()}
                                    {tableBody()}
                                </Table>
                            </div>)
                    }
                </div>
            );
        }

        function tableHead() {
            return expandedInUI ?
                (<thead>
                    <tr onClick={() => handleGroupCheckChange(groupId)} className={isGroupChecked(groupId) ? 'connectedDeviceRow selectedRow' : 'connectedDeviceRow'}>
                        <th className="groupCheck">
                            <input type='checkbox' checked={isGroupChecked(groupId)} onChange={() => handleGroupCheckChange(groupId)} onClick={(e) => e.stopPropagation()} />
                        </th>
                        <th>NAME</th>
                        <th>STATUS</th>
                        <th><nobr>IP ADDRESS</nobr></th>
                        <th>VERSION</th>
                        <th>UPDATES</th>
                        <th>PROTOCOLS</th>
                        <th>HOTSPOT</th>
                        <th>MODERATOR</th>
                        <th>SETTINGS</th>
                    </tr>
                </thead>)
                    : null;
        }

        function tableBody() {
            function getTrClassName(device) {
                //return trClass + (deviceConnected(device) ? ' connectedDeviceRow' : '') + (device.selected ? ' selectedRow' : '');
                return trClass + ' connectedDeviceRow' + (device.selected ? ' selectedRow' : '');
            }

            return expandedInUI ?
                (<tbody>
                    {devices.map(device =>
                        <tr key={device.deviceId} className={getTrClassName(device)} onClick={() => handleDeviceCheckChange(device.deviceId)}>
                            <td className="groupCheck">
                                <input type='checkbox' checked={isDeviceChecked(device.deviceId)} onChange={() => handleDeviceCheckChange(device.deviceId)} onClick={(e) => e.stopPropagation()} />
                            </td>
                            <td>{device.config && device.config.device ? device.config.device.name : ""}</td>
                            <td className={getStatusClass(device)} title={getStatusTip(device)}>{getStatusText(device)}</td>
                            <td>{getIpAddress(device)}</td>
                            <td>{getVersion(device)}</td>
                            <td>{getCheckForUpdatesStr(device)}</td>
                            <td>{getProtocolIcons(device)}</td>
                            <td>{getHotspot(device)}</td>
                            <td>{getModerator(device)}</td>
                            <td>
                                {
                                    isInitializing(device) ? null : (<FontAwesomeIcon className='detailsButton' icon={faEllipsisH} onClick={(e) => handleRowClick(e, device.deviceId, deviceConnectionState(device))} size='2x' />)
                                }
                            </td>
                        </tr>
                    )}
                </tbody>)
                    : null;
        }

        return (
            <div>
                <div>
                    <Table className={tableClass} bordered responsive>
                        {tableHead()}
                        {tableBody()}
                    </Table>
                </div>
            </div>
        );
    }

    let contents = renderAirserversTable();

    return (
        <div>
            {contents}
        </div>
    );
}
