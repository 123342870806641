import React, { Fragment, useState, useEffect } from 'react';
import { Button, Nav, Navbar, Container } from 'react-bootstrap';
import { LoginMenu } from './api-authorization/LoginMenu';
import { NavLink } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import './NavMenu.css';

export default function NavMenu({ editMultipleDevices, createGroup, addDevice, detailsPaneVisible, numSelectedDevices, loggedIn, noGroupsCreated, selectedPage,
	set_selectedPage, canManageUsers }) {

	const [isAuthenticated, set_isAuthenticated] = useState(false);
	
	useEffect(() => {
		async function checkIfAuthenticated() {
			const loggedIn = await authService.isAuthenticated();

			set_isAuthenticated(loggedIn);
		}

		checkIfAuthenticated();
	}, []);

    let buttons = null;
    if (selectedPage === 'Devices') {
        buttons = noGroupsCreated ?
            <Fragment>
				{
					canManageUsers &&
					<Button variant='primary' onClick={createGroup} disabled={detailsPaneVisible ? true : false} className='createGroupButton'>CREATE GROUP</Button>
				}
            </Fragment>
            :
            <Fragment>
				<Button variant='success' onClick={addDevice} disabled={detailsPaneVisible ? true : false} className='addDeviceButton'>ATTACH DEVICE</Button>
				{
					canManageUsers &&
					<Button variant='primary' onClick={createGroup} disabled={detailsPaneVisible ? true : false} className='createGroupButton'>CREATE GROUP</Button>
				}
            </Fragment>
            ;
    }

    return (
        <Fragment>
			<Navbar collapseOnSelect expand="xl" className='border-bottom navbar-dark'>
				<Container>
					<Navbar.Brand className='navbarTitle'><b>CLOUD MANAGEMENT</b></Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="mr-auto navItems">
							{
								canManageUsers &&
								<Fragment>
									<NavLink className='routes' to='/devices' onClick={() => set_selectedPage('Devices')}>Devices</NavLink>
									<NavLink className='routes' to='/users' onClick={() => set_selectedPage('Users')}>Users</NavLink>
								</Fragment>
							}
                        </Nav>
                        <Nav>
                            <ul className="navbar-nav flex-grow">
                                {
                                    (numSelectedDevices > 1 && selectedPage === 'Devices') ?
                                        <React.Fragment>
                                            <Button variant='primary' onClick={editMultipleDevices} className='editButton'>EDIT MULTIPLE DEVICES</Button>
                                        </React.Fragment> :
                                        null
                                }
                                {
                                    loggedIn ?
                                        buttons :
                                        null
                                }
								{
									isAuthenticated && <LoginMenu />
								}
                            </ul>
                        </Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
        </Fragment>
    );
}
