import React from 'react';
import { Form, Col } from 'react-bootstrap';

function prettyText(text) {
    return text === '' ? 'Not Available' : text;
}

export default function InfoRow({ label, value, valueLink = '' }) {
    return (
        <Form.Row>
            <Col className='col-auto mr-auto'><Form.Label>{label}</Form.Label></Col>
            <Col className='col-auto'>
                {
                    valueLink !== '' ?
                        (<a className='colorWhite infoRowLink' href={valueLink} target='_blank' rel='noopener noreferrer'>{value}</a>) :
                        (<div className='colorWhite right-aligned' dangerouslySetInnerHTML={{ __html: prettyText(value) }}></div>)
                }
            </Col>
        </Form.Row>
    );
}
