import React, { useState } from 'react';
import { Jumbotron, Button, Form, Tabs, Tab, Col, Container, Row } from 'react-bootstrap';
import '../main.css';
import authService from './api-authorization/AuthorizeService';
import Utils, { DISCONNECTED, HOMESCREEN_FULL, SESSION_DEFAULT, SAMPLE_RATE_44100, ENGLISH, HOUR_12, UTC } from './Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChromecast } from '@fortawesome/free-brands-svg-icons';
import { faUser, faProjectDiagram, faRouter, faTvMusic, faCloud, faCommentAltCheck, faInfo, faShieldCheck, faGlobeStand } from '@fortawesome/pro-regular-svg-icons';
import 'react-tippy/dist/tippy.css';
import { Tooltip, } from 'react-tippy';
import PersonalizationTab from './Tabs/PersonalizationTab';
import NetworkTab from './Tabs/NetworkTab';
import GuestConnectivityTab from './Tabs/GuestConnectivityTab';
import DeviceManagementTab from './Tabs/DeviceManagementTab';
import ModeratorModeTab from './Tabs/ModeratorModeTab';
import ScreenMirroringTab from './Tabs/ScreenMirroringTab';
import AudioAndVideoTab from './Tabs/AudioAndVideoTab';
import SecurityTab from './Tabs/SecurityTab';
import LocalizationTab from "./Tabs/LocalizationTab";
import AboutTab from './Tabs/AboutTab';
import axios from 'axios';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

export default function DetailsPane({ paneVisible, closeDetailsPane, deviceId, multipleEdit, groups, unselectDevices,
    removeDevice, removeDevices, displayNotification, displayError, set_showTimeout }) {

    const networkReadOnly = false;
    const APPLY_TEXT = 'APPLY';
    const APPLYING_TEXT = 'APPLYING...';
    const DETAILPAGE_TIMEOUT = 30000;
    const NORMAL_TAB_COLOR = '#42B8FE';
    const ERROR_TAB_COLOR = 'darkred';

    const [loading, set_loading] = useState(true);
    const [sentFetch, set_sentFetch] = useState(false);
    const [applyButtonText, set_applyButtonText] = useState(APPLY_TEXT);
    const [groupId, set_groupId] = useState('');
    const [latestDeviceVersion, set_latestDeviceVersion] = useState('');
    const [numRedCrosses, set_numRedCrosses] = useState(0);

    const [name, set_name] = useState('');

    const [airplayAudioReceiver, set_airplayAudioReceiver] = useState(false);
    const [airplayMode, set_airplayMode] = useState(0);
    const [googleCastMode, set_googleCastMode] = useState(0);
    const [miracastMode, set_miracastMode] = useState(0);
    const [airplayPassword, set_airplayPassword] = useState('');
    const [automaticUpdateEnabled, set_automaticUpdateEnabled] = useState(false);
    const [background, set_background] = useState('');
    const [backgroundValid, set_backgroundValid] = useState(true);
    const [builtInHotspot, set_builtInHotspot] = useState(false);
    const [checkForUpdates, set_checkForUpdates] = useState(false);
    const [clientIsolation, set_clientIsolation] = useState(false);
    const [clock, set_clock] = useState(HOUR_12);
    const [currentFrequency, set_currentFrequency] = useState(0);
    const [deviceConfig, set_deviceConfig] = useState(null);
    const [deviceConnectionState, set_deviceConnectionState] = useState('');
    const [deviceManagementPassword, set_deviceManagementPassword] = useState('');
    const [deviceManagementPasswordValid, set_deviceManagementPasswordValid] = useState(true);
    const [displayPowerManagement, set_displayPowerManagement] = useState(false);
    const [displayYourOwnNetwork, set_displayYourOwnNetwork] = useState(false);
    const [enableDeviceManagement, set_enableDeviceManagement] = useState(false);
    const [enterprisePassword, set_enterprisePassword] = useState('');
    const [enterpriseSecurity, set_enterpriseSecurity] = useState(0);
    const [enterpriseUsername, set_enterpriseUsername] = useState('');
    const [externalWifiPassword, set_externalWifiPassword] = useState('');
    const [externalWifiSsid, set_externalWifiSsid] = useState('');
    const [forceLowResolution, set_forceLowResolution] = useState(false);
    const [guestAccessType, set_guestAccessType] = useState(0);
    const [hdmiCecEnabled, set_hdmiCecEnabled] = useState(false);
    const [hdmiCecSupported, set_hdmiCecSupported] = useState(false);
    const [homescreenLayout, set_homescreenLayout] = useState(HOMESCREEN_FULL);
    const [hotspotInterfaceInfoRegdom, set_hotspotInterfaceInfoRegdom] = useState('');
    const [hotspotInterfaceInfoTxPower, set_hotspotInterfaceInfoTxPower] = useState(0);
    const [hotspotInterfaceInfoWidth, set_hotspotInterfaceInfoWidth] = useState('');
    const [hotspotPassword, set_hotspotPassword] = useState('');
    const [hotspotPasswordValid, set_hotspotPasswordValid] = useState(true);
    const [initialGroupId, set_initialGroupId] = useState('');
    const [irChannels, set_irChannels] = useState([]);
    const [language, set_language] = useState(ENGLISH);
    const [moderator, set_moderator] = useState(false);
    const [proxyEnabled, set_proxyEnabled] = useState(false);
    const [proxyHostname, set_proxyHostname] = useState('');
    const [proxyHostnameValid, set_proxyHostnameValid] = useState(true);
    const [proxyPort, set_proxyPort] = useState(0);
    const [requestedFrequency, set_requestedFrequency] = useState(0);
    const [sampleRate, set_sampleRate] = useState(SAMPLE_RATE_44100);
    const [sessionLayout, set_sessionLayout] = useState(SESSION_DEFAULT);
    const [showGuestAccessWhileInUse, set_showGuestAccessWhileInUse] = useState(false);
    const [showHowToConnect, set_showHowToConnect] = useState(false);
    const [showWiFiQrCode, set_showWiFiQrCode] = useState(false);
    const [sleepTimerMinutes, set_sleepTimerMinutes] = useState(0);
    const [softRotation, set_softRotation] = useState(0);
    const [sslCertificate, set_sslCertificate] = useState('');
    const [sslPrivateKey, set_sslPrivateKey] = useState('');
    const [timezone, set_timezone] = useState(UTC);
    const [upperChannelsClicked, set_upperChannelsClicked] = useState(false);
    const [version, set_version] = useState('');
    const [wifiCountry, set_wifiCountry] = useState('us');

    const [checked_airplay, setChecked_airplay] = useState(false);
    const [checked_airplayAudioReceiver, setChecked_airplayAudioReceiver] = useState(false);
    const [checked_automaticUpdateEnabled, setChecked_automaticUpdateEnabled] = useState(false);
    const [checked_background, setChecked_background] = useState(false);
    const [checked_builtInHotspot, setChecked_builtInHotspot] = useState(false);
    const [checked_clock, setChecked_clock] = useState(false);
    const [checked_country, setChecked_country] = useState(false);
    const [checked_deviceManagementPassword, setChecked_deviceManagementPassword] = useState(false);
    const [checked_displayRotation, setChecked_displayRotation] = useState(false);
    const [checked_displayYourOwnNetwork, setChecked_displayYourOwnNetwork] = useState(false);
    const [checked_enableDeviceManagement, setChecked_enableDeviceManagement] = useState(false);
    const [checked_enterpriseSecurity, setChecked_enterpriseSecurity] = useState(false);
    const [checked_forceLowResolution, setChecked_forceLowResolution] = useState(false);
    const [checked_googleCast, setChecked_googleCast] = useState(false);
    const [checked_groups, setChecked_groups] = useState(false);
    const [checked_guestAccessType, setChecked_guestAccessType] = useState(false);
    const [checked_hdmiCecEnabled, setChecked_hdmiCecEnabled] = useState(false);
    const [checked_homescreenLayout, setChecked_homescreenLayout] = useState(false);
    const [checked_hotspotPassword, setChecked_hotspotPassword] = useState(false);
    const [checked_language, setChecked_language] = useState(false);
    const [checked_miracast, setChecked_miracast] = useState(false);
    const [checked_moderator, setChecked_moderator] = useState(false);
    const [checked_privateKeyAndCert, setChecked_privateKeyAndCert] = useState(false);
    const [checked_proxy, setChecked_proxy] = useState(false);
    const [checked_sampleRate, setChecked_sampleRate] = useState(false);
    const [checked_sessionLayout, setChecked_sessionLayout] = useState(false);
    const [checked_showGuestAccessWhileInUse, setChecked_showGuestAccessWhileInUse] = useState(false);
    const [checked_showHowToConnect, setChecked_showHowToConnect] = useState(false);
    const [checked_showWiFiQrCode, setChecked_showWiFiQrCode] = useState(false);
    const [checked_timezone, setChecked_timezone] = useState(false);
    const [checked_turnDisplayOutOffWhenInactive, setChecked_turnDisplayOutOffWhenInactive] = useState(false);

    const [checked_upper5g, setChecked_upper5g] = useState(false);
    const [checked_clientIsolation, setChecked_clientIsolation] = useState(false);

    const [showConfirmationApplyAndRebootDevice, set_showConfirmationApplyAndRebootDevice] = useState(false);
    const [showConfirmationEndSession, set_showConfirmationEndSession] = useState(false);
    const [showConfirmationPowerOffDevice, set_showConfirmationPowerOffDevice] = useState(false);
    const [showConfirmationRebootDevice, set_showConfirmationRebootDevice] = useState(false);
    const [showConfirmationRemoveDevice, set_showConfirmationRemoveDevice] = useState(false);
    const [showConfirmationResetAllSettings, set_showConfirmationResetAllSettings] = useState(false);

    function showPowerOffDeviceConfirmation() {
        set_showConfirmationPowerOffDevice(true);
    }

    function showRebootDeviceConfirmation() {
        set_showConfirmationRebootDevice(true);
    }

    function showApplyAndRebootDeviceConfirmation() {
        set_showConfirmationApplyAndRebootDevice(true);
    }

    function showResetAllSettingsConfirmation() {
        set_showConfirmationResetAllSettings(true);
    }

    function showRemoveDeviceConfirmation() {
        set_showConfirmationRemoveDevice(true);
    }

    function powerOffDeviceConfirmationResult(result) {
        set_showConfirmationPowerOffDevice(false);
        if (result === true) {
            sendToolsCommand('powerOffDevice', 'true');
            displayNotification(`Powering off ${getDeviceAlias()}...`);
            exitPane();
        }
    }

    function rebootDeviceConfirmationResult(result) {
        set_showConfirmationRebootDevice(false);
        if (result === true) {
            sendToolsCommand('reboot', 'true', false);
            displayNotification(`Rebooting ${getDeviceAlias()}...`);
            exitPane();
        }
    }

    function applyAndRebootDeviceConfirmationResult(result) {
        set_showConfirmationApplyAndRebootDevice(false);
        if (result === true) {
            handleApplyClick(true);
        } else {
            exitPane();
        }
    }

    function resetAllSettingsConfirmationResult(result) {
        set_showConfirmationResetAllSettings(false);
        if (result === true) {
            sendToolsCommand('resetSettingsAndRestart', 'true');
            displayNotification(`Resetting all settings and rebooting ${getDeviceAlias()}. This might take a few minutes...`);
            exitPane();
            setTimeout(() => {
                multipleEdit ? removeDevices() : removeDevice(deviceId);
            }, 2000);
        }
    }

    function installUpdatesConfirmationResult(result) {
        if (result === true) {
            sendToolsCommand('checkForUpdates', 'true');
            displayNotification(`Updating ${getDeviceAlias()}...`);
            exitPane();
        }
    }

	function endSessionConfirmationResult(result) {
        set_showConfirmationEndSession(false);
        if (result === true) {
            sendToolsCommand('resetApplicationSession', 'true');
            displayNotification(`Ending session for ${getDeviceAlias()}...`);
            exitPane();
        }
    }

    function resetAllSettingsNotificationText() {
        if (multipleEdit) {
            return `For all selected devices: Resetting all settings and detaching them. This might take a few minutes...`;
        } else {
            return `Resetting all settings and detaching ${getDeviceAlias()}...`;
        }
    }

    function detachingNotificationText() {
        if (multipleEdit) {
            return 'Detaching devices from cloud...';
        } else {
            return `Detaching ${getDeviceAlias()}...`;
        }
    }

    function removeDeviceConfirmationResult(result, resetSettings) {
        set_showConfirmationRemoveDevice(false);
        if (result === true) {
            if (resetSettings) {
                sendToolsCommand('resetSettingsAndRestart', 'true');
                displayNotification(resetAllSettingsNotificationText());
                exitPane();

                setTimeout(() => {
                    multipleEdit ? removeDevices() : removeDevice(deviceId);
                }, 2000);
            } else {
                displayNotification(detachingNotificationText());
                multipleEdit ? removeDevices() : removeDevice(deviceId);
                exitPane();
            }
        }
    }
    
    function handleDisplayRotationChange(e) {
        set_softRotation(Number(e.target.value));
    }

    function handleEnterpriseSecurityChange(e) {
        if (!networkReadOnly) {
            set_enterpriseSecurity(Number(e.target.value));
        }
    }

    function handleGuestAccessTypeChange(e) {
        set_guestAccessType(Number(e.target.value));
    }

    function handleToggleHotspot() {
        set_builtInHotspot(!builtInHotspot);
    }

    function handleToggleDisplayYourOwnNetwork() {
        set_displayYourOwnNetwork(!displayYourOwnNetwork)
    }

    function getIpAddress() {
        if (!deviceConfig || !deviceConfig.device || !deviceConfig.device.info) return '';
        let activeIpv4EthernetAddresses = deviceConfig.device.info.activeIpv4EthernetAddresses || deviceConfig.device.info.activeIpv4Addresses;
        let activeIpv4WifiAddresses = deviceConfig.device.info.activeIpv4WifiAddresses;
        if (Utils.hasString(activeIpv4EthernetAddresses) && activeIpv4EthernetAddresses) {
            return activeIpv4EthernetAddresses;
        } else if (Utils.hasString(activeIpv4WifiAddresses) && activeIpv4WifiAddresses) {
            return activeIpv4WifiAddresses;
        } else {
            return '';
        }
    }

    function saveHubData(device) {
        device.isInitialized = true;

        set_groupId(device.tags.groupId);
        set_initialGroupId(device.tags.groupId);

        if (device.config) {
            set_deviceConfig(device.config);
            console.log("deviceConfig:", device.config);
        }
        set_deviceConnectionState(device.connectionState);

        if (device.config.v2) {
            const v2props = device.config.v2.device;
            set_homescreenLayout(v2props.homescreenLayout != null ? v2props.homescreenLayout.toString() : HOMESCREEN_FULL);
            set_showHowToConnect(v2props.showHowToConnect != null ? v2props.showHowToConnect : false);
            set_showWiFiQrCode(v2props.showQrCode != null ? v2props.showQrCode : false);
            set_showGuestAccessWhileInUse(v2props.showGuestAccessWhileInUse != null ? v2props.showGuestAccessWhileInUse : false);
            set_sessionLayout(v2props.sessionLayout != null ? v2props.sessionLayout.toString() : SESSION_DEFAULT);
            set_sampleRate(v2props.sampleRate != null ? v2props.sampleRate.toString() : SAMPLE_RATE_44100);
            set_language(v2props.language != null ? v2props.language.toString() : ENGLISH);
            set_clock(v2props.clock != null ? v2props.clock.toString() : HOUR_12);
            set_timezone(v2props.timezone != null ? v2props.timezone.toString() : UTC);
        } else {
            set_homescreenLayout(HOMESCREEN_FULL);
            set_showHowToConnect(false);
            set_showWiFiQrCode(false);
            set_showGuestAccessWhileInUse(false);
            set_sessionLayout(SESSION_DEFAULT);
            set_sampleRate(SAMPLE_RATE_44100);
            set_language(ENGLISH);
            set_clock(HOUR_12);
            set_timezone(UTC);
        }

        if (device.config.device != null) {
            set_name(device.config.device.name || '');
            set_version(device.config.device.info.version);
            set_checkForUpdates(device.config.device.info.checkForUpdates);
            set_background(device.config.device.background !== null ? device.config.device.background : '');
        } else {
            set_name('');
            set_version('');
            set_checkForUpdates(true);
            set_background('');
        }

        if (device.config.moderator != null) {
            set_moderator(device.config.moderator.enabled);
        } else {
            set_moderator(false);
        }

        if (device.config.services != null) {
            if (device.config.services.networkaccesscontrol != null) {
                set_enterpriseSecurity(device.config.services.networkaccesscontrol.login ? 1 : 0);
                set_enterpriseUsername(device.config.services.networkaccesscontrol.username);
                set_enterprisePassword(device.config.services.networkaccesscontrol.password);
            } else {
                set_enterpriseSecurity(0);
                set_enterpriseUsername('');
                set_enterprisePassword('');
            }

            if (device.config.services.hotspot != null) {
                if (device.config.services.hotspot.irChannels != null) {
                    set_irChannels(device.config.services.hotspot.irChannels);
                }

                if (device.config.services.hotspot.clientIsolation != null) {
                    set_clientIsolation(device.config.services.hotspot.clientIsolation);
                }

                set_currentFrequency(device.config.services.hotspot.interfaceInfo.frequency);
                set_requestedFrequency(device.config.services.hotspot.requestedFrequency);
                set_builtInHotspot(device.config.services.hotspot.enabled || false);
                set_hotspotPassword(device.config.services.hotspot.passphrase || '');

                if (device.config.services.hotspot.interfaceInfo != null) {
                    set_hotspotInterfaceInfoRegdom(device.config.services.hotspot.interfaceInfo.regdom || '');
                    set_hotspotInterfaceInfoTxPower(device.config.services.hotspot.interfaceInfo.txPower || 0);
                    set_hotspotInterfaceInfoWidth(device.config.services.hotspot.interfaceInfo.width || '');
                }
            } else {
                set_irChannels([]);
                set_clientIsolation(false);
                set_currentFrequency(0);
                set_requestedFrequency(0);
                set_builtInHotspot(false);
                set_hotspotPassword(false);
                set_hotspotInterfaceInfoRegdom('');
                set_hotspotInterfaceInfoTxPower(0);
                set_hotspotInterfaceInfoWidth('');
            }

            if (device.config.services.externalwifi != null) {
                set_displayYourOwnNetwork(device.config.services.externalwifi.enabled || false);
                set_externalWifiSsid(device.config.services.externalwifi.ssid || '');
                set_externalWifiPassword(device.config.services.externalwifi.password || '');
            } else {
                set_displayYourOwnNetwork(false);
                set_externalWifiSsid('');
                set_externalWifiPassword('');
            }

            if (device.config.services.airplay) {
                set_airplayPassword(device.config.services.airplay.password);
                if (device.config.services.airplay.enabled) {
                    set_airplayMode(device.config.services.airplay.authMode + 1);
                } else {
                    set_airplayMode(0);
                }
                set_airplayAudioReceiver(device.config.services.airplay.audioReceiver);
            } else {
                set_airplayMode(1);
                set_airplayPassword('');
                set_airplayAudioReceiver(false);
            }

            if (device.config.services.googlecast) {
                if (device.config.services.googlecast.enabled) {
                    set_googleCastMode(device.config.services.googlecast.prompt ? 2 : 1);
                } else {
                    set_googleCastMode(0);
                }
            }
            if (device.config.services.miracast) {
                if (device.config.services.miracast.enabled) {
                    set_miracastMode(device.config.services.miracast.pin ? (device.config.services.miracast.useShortPin ? 3 : 2) : 1);
                } else {
                    set_miracastMode(0);
                }
            }

            set_automaticUpdateEnabled(device.config.services.updater ? device.config.services.updater.automaticUpdateEnabled : false);

            if (device.config.services.management != null) {
                set_sslPrivateKey(device.config.services.management.sslPrivateKey || '');
                set_sslCertificate(device.config.services.management.sslCertificate || '');
                set_enableDeviceManagement(device.config.services.management.enabled || false);
                set_deviceManagementPassword(device.config.services.management.adminPassword || '');
            } else {
                set_sslPrivateKey('');
                set_sslCertificate('');
                set_enableDeviceManagement(false);
                set_deviceManagementPassword('');
            }
        }

        if (device.config.network != null) {
            let forwardToInternet = device.config.network.forwardToInternet || false;
            let forwardToLAN = device.config.network.forwardToLAN || false;
            if (forwardToInternet === false && forwardToLAN === false) {
                set_guestAccessType(0);
            } else if (forwardToInternet === true && forwardToLAN === false) {
                set_guestAccessType(1);
            } else if (forwardToInternet === true && forwardToLAN === true) {
                set_guestAccessType(2);
            } else {
                set_guestAccessType(0);
            }

            set_proxyEnabled(device.config.network.proxyEnabled || false);
            set_proxyHostname(device.config.network.proxyHostname || '');
            set_proxyPort(device.config.network.proxyPort || 0);
        } else {
            set_guestAccessType(0);
            set_proxyEnabled(false);
            set_proxyHostname('');
            set_proxyPort(0);
        }

        if (device.config.display) {
            set_softRotation(device.config.display.softRotation || 0);
            set_displayPowerManagement(device.config.display.sleepTimerEnabled || false);
            set_sleepTimerMinutes(device.config.display.sleepTimerSeconds === null ? 0 : device.config.display.sleepTimerSeconds / 60);
            set_hdmiCecSupported(device.config.display.cecSupported);
            set_hdmiCecEnabled(device.config.display.cecEnabled);
        } else {
            set_softRotation(0);
            set_displayPowerManagement(false);
            set_sleepTimerMinutes(0);
            set_hdmiCecSupported(false);
            set_hdmiCecEnabled(false);
        }
        set_latestDeviceVersion(device.latestDeviceVersion);

        if (device.config.wifi != null) {
            set_wifiCountry(device.config.wifi.country ? device.config.wifi.country : '');
        } else {
            set_wifiCountry('');
        }

        if (device.config.decoder && device.config.decoder.forceLowResolution != null) {
            set_forceLowResolution(device.config.decoder.forceLowResolution);
        } else {
            set_forceLowResolution(false);
        }
    }

    function createAirServerInput()
    {
        let services = {
            airplay: {},
            googlecast: {},
            miracast: {},
            hotspot: {},
            externalwifi: {},
            management: {},
            updater: {},
        };

        if (services.airplay != null) {
            let airplayAuthMode = Number(airplayMode);
            if (airplayAuthMode === 0) {
                services.airplay.enabled = false;
            } else {
                services.airplay.enabled = true;
                services.airplay.authMode = airplayAuthMode - 1;
            }
            services.airplay.password = airplayPassword;
            if (airplayPassword === '' && airplayAuthMode === 3) {
                services.airplay.authMode = 0;
                set_airplayMode(1);
            }
            services.airplay.password = airplayPassword;
            services.airplay.audioReceiver = !!airplayAudioReceiver;
        }
        if (services.googlecast) {
            let googleCastAuthMode = Number(googleCastMode);
            if (googleCastAuthMode === 0) {
                services.googlecast.enabled = false;
            } else {
                services.googlecast.enabled = true;
                services.googlecast.prompt = (googleCastAuthMode === 2);
            }
        }
        if (services.miracast) {
            let miracastAuthMode = Number(miracastMode);
            if (miracastAuthMode === 0) {
                services.miracast.enabled = false;
            } else {
                services.miracast.enabled = true;
                services.miracast.pin = (miracastAuthMode === 2 || miracastAuthMode === 3);
                services.miracast.useShortPin = (miracastAuthMode === 3);
            }
        }

        if (services.hotspot) {
            services.hotspot.requestedFrequency = requestedFrequency;
            services.hotspot.enabled = builtInHotspot;
            services.hotspot.passphrase = hotspotPassword ? hotspotPassword : '';
            services.hotspot.clientIsolation = clientIsolation;
        }

        if (services.externalwifi) {
            services.externalwifi.enabled = displayYourOwnNetwork;
            services.externalwifi.ssid = externalWifiSsid ? externalWifiSsid : '';
            services.externalwifi.password = externalWifiPassword ? externalWifiPassword : '';
        }
        if (services.networkaccesscontrol) {
            //services.networkaccesscontrol.eapType = enterpriseSecurity === 0 ? 0 : 1;
            services.networkaccesscontrol.login = (enterpriseSecurity === 1);
            services.networkaccesscontrol.username = enterpriseUsername;
            services.networkaccesscontrol.password = enterprisePassword;
        }

        if (services.management) {
            services.management.sslPrivateKey = sslPrivateKey;
            services.management.sslCertificate = sslCertificate;
            services.management.enabled = enableDeviceManagement;
            if (enableDeviceManagement && deviceManagementPassword !== '') {
                services.management.adminPassword = deviceManagementPassword;
            } else {
                services.management.adminPassword = null;
            }
        }


        if (services.updater) {
            services.updater.automaticUpdateEnabled = automaticUpdateEnabled;
        }

        let tmpForwardToInternet = false, tmpForwardToLAN = false;
        if (guestAccessType === 1) {
            tmpForwardToInternet = true;
        } else if (guestAccessType === 2) {
            tmpForwardToInternet = true;
            tmpForwardToLAN = true;
        }

        let backgroundUrl = null;
        try {
            let url = new URL(background);
            if (url.protocol === 'http:' || url.protocol === 'https:') {
                backgroundUrl = url.toString();
            }
        } catch (e) { }

        if (deviceConfig.device.background && backgroundUrl === null) {
            backgroundUrl = "";
        }

        let config = {
            device: {
                name,
                info: {
                    checkForUpdates,
                },
                background: backgroundUrl
            },
            services: services,
            moderator: { enabled: moderator },
            network: {
                forwardToInternet: tmpForwardToInternet,
                forwardToLAN: tmpForwardToLAN,
                proxyEnabled,
                proxyHostname,
                proxyPort: Number(proxyPort)
            },
            display: {
                softRotation: softRotation,
                sleepTimerEnabled: displayPowerManagement,
                cecEnabled: hdmiCecEnabled,
                sleepTimerSeconds: sleepTimerMinutes === 0 ? 0 : sleepTimerMinutes * 60
            },
            v2: {
                device: {
                    homescreenLayout: homescreenLayout,
                    showHowToConnect,
                    showQrCode: showWiFiQrCode,
                    showGuestAccessWhileInUse,
                    sessionLayout: sessionLayout,
                    sampleRate: sampleRate,
                    clock: clock,
                    timezone: timezone
                }
            },
            wifi: { country: wifiCountry },
            decoder: {
                forceLowResolution: forceLowResolution
            }
        };

        let deviceUpdate = JSON.parse(JSON.stringify(config));
        //console.warn("deviceUpdate", JSON.stringify(deviceUpdate));
        while (removeDuplicates(deviceUpdate, deviceConfig));
        //console.log("update deviceComfig:", deviceUpdate);
        return deviceUpdate;
    }

    function removeDuplicates(srcJson, compareJson) {
        let modified = false;
        for (const key in srcJson) {
            let obj = srcJson[key];
            if (typeof(obj) === "object") {
                if (!obj || Object.entries(obj).length === 0) {
                    delete srcJson[key];
                    modified = true;
                } else {
                    if (Object.entries(compareJson).length !== 0) {
                        if (removeDuplicates(obj, compareJson[key])) {
                            modified = true;
                        }
                    }
                }
            } else {
                if (obj === compareJson[key]) {
                    delete srcJson[key];
                    modified = true;
                }
            }
        }
        return modified;
    }

    function callUpdateDevice(airServerInput, token, exitPaneWhenDone) {

        set_applyButtonText(APPLYING_TEXT);

        axios({
            url: `CloudManagement/UpdateDevice/${deviceId}`,
            data: { groupId: groupId, config: JSON.stringify(airServerInput) },
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' },
                method: 'PUT'
            })
            .then(() => {
                if (exitPaneWhenDone) {
                    displayNotification(`Changes are being applied to ${getDeviceAlias()}...`);
                    exitPane();
                }
            })
            .catch(error => {
                console.error('UpdateDevice failed ', error);

                if (error.message.toLowerCase().includes('timeout')) {
                    set_showTimeout(true);
                }
                if (error.message.includes('401')) {
                    displayError('Please log in again');
                }
                exitPane();
            });
    }

    function putMultipleEdit(multipleDevices, token, exitPaneWhenDone) {

        set_applyButtonText(APPLYING_TEXT);

        console.log("multipleDevices", multipleDevices);

        fetch('CloudManagement/EditMultipleDevices',
            {
                body: JSON.stringify(multipleDevices),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' },
                method: 'PUT'
            })
            .then(response => response.json())
            .then(() => {
                if (exitPaneWhenDone) {
                    displayNotification('Updating selected devices...');
                    unselectDevices();
                    exitPane();
                }
            })
            .catch(error => {
                console.error('UpdateDevice with multiple devices failed ', error);
                if (error.message.toLowerCase().includes('timeout')) {
                    set_showTimeout(true);
                }
                if (error.message.includes('401')) {
                    displayError('Please log in again');
                }
                exitPane();
            });

        return multipleDevices;
    }

    function constructDeviceAndGroupIdLists(deviceIds, groupIds) {
        
        for (let group of groups) {
            if (group.selected) groupIds.push(group.deviceId);
            for (let device of group.devices) {
                if (device.selected) deviceIds.push(device.deviceId);
            }
        }
    }

    function removeEmptyObjects(dict) {
        let modified = false;
        for (const key in dict) {
            let obj = dict[key];
            if (typeof (obj) === "object") {
                if (!obj || Object.entries(obj).length === 0) {
                    delete dict[key];
                    modified = true;
                } else {
                    if (removeEmptyObjects(obj)) {
                        modified = true;
                    }
                }
            }
        }
        return modified;
    }

    function constructMultipleEditInput() {

        let config = {
            decoder: {},
            device: {},
            display: {},
            moderator: {},
            network: {},
            services: {
                airplay: {},
                externalwifi: {},
                googlecast: {},
                hotspot: {},
                management: {},
                miracast: {},
                networkaccesscontrol: {},
                updater: {},
            },
            v2: {
                device: {}
            },
            wifi: {},
        };

        // about
        if (checked_automaticUpdateEnabled) {
            config.services.updater.automaticUpdateEnabled = automaticUpdateEnabled;
        }

        // Personalization
        if (checked_background) {
            config.device.background = background;
        }
        if (checked_homescreenLayout) {
            config.v2.device.homescreenLayout = homescreenLayout;
        }
        if (checked_sessionLayout) {
            config.v2.device.sessionLayout = sessionLayout;
        }
        if (checked_showHowToConnect) {
            config.v2.device.showHowToConnect = showHowToConnect;
        }
        if (checked_showWiFiQrCode) {
            config.v2.device.showQrCode = showWiFiQrCode;
        }
        if (checked_showGuestAccessWhileInUse) {
            config.v2.device.showGuestAccessWhileInUse = showGuestAccessWhileInUse;
        }
        if (checked_displayYourOwnNetwork) {
            config.services.externalwifi.enabled = !!displayYourOwnNetwork;
            if (config.services.externalwifi.enabled && !!externalWifiSsid) {
                config.services.externalwifi.ssid = externalWifiSsid;
                config.services.externalwifi.password = externalWifiPassword;
            } else {
                set_displayYourOwnNetwork(false);
            }
        }

        // Screen Mirroring
        if (checked_airplay) {
            let airplayAuthMode = Number(airplayMode);
            if (airplayAuthMode === 0) {
                config.services.airplay.enabled = false;
            } else {
                config.services.airplay.enabled = true;
                config.services.airplay.authMode = airplayAuthMode - 1;
            }
            config.services.airplay.password = airplayPassword;
            if (airplayPassword === '' && airplayAuthMode === 3) {
                config.services.airplay.authMode = 0;
                set_airplayMode(1);
            }
            config.services.airplay.password = airplayPassword;
        }

        if (checked_googleCast) {
            let googleCastAuthMode = Number(googleCastMode);
            if (googleCastAuthMode === 0) {
                config.services.googlecast.enabled = false;
            } else {
                config.services.googlecast.enabled = true;
                config.services.googlecast.prompt = (googleCastAuthMode === 2);
            }
        }
        if (checked_miracast) {
            let miracastAuthMode = Number(miracastMode);
            if (miracastAuthMode === 0) {
                config.services.miracast.enabled = false;
            } else {
                config.services.miracast.enabled = true;
                config.services.miracast.pin = (miracastAuthMode === 2 || miracastAuthMode === 3);
                config.services.miracast.useShortPin = (miracastAuthMode === 3);
            }
        }

        // Moderator
        if (checked_moderator) {
            config.moderator.enabled = !!moderator;
        }

        // Networking
        //networkaccesscontrol
        if (checked_enterpriseSecurity) {
            console.log('enterpriseSecurity', enterpriseSecurity, enterpriseUsername, enterprisePassword)
            config.services.networkaccesscontrol.eapSecurity = enterpriseSecurity;
            //config.services.networkaccesscontrol.eapType = 0;
            config.services.networkaccesscontrol.login = enterpriseSecurity === 1;
            if (config.services.networkaccesscontrol.login && !!enterpriseUsername) {
                config.services.networkaccesscontrol.username = enterpriseUsername;
                config.services.networkaccesscontrol.password = enterprisePassword;
            } else {
                set_enterpriseSecurity(0);
            }
        }
        if (checked_proxy) {
            config.network.proxyEnabled = !!proxyEnabled;
            if (config.network.proxyEnabled && !!proxyHostname && !!proxyPort) {
                config.network.proxyHostname = proxyHostname;
                config.network.proxyPort = proxyPort;
            } else {
                set_proxyEnabled(false);
            }
        }

        // Guest Connectivity
        if (checked_builtInHotspot) {
            config.services.hotspot.enabled = !!builtInHotspot;
            //config.services.hotspot.requestedFrequency = requestedFrequency;
            if (checked_hotspotPassword) {
                config.services.hotspot.passphrase = hotspotPassword;
            }
            if (checked_clientIsolation) {
                config.services.hotspot.clientIsolation = !!clientIsolation;
            }

            if (checked_guestAccessType) {
                let tmpForwardToInternet = false, tmpForwardToLAN = false;
                if (guestAccessType === 1) {
                    tmpForwardToInternet = true;
                } else if (guestAccessType === 2) {
                    tmpForwardToInternet = true;
                    tmpForwardToLAN = true;
                }
                config.network.forwardToInternet = tmpForwardToInternet;
                config.network.forwardToLAN = tmpForwardToLAN;
            }
        }

        // Audio/Video
        if (checked_airplayAudioReceiver) {
            config.services.airplay.audioReceiver = !!airplayAudioReceiver;
        }
        if (checked_forceLowResolution) {
            config.decoder.forceLowResolution = forceLowResolution;
        }
        if (checked_hdmiCecEnabled) {
            // dangerous
            config.display.cecEnabled = hdmiCecEnabled;
        }
        if (checked_turnDisplayOutOffWhenInactive) {
            config.display.sleepTimerEnabled = !!displayPowerManagement;
            config.display.sleepTimerSeconds = sleepTimerMinutes * 60;
        }
        if (checked_sampleRate) {
            config.v2.device.sampleRate = sampleRate;
        }
        if (checked_displayRotation) {
            config.display.softRotation = softRotation;
        }


        // Localization
        if (checked_country) {
            config.wifi.country = wifiCountry;
        }
        if (checked_language) {
            config.v2.device.language = language;
        }
        if (checked_clock) {
            config.v2.device.clock = clock;
        }
        if (checked_timezone) {
            config.v2.device.timezone = timezone;
        }

        // Security Tab
        if (checked_enableDeviceManagement) {
            config.services.management.enabled = !!enableDeviceManagement;
            if (checked_deviceManagementPassword) {
                config.services.management.adminPassword = deviceManagementPassword;
            }
        }
        if (checked_privateKeyAndCert) {
            config.services.management.sslPrivateKey = sslPrivateKey;
            config.services.management.sslCertificate = sslCertificate;
        }

        while (removeEmptyObjects(config));

        let retval = {
            deviceIds: [],
            groupIds: [],
            config: JSON.stringify(config)
        };

        constructDeviceAndGroupIdLists(retval.deviceIds, retval.groupIds);
        if (checked_groups) {
            retval.targetGroup = groupId;
        }
        console.log("update devices with data:", retval);

        return retval;
    }


    function isUrl(text) {
        try {
            let url = new URL(text);
            if (url.protocol === 'http:' || url.protocol === 'https:') {
                return true;
            }
        } catch (e) { }
        return false;
    }

    function validateInput() {
        let valid = true;
        let invalidProps = [];

        if (background && !isUrl(background)) {
            // 'background' is not a valid URL.
            invalidProps.push('Custom Background');
            set_backgroundValid(false);
            valid = false;
        }
        if (builtInHotspot && hotspotPassword && hotspotPassword.length < 8) {
            invalidProps.push('Hotspot password');
            set_hotspotPasswordValid(false);
            valid = false;
        }
        if (enableDeviceManagement && deviceManagementPassword === '') {
            // Device Management password is empty when Device Management is enabled.
            invalidProps.push('Device Management password');
            set_deviceManagementPasswordValid(false);
            valid = false;
        }
        if (!multipleEdit && proxyEnabled && proxyHostname === '') {
            // 'proxyHostname' is not a valid URL.
            invalidProps.push('Proxy Hostname');
            set_proxyHostnameValid(false);
            valid = false;
        }

        if (!multipleEdit || (multipleEdit && checked_privateKeyAndCert)) {
            if (!sslPrivateKey || !sslCertificate) {
                invalidProps.push('TLS/SSL Security');
                valid = false;
            }
        }

        if (valid) {
            set_backgroundValid(true);
            set_hotspotPasswordValid(true);
            set_proxyHostnameValid(true);
            set_deviceManagementPasswordValid(true);
            return true;
        } else {
            displayError('Invalid input: ' + invalidProps.join(', '));
            return false;
        }
    }

    function personalizationTabColor() {
        return backgroundValid ? NORMAL_TAB_COLOR : ERROR_TAB_COLOR;
    }

    function networkTabColor() {
        return proxyHostnameValid ? NORMAL_TAB_COLOR : ERROR_TAB_COLOR;
    }

    function guestConnectivityTabColor() {
        return hotspotPasswordValid ? NORMAL_TAB_COLOR : ERROR_TAB_COLOR;
    }

    function normalTabColor() {
        return NORMAL_TAB_COLOR;
    }

    function handleApplyClick(exitPaneWhenDone = true) {
        if (!validateInput()) return;

        if (multipleEdit) {

            const multipleDevices = constructMultipleEditInput();

            authService.getAccessToken()
                .then(token => {
                    putMultipleEdit(multipleDevices, token, exitPaneWhenDone);
                })
        } else {

            let airServerInput = createAirServerInput();
            if (Object.entries(airServerInput).length === 0 && initialGroupId === groupId) {
                console.warn("nothing up update...", airServerInput);
                if (exitPaneWhenDone) {
                    exitPane();
                }
                return;
            }

            console.log("update deviceConfig:", airServerInput);

            // Updating a device's properties:
            authService.getAccessToken()
                .then(token =>
                    callUpdateDevice(airServerInput, token, exitPaneWhenDone)
                );
        }
    }

    function exitPane() {
  //      set_name('');
        set_applyButtonText(APPLY_TEXT);
        set_upperChannelsClicked(false);

  //      set_version('');
  //      set_checkForUpdates(false);
  //      set_services(initialServiceSettings());
  //      set_moderator(false);
  //      set_background('');
  //      set_backgroundValid(true);
  //      set_hotspotPasswordValid(true);
  //      set_deviceManagementPasswordValid(true);
  //      set_proxyHostnameValid(true);
  //      set_enterpriseSecurity(0);
  //      set_enterpriseUsername('');
  //      set_enterprisePassword('');
  //      set_proxyEnabled(false);
  //      set_proxyHostname('');
  //      set_proxyPort(0);
  //      set_builtInHotspot(false);
  //      set_displayYourOwnNetwork(false);
  //      set_externalWifiSsid('');
  //      set_externalWifiPassword('');
  //      set_selectAirplayAuthMode('0');
  //      set_selectMiracastAuthMode('0');
  //      set_selectGoogleCastAuthMode('0');
  //      set_airplayPw('');
  //      set_softRotation(0);
  //      set_displayPowerManagement(false);
  //      set_sleepTimerMinutes(0);
  //      set_groupName('');
  //      set_groupId(groups.length > 0 ? groups[0].deviceId : '');
  //      set_enableDeviceManagement(false);
  //      set_deviceManagementPassword('');
  //      set_automaticUpdateEnabled(false);
  //      set_sslPrivateKey('');
  //      set_sslCertificate('');
  //      set_accessType(0);
  //      set_dhcpRange(0);
  //      set_currentChannel(99);
  //      set_requestedChannel('Auto');
  //      set_hotspotPassword('');
  //      set_irChannels([]);
  //      set_requestedNoGoFrequencies(NO_UPPER_5GHZ_CHANNELS);
  //      set_clientIsolation(false);

  //      set_hotspotInterfaceInfoRegdom('');
  //      set_hotspotInterfaceInfoTxPower(0);
  //      set_hotspotInterfaceInfoWidth('');

  //      set_numRedCrosses(0);
		//set_latestDeviceVersion('');

  //      set_wifiCountry('us');

  //      set_homescreenLayout(HOMESCREEN_FULL);
  //      set_sessionLayout(SESSION_DEFAULT);
  //      set_showHowToConnect(false);
  //      set_showWiFiQrCode(false);
  //      set_showGuestAccessWhileInUse(false);
  //      set_sampleRate(SAMPLE_RATE_44100);

  //      set_language(ENGLISH);
  //      set_clock(HOUR_12);
  //      set_timezone(UTC_MINUS_12_00);

        setChecked_airplay(false);
        setChecked_airplayAudioReceiver(false);
        setChecked_automaticUpdateEnabled(false);
        setChecked_background(false);
        setChecked_builtInHotspot(false);
        setChecked_clock(false);
        setChecked_country(false);
        setChecked_deviceManagementPassword(false);
        setChecked_displayRotation(false);
        setChecked_displayYourOwnNetwork(false);
        setChecked_enableDeviceManagement(false);
        setChecked_forceLowResolution(false);
        setChecked_googleCast(false);
        setChecked_groups(false);
        setChecked_guestAccessType(false);
        setChecked_hdmiCecEnabled(false);
        setChecked_homescreenLayout(false);
        setChecked_hotspotPassword(false);
        setChecked_language(false);
        setChecked_miracast(false);
        setChecked_moderator(false);
        setChecked_privateKeyAndCert(false);
        setChecked_proxy(false);
        setChecked_sampleRate(false);
        setChecked_enterpriseSecurity(false);
        setChecked_sessionLayout(false);
        setChecked_showGuestAccessWhileInUse(false);
        setChecked_showHowToConnect(false);
        setChecked_showWiFiQrCode(false);
        setChecked_timezone(false);
        setChecked_turnDisplayOutOffWhenInactive(false);

        closeDetailsPane();
    }

    // Methods that change the boolean values but also call 'changeRedCrossCount()' so the APPLY button is only enabled if the user has checked at least one change:
    const handle_setChecked_airplay                         = (val) => { setChecked_airplay(val);                       changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_airplayAudioReceiver            = (val) => { setChecked_airplayAudioReceiver(val);          changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_automaticUpdateEnabled          = (val) => { setChecked_automaticUpdateEnabled(val);        changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_background                      = (val) => { setChecked_background(val);                    changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_builtInHotspot                  = (val) => { setChecked_builtInHotspot(val);                changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_clock                           = (val) => { setChecked_clock(val);                         changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_country                         = (val) => { setChecked_country(val);                       changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_deviceManagementPassword        = (val) => { setChecked_deviceManagementPassword(val);      changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_displayRotation                 = (val) => { setChecked_displayRotation(val);               changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_displayYourOwnNetwork           = (val) => { setChecked_displayYourOwnNetwork(val);         changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_enableDeviceManagement          = (val) => { setChecked_enableDeviceManagement(val);        changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_forceLowResolution              = (val) => { setChecked_forceLowResolution(val);            changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_googleCast                      = (val) => { setChecked_googleCast(val);                    changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_groups                          = (val) => { setChecked_groups(val);                        changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_guestAccessType                 = (val) => { setChecked_guestAccessType(val);               changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_hdmiCecEnabled                  = (val) => { setChecked_hdmiCecEnabled(val);                changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_homescreenLayout                = (val) => { setChecked_homescreenLayout(val);              changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_language                        = (val) => { setChecked_language(val);                      changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_miracast                        = (val) => { setChecked_miracast(val);                      changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_moderator                       = (val) => { setChecked_moderator(val);                     changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_privateKeyAndCert               = (val) => { setChecked_privateKeyAndCert(val);             changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_proxy                           = (val) => { setChecked_proxy(val);                         changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_sampleRate                      = (val) => { setChecked_sampleRate(val);                    changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_enterpriseSecurity              = (val) => { setChecked_enterpriseSecurity(val);            changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_sessionLayout                   = (val) => { setChecked_sessionLayout(val);                 changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_showGuestAccessWhileInUse       = (val) => { setChecked_showGuestAccessWhileInUse(val);     changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_showHowToConnect                = (val) => { setChecked_showHowToConnect(val);              changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_showWiFiQrCode                  = (val) => { setChecked_showWiFiQrCode(val);                changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_timezone                        = (val) => { setChecked_timezone(val);                      changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_turnDisplayOutOffWhenInactive   = (val) => { setChecked_turnDisplayOutOffWhenInactive(val); changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_upper5g                         = (val) => { setChecked_upper5g(val);                       changeRedCrossCount(val ? 1 : -1); }
    const handle_setChecked_clientIsolation                 = (val) => { setChecked_clientIsolation(val);               changeRedCrossCount(val ? 1 : -1); }

    function sendToolsCommand(attribute, value, notifyWhenDone = false) {
        let uri = null, body = null;

        if (multipleEdit) {
            const toggleMultipleInput = {
                deviceIds: [],
                groupIds: [],
                attributeName: attribute,
                attributeValue: value,
                type: 'bool'
            };
            constructDeviceAndGroupIdLists(toggleMultipleInput.deviceIds, toggleMultipleInput.groupIds);

            uri = 'CloudManagement/ToggleAttributeMultiple';
            body = JSON.stringify(toggleMultipleInput);
        } else {
            uri = `CloudManagement/ToggleAttribute/${deviceId}`;
            body = JSON.stringify({name: attribute, value, type: 'bool'});
        }

        authService.getAccessToken()
            .then(token =>
                fetch(uri,
                    {
                        body,
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        method: 'PUT'
                    })
            )
            .then(() => {
                if (notifyWhenDone === true) {
                    //setShowNotificationState();
                    displayNotification(`Command sent to device${multipleEdit ? 's' : ''}`);
                }
            })
            .catch((errorMsg) => {
                console.log(`ToggleAttribute returned error: ${errorMsg}`);
            });
    }

    function isValidNumber(value) {
        if ((Utils.isNumeric(value) && value.length < 7 && value.length > 0 && !value.includes('.')) || value === '') {
            return true;
        }
    }

    function handleProxyPortChange(value) {
        if (isValidNumber(value)) {
            set_proxyPort(value);
        }
    }

    function changeSelectedGroup(e) {
        set_groupId(e.target.value);
    }

    function handleChangeName(e) {
        const value = e.target.value;
        set_name(value ? value.substr(0, 32) : '');
    }

    function handleChangeBackground(value) {
        set_background(value ? value : '');
        set_backgroundValid(true);
    }

    function handleChangeDeviceManagementPassword(value) {
        set_deviceManagementPassword(value ? value : '');
        set_deviceManagementPasswordValid(true);
    }

    function handleChangeProxyHostname(value) {
        set_proxyHostname(value ? value : '');
        set_proxyHostnameValid(true);
    }

    function handleSetHotspotPassword(value) {
        if (value.length <= 32) {
            set_hotspotPassword(value ? value : '');
            set_hotspotPasswordValid(true);
        }
    }

    function isDisconnected() {
        return deviceConnectionState === DISCONNECTED;
    }

    function detailsPaneClass() {
        if (isDisconnected()) {
            return 'show disconnected';
        } else if (applyButtonText === APPLYING_TEXT) {
            return 'showAndWait';
        } else {
            return 'show';
        }
    }

    function detailsPaneHeader() {
        if (multipleEdit) {
            return (<h3>Edit Multiple Devices</h3>);
        }
        let ip = getIpAddress();
        if (ip) {
            return (<h3>{name} ({ip})</h3>);
        } else {
            return (<h3>{name}</h3>);
        }
    }

    function getDeviceAlias() {
        let deviceName = name;
        if (!deviceName) {
            deviceName = deviceId;
        }
        let ip = getIpAddress();
        if (ip) {
            return `${deviceName} (${ip})`;
        } else {
            return deviceName;
        }
    }

    function changeRedCrossCount(delta) {
        set_numRedCrosses(numRedCrosses + delta);
    }

    function applyButtonDisabled() {
        return applyButtonText === APPLYING_TEXT || (multipleEdit && numRedCrosses === 0) ?true : false;
    }

	function showEndSessionConfirmation() {
        set_showConfirmationEndSession(true);
    }

    if (!paneVisible) {
        if (!loading) set_loading(true);

        return (
            <div id='detailsPane' className='hide'>
            </div>);
    }

    if (loading && !multipleEdit) {
        if (!sentFetch) {
            authService.getAccessToken()
                .then(token => axios.get(`CloudManagement/GetDeviceDetails/${deviceId}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` }, timeout: DETAILPAGE_TIMEOUT }))
                .then(response => {
                    return response.data;
                })
                .then(data => {
                    saveHubData(data);
                    set_loading(false);
                    set_sentFetch(false);
                })
                .catch(error => {
                    console.log('Fetching detail returned error: ' + error);

                    if (error.message.includes('401')) {
                        // Close Details pane and show a notification that the user should log in again:
                        displayError('Please log in again');
                        exitPane();
                    }

                    set_loading(false);
                    set_sentFetch(false);
                    if (error.message.toLowerCase().includes('timeout loading device details')) {
                        set_showTimeout(true);
                        exitPane();
                    }
                });

            set_sentFetch(true);
        }

        return (
            <div id='detailsPane' className='show'>
                <Jumbotron>
                    <Loader type="TailSpin" color="#00BFFF" height={30} width={30} timeout={20000} />
                </Jumbotron>
            </div>);
    } else {
        if (loading && multipleEdit) {
            set_loading(false);
            set_airplayMode(1);
            set_googleCastMode(1);
            set_miracastMode(1);
        }
        return (
            <div id='detailsPane' className={detailsPaneClass()} >
                <Container>
                    <Row>
                        <Col xs={8} className='detailsPaneHeaderCol'>
                            <Form.Row>
                                <Col>
                                    {
                                        detailsPaneHeader()
                                    }
                                </Col>
                            </Form.Row>
                        </Col>
                        <Col xs={4} className='detailFormButtons'>
                            {
                                applyButtonText === APPLYING_TEXT ? null : (
                                    <Button className='cancelButton' variant='secondary' onClick={() => exitPane()}>
                                        CANCEL
                                    </Button>
                                )
                            }

                            {
                                <Button className={applyButtonText === APPLYING_TEXT ? 'saveButton applyingButton' : 'saveButton'}
                                        variant='primary' onClick={() => handleApplyClick()} disabled={applyButtonDisabled()}>
                                    {applyButtonText}
                                </Button>
                            }
                        </Col>
                    </Row>
                </Container>
                
                <Tabs className='detailsAccordion' defaultActiveKey='DeviceManagement'>

                    {/*Device Management*/}
                    <Tab title={<Tooltip title='Device Management' position='bottom' trigger='mouseenter' arrow='true'><span><FontAwesomeIcon color={normalTabColor()} icon={faCloud} />{' '}</span></Tooltip>} eventKey="DeviceManagement">
                        <DeviceManagementTab multipleEdit={multipleEdit}
                            showPowerOffDeviceConfirmation={showPowerOffDeviceConfirmation} showConfirmationPowerOffDevice={showConfirmationPowerOffDevice} powerOffDeviceConfirmationResult={powerOffDeviceConfirmationResult}
                            showRebootDeviceConfirmation={showRebootDeviceConfirmation} showConfirmationRebootDevice={showConfirmationRebootDevice} rebootDeviceConfirmationResult={rebootDeviceConfirmationResult}
                            showRemoveDeviceConfirmation={showRemoveDeviceConfirmation} showConfirmationRemoveDevice={showConfirmationRemoveDevice} removeDeviceConfirmationResult={removeDeviceConfirmationResult}
                            showResetAllSettingsConfirmation={showResetAllSettingsConfirmation} showConfirmationResetAllSettings={showConfirmationResetAllSettings} resetAllSettingsConfirmationResult={resetAllSettingsConfirmationResult}
                            changeSelectedGroup={changeSelectedGroup} groupId={groupId} set_groupId={set_groupId} checked_groups={checked_groups} groups={groups} setChecked_groups={handle_setChecked_groups} />
                    </Tab>

                    {/*About*/}
                    <Tab title={<Tooltip title='About' position='bottom' trigger='mouseenter' arrow='true'><span><FontAwesomeIcon color={normalTabColor()} icon={faInfo} />{' '}</span></Tooltip>} eventKey="About">
                        <AboutTab deviceInfo={deviceConfig ? deviceConfig.device.info : null} deviceConfig={deviceConfig} automaticUpdateEnabled={automaticUpdateEnabled} set_automaticUpdateEnabled={set_automaticUpdateEnabled} multipleEdit={multipleEdit} checked_automaticUpdateEnabled={checked_automaticUpdateEnabled}
                            setChecked_automaticUpdateEnabled={handle_setChecked_automaticUpdateEnabled} installUpdatesConfirmationResult={installUpdatesConfirmationResult}
                            version={version} latestDeviceVersion={latestDeviceVersion} connectedState={deviceConnectionState} />
                    </Tab>
                    
                    {/*Personalization*/}
                    <Tab title={<Tooltip title='Personalization' position='bottom' trigger='mouseenter' arrow='true'><span><FontAwesomeIcon color={personalizationTabColor()} icon={faUser} />{' '}</span></Tooltip>} eventKey="Personalization">
                        <PersonalizationTab multipleEdit={multipleEdit} name={name} handleChangeName={handleChangeName} background={background} handleChangeBackground={handleChangeBackground}
                            checked_background={checked_background} setChecked_background={handle_setChecked_background} backgroundValid={backgroundValid}
                            homescreenLayout={homescreenLayout} set_homescreenLayout={set_homescreenLayout} checked_homescreenLayout={checked_homescreenLayout}
                            setChecked_homescreenLayout={handle_setChecked_homescreenLayout}
                            showHowToConnect={showHowToConnect} set_showHowToConnect={set_showHowToConnect} checked_showHowToConnect={checked_showHowToConnect} setChecked_showHowToConnect={handle_setChecked_showHowToConnect}
                            showWiFiQrCode={showWiFiQrCode} set_showWiFiQrCode={set_showWiFiQrCode} checked_showWiFiQrCode={checked_showWiFiQrCode} setChecked_showWiFiQrCode={handle_setChecked_showWiFiQrCode}
                            showGuestAccessWhileInUse={showGuestAccessWhileInUse} set_showGuestAccessWhileInUse={set_showGuestAccessWhileInUse}
                            checked_showGuestAccessWhileInUse={checked_showGuestAccessWhileInUse} setChecked_showGuestAccessWhileInUse={handle_setChecked_showGuestAccessWhileInUse}
                            sessionLayout={sessionLayout} set_sessionLayout={set_sessionLayout} checked_sessionLayout={checked_sessionLayout}
                            setChecked_sessionLayout={handle_setChecked_sessionLayout}
                            displayYourOwnNetwork={displayYourOwnNetwork} handleToggleDisplayYourOwnNetwork={handleToggleDisplayYourOwnNetwork}
                            checked_displayYourOwnNetwork={checked_displayYourOwnNetwork} setChecked_displayYourOwnNetwork={handle_setChecked_displayYourOwnNetwork} wifiSsid={externalWifiSsid} set_wifiSsid={set_externalWifiSsid}
                            wifiPassword={externalWifiPassword} set_wifiPassword={set_externalWifiPassword} />
                    </Tab>

                    {/*Screen Mirroring*/}
                    <Tab title={<Tooltip title='Screen Mirroring' position='bottom' trigger='mouseenter' arrow='true'><span><FontAwesomeIcon color={normalTabColor()} icon={faChromecast} />{' '}</span></Tooltip>} eventKey="ScreenMirroring">
                        <ScreenMirroringTab multipleEdit={multipleEdit}
                            airplayMode={airplayMode} set_airplayMode={set_airplayMode} airplayPassword={airplayPassword} set_airplayPassword={set_airplayPassword} checked_airplay={checked_airplay} setChecked_airplay={handle_setChecked_airplay}
                            googleCastMode={googleCastMode} set_googleCastMode={set_googleCastMode} checked_googleCast={checked_googleCast} setChecked_googleCast={handle_setChecked_googleCast}
                            miracastMode={miracastMode} set_miracastMode={set_miracastMode} checked_miracast={checked_miracast} setChecked_miracast={handle_setChecked_miracast} />
                    </Tab>

                    {/*Moderator Mode*/}
                    <Tab title={<Tooltip title='Moderator' position='bottom' trigger='mouseenter' arrow='true'><span><FontAwesomeIcon color={normalTabColor()} icon={faCommentAltCheck} />{' '}</span></Tooltip>} eventKey="ModeratorMode">
                        <ModeratorModeTab multipleEdit={multipleEdit} moderator={moderator} set_moderator={set_moderator} checked_moderator={checked_moderator}
                            setChecked_moderator={handle_setChecked_moderator} showEndSessionConfirmation={showEndSessionConfirmation} showConfirmationEndSession={showConfirmationEndSession}
                            endSessionConfirmationResult={endSessionConfirmationResult} />
                    </Tab>

                    {/*Network*/}
                    <Tab title={<Tooltip title={networkReadOnly ? 'Network (read only)' : 'Network'} position='bottom' trigger='mouseenter' arrow='true'><span><FontAwesomeIcon color={networkTabColor()} icon={faProjectDiagram} />{' '}</span></Tooltip>} eventKey="Network">
                        <NetworkTab networkReadOnly={networkReadOnly} multipleEdit={multipleEdit} deviceInfo={deviceConfig ? deviceConfig.device.info : null}
                            checked_enterpriseSecurity={checked_enterpriseSecurity} handleEnterpriseSecurityChange={handleEnterpriseSecurityChange} enterpriseSecurity={enterpriseSecurity} setChecked_enterpriseSecurity={handle_setChecked_enterpriseSecurity} enterprisePassword={enterprisePassword} enterpriseUsername={enterpriseUsername} set_enterprisePassword={set_enterprisePassword} set_enterpriseUsername={set_enterpriseUsername}
                            checked_proxy={checked_proxy} handleChangeProxyHostname={handleChangeProxyHostname} handleProxyPortChange={handleProxyPortChange} proxyEnabled={proxyEnabled} proxyHostname={proxyHostname} proxyHostnameValid={proxyHostnameValid} proxyPort={proxyPort} setChecked_proxy={handle_setChecked_proxy} set_proxyEnabled={set_proxyEnabled} />
                    </Tab>

                    {/*Guest Connectivity*/}
                    <Tab title={<Tooltip title='Guest Connectivity' position='bottom' trigger='mouseenter' arrow='true'><span><FontAwesomeIcon color={guestConnectivityTabColor()} icon={faRouter} />{' '}</span></Tooltip>} eventKey="GuestConnectivity">
                        <GuestConnectivityTab builtInHotspot={builtInHotspot} checked_builtInHotspot={checked_builtInHotspot} setChecked_builtInHotspot={handle_setChecked_builtInHotspot}
                            multipleEdit={multipleEdit} checked_hotspotPassword={checked_hotspotPassword} handleSetHotspotPassword={handleSetHotspotPassword} handleToggleHotspot={handleToggleHotspot}
                            hotspotInterfaceInfoRegdom={hotspotInterfaceInfoRegdom}
                            hotspotInterfaceInfoTxPower={hotspotInterfaceInfoTxPower} hotspotInterfaceInfoWidth={hotspotInterfaceInfoWidth} hotspotPassword={hotspotPassword}
                            setChecked_hotspotPassword={setChecked_hotspotPassword} requestedFrequency={requestedFrequency} irChannels={irChannels} set_requestedFrequency={set_requestedFrequency}
                            currentFrequency={currentFrequency}
                            upperChannelsClicked={upperChannelsClicked}
                            checked_upper5g={checked_upper5g} setChecked_upper5g={handle_setChecked_upper5g}

                            showApplyAndRebootDeviceConfirmation={showApplyAndRebootDeviceConfirmation} applyAndRebootDeviceConfirmationResult={applyAndRebootDeviceConfirmationResult}
                            showConfirmationApplyAndRebootDevice={showConfirmationApplyAndRebootDevice} clientIsolation={clientIsolation} set_clientIsolation={set_clientIsolation} guestAccessType={guestAccessType} checked_guestAccessType={checked_guestAccessType}
                            checked_clientIsolation={checked_clientIsolation} setChecked_clientIsolation={handle_setChecked_clientIsolation}
                            handleGuestAccessTypeChange={handleGuestAccessTypeChange} setChecked_guestAccessType={handle_setChecked_guestAccessType} hotspotPasswordValid={hotspotPasswordValid} deviceInfo={deviceConfig ? deviceConfig.device.info : null} />
                    </Tab>

                    {/*Audio and Video*/}
                    <Tab title={<Tooltip title='Audio and Video' position='bottom' trigger='mouseenter' arrow='true'><span><FontAwesomeIcon color={normalTabColor()} icon={faTvMusic} />{' '}</span></Tooltip>} eventKey="AudioAndVideo">
                        <AudioAndVideoTab multipleEdit={multipleEdit} softRotation={softRotation} checked_displayRotation={checked_displayRotation} handleDisplayRotationChange={handleDisplayRotationChange}
                            setChecked_displayRotation={handle_setChecked_displayRotation} displayPowerManagement={displayPowerManagement} set_displayPowerManagement={set_displayPowerManagement}
                            checked_turnDisplayOutOffWhenInactive={checked_turnDisplayOutOffWhenInactive} setChecked_turnDisplayOutOffWhenInactive={handle_setChecked_turnDisplayOutOffWhenInactive}
                            sampleRate={sampleRate} set_sampleRate={set_sampleRate} checked_sampleRate={checked_sampleRate} setChecked_sampleRate={handle_setChecked_sampleRate}
                            sleepTimerMinutes={sleepTimerMinutes} set_sleepTimerMinutes={set_sleepTimerMinutes} forceLowResolution={forceLowResolution} set_forceLowResolution={set_forceLowResolution} airplayAudioReceiver={airplayAudioReceiver} set_airplayAudioReceiver={set_airplayAudioReceiver} hdmiCecEnabled={hdmiCecEnabled} set_hdmiCecEnabled={set_hdmiCecEnabled} hdmiCecSupported={hdmiCecSupported}
                            checked_airplayAudioReceiver={checked_airplayAudioReceiver} setChecked_airplayAudioReceiver={handle_setChecked_airplayAudioReceiver} checked_forceLowResolution={checked_forceLowResolution} setChecked_forceLowResolution={handle_setChecked_forceLowResolution} checked_hdmiCecEnabled={checked_hdmiCecEnabled} setChecked_hdmiCecEnabled={handle_setChecked_hdmiCecEnabled}
                        />
                    </Tab>

                    {/*Localization*/}
                    <Tab title={<Tooltip title='Localization' position='bottom' trigger='mouseenter' arrow='true'><span><FontAwesomeIcon color={normalTabColor()} icon={faGlobeStand} />{' '}</span></Tooltip>} eventKey="Localization">
                        <LocalizationTab multipleEdit={multipleEdit} language={language} set_language={set_language} checked_language={checked_language} setChecked_language={handle_setChecked_language}
                            clock={clock} set_clock={set_clock} checked_clock={checked_clock} setChecked_clock={handle_setChecked_clock}
                            timezone={timezone} set_timezone={set_timezone} checked_timezone={checked_timezone} setChecked_timezone={handle_setChecked_timezone} wifiCountry={wifiCountry} set_wifiCountry={set_wifiCountry} checked_country={checked_country} setChecked_country={handle_setChecked_country} />
                    </Tab>

                    {/*Security*/}
                    <Tab title={<Tooltip title='Security' position='bottom' trigger='mouseenter' arrow='true'><span><FontAwesomeIcon color={normalTabColor()} icon={faShieldCheck} />{' '}</span></Tooltip>} eventKey="Security">
                        <SecurityTab multipleEdit={multipleEdit} sslPrivateKey={sslPrivateKey} sslCertificate={sslCertificate} set_sslPrivateKey={set_sslPrivateKey} set_sslCertificate={set_sslCertificate}
                            checked_privateKeyAndCert={checked_privateKeyAndCert} setChecked_privateKeyAndCert={handle_setChecked_privateKeyAndCert}
                            enableDeviceManagement={enableDeviceManagement} set_enableDeviceManagement={set_enableDeviceManagement} checked_enableDeviceManagement={checked_enableDeviceManagement}
                            setChecked_enableDeviceManagement={handle_setChecked_enableDeviceManagement} deviceManagementPassword={deviceManagementPassword} handleChangeDeviceManagementPassword={handleChangeDeviceManagementPassword}
                            checked_deviceManagementPassword={checked_deviceManagementPassword} setChecked_deviceManagementPassword={handle_setChecked_deviceManagementPassword} deviceManagementPasswordValid={deviceManagementPasswordValid} />
                    </Tab>
                </Tabs>
            </div>
        );
    }
}
