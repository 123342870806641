import React from 'react';
import '../main.css';

export default function Switch({ isOn, handleToggle, labelText, visible = true, readonly = false, addClassName = '' }) {
    let labelCss = readonly ? 'react-switch-label-disabled' : 'react-switch-label';

    return (
        <React.Fragment>
            <input
                checked={isOn}
                onChange={handleToggle}
                className={readonly ? "react-switch-checkbox-disabled" : "react-switch-checkbox"}
                id={`react-switch-new` + labelText}
                type="checkbox"
                disabled={readonly ? true : false}
            />
            <label style={{ background: isOn && '#06D6A0' }} className={visible ? labelCss : labelCss + " invisible"} htmlFor={`react-switch-new` + labelText}>
                <span className={readonly ? 'react-switch-button-disabled' : 'react-switch-button'} />
            </label>
            <label className={visible ? 'form-label checkboxLabel ' + addClassName : 'invisiblePosition form-label checkboxLabel'}>{labelText}</label>
        </React.Fragment>
    );
};
