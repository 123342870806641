import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    padding: 16px;
    position: absolute;
    bottom: ${props => props.shift}px;
    left: ${props => props.left};
    margin: 0 auto;
    z-index: 999;
    transition: bottom 1.0s ease;
    border-radius: 5px;
    width: ${props => props.width};
`;

export default function Notification({ msg, showNotification, set_showNotification, type = 'info', left = '35%', duration = 5000, width = null }) {  // 'type' can also be 'error'
    const distance = 550;//140; // 550 is good for the main screen.
    const [shift, set_shift] = useState(-distance);
    const [showing, set_showing] = useState(false);
    const [timeout, set_timeout] = useState(-1);

    const color = type === 'error' ? 'white' : 'black';
    const backgroundColor = type === 'error' ? 'darkred' : 'whitesmoke';

    //const myWidth = type === 'error' ? 'auto' : '250px';
    let myWidth = '250px';
    if (type === 'error') {
        if (width === null) {
            myWidth = 'auto';
        } else {
            myWidth = width;
        }
    }

    useEffect(() => {
        return function cleanup() {
            if (timeout !== -1) {
                clearTimeout(timeout);
            }
        }
    }, [timeout]);

    if (showNotification && !showing) {
        set_shift(distance / 8);
        set_showNotification(false);
        set_showing(true);

        let t = setTimeout(() => {
            set_shift(-distance);
            set_showing(false);
        }, duration);

        set_timeout(t);
    }

    return (
        <Container shift={shift} color={color} backgroundColor={backgroundColor} left={left} width={myWidth}>{msg}</Container>
    );
}
