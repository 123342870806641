import React from 'react';
import { Form, Col } from 'react-bootstrap';
import Switch from '../Switch';
import PlusX from '../PlusX';
import {TURN_DISPLAY_OFF_INACTIVE_NEVER, TURN_DISPLAY_OFF_INACTIVE_IMMEDIALLY, TURN_DISPLAY_OFF_INACTIVE_1_MINUTE, TURN_DISPLAY_OFF_INACTIVE_15_MINUTES, TURN_DISPLAY_OFF_INACTIVE_1_HOUR,
    TURN_DISPLAY_OFF_INACTIVE_3_HOURS, SAMPLE_RATE_44100, SAMPLE_RATE_48000, SAMPLE_RATE_96000, SAMPLE_RATE_192000} from '../Utils';

export default function AudioAndVideoTab({ multipleEdit, softRotation, checked_displayRotation, handleDisplayRotationChange, setChecked_displayRotation,
    displayPowerManagement, set_displayPowerManagement, checked_turnDisplayOutOffWhenInactive, setChecked_turnDisplayOutOffWhenInactive,
    sampleRate, set_sampleRate, checked_sampleRate, setChecked_sampleRate, sleepTimerMinutes, set_sleepTimerMinutes, forceLowResolution, set_forceLowResolution, airplayAudioReceiver, set_airplayAudioReceiver, hdmiCecEnabled, set_hdmiCecEnabled, hdmiCecSupported,
    checked_airplayAudioReceiver, setChecked_airplayAudioReceiver, checked_forceLowResolution, setChecked_forceLowResolution, checked_hdmiCecEnabled, setChecked_hdmiCecEnabled}) {

    function mapTurnDisplayOutOffWhenInactive() {
        if (multipleEdit && !checked_turnDisplayOutOffWhenInactive) return null;
        let retval = [];
        retval.push(<option key={TURN_DISPLAY_OFF_INACTIVE_NEVER} value={TURN_DISPLAY_OFF_INACTIVE_NEVER}>Never</option>);
        retval.push(<option key={TURN_DISPLAY_OFF_INACTIVE_IMMEDIALLY} value={TURN_DISPLAY_OFF_INACTIVE_IMMEDIALLY}>Immediately</option>);
        retval.push(<option key={TURN_DISPLAY_OFF_INACTIVE_1_MINUTE} value={TURN_DISPLAY_OFF_INACTIVE_1_MINUTE}>After 1 Minute</option>);
        retval.push(<option key={TURN_DISPLAY_OFF_INACTIVE_15_MINUTES} value={TURN_DISPLAY_OFF_INACTIVE_15_MINUTES}>After 15 Minutes</option>);
        retval.push(<option key={TURN_DISPLAY_OFF_INACTIVE_1_HOUR} value={TURN_DISPLAY_OFF_INACTIVE_1_HOUR}>After 1 Hour</option>);
        retval.push(<option key={TURN_DISPLAY_OFF_INACTIVE_3_HOURS} value={TURN_DISPLAY_OFF_INACTIVE_3_HOURS}>After 3 Hours</option>);
        return retval;
    }

    function mapDisplayRotation() {
        if (multipleEdit && !checked_displayRotation) return null;
        let retval = [];
        retval.push(<option key='rotateNone' value='0'>None</option>);
        retval.push(<option key='rotateRight' value='90'>Clockwise</option>);
        retval.push(<option key='rotateUpsideDown' value='180'>Invert</option>);
        retval.push(<option key='rotateLeft' value='270'>Counterclockwise</option>);
        return retval;
    }

    function getTurnDisplayOutOffWhenInactive() {
        if (displayPowerManagement === false) {
            return TURN_DISPLAY_OFF_INACTIVE_NEVER;
        } else {
            if (sleepTimerMinutes < 1) {
                return TURN_DISPLAY_OFF_INACTIVE_IMMEDIALLY;
            } else if (sleepTimerMinutes === 1) {
                return TURN_DISPLAY_OFF_INACTIVE_1_MINUTE;
            } else if (sleepTimerMinutes <= 15) {
                return TURN_DISPLAY_OFF_INACTIVE_15_MINUTES;
            } else if (sleepTimerMinutes <= 60) {
                return TURN_DISPLAY_OFF_INACTIVE_1_HOUR;
            } else {
                return TURN_DISPLAY_OFF_INACTIVE_3_HOURS;
            }
        }
    }

    function handleAirPlayAudioReceiverChange() {
        set_airplayAudioReceiver(!airplayAudioReceiver);
    }
    function handleForceLowResolutionChange() {
        set_forceLowResolution(!forceLowResolution);
    }

    function handleHdmiCecEnableChanged() {
        if (!multipleEdit && !hdmiCecSupported) return;
        set_hdmiCecEnabled(!hdmiCecEnabled)
    }

    function dropdownChangeTurnDisplayOutOffWhenInactive(e) {
        const val = e.target.value;

        switch (val) {
            case TURN_DISPLAY_OFF_INACTIVE_NEVER:
                set_displayPowerManagement(false);
                set_sleepTimerMinutes(0);
                break;

            case TURN_DISPLAY_OFF_INACTIVE_IMMEDIALLY:
                set_displayPowerManagement(true);
                set_sleepTimerMinutes(0);
                break;

            case TURN_DISPLAY_OFF_INACTIVE_1_MINUTE:
                set_displayPowerManagement(true);
                set_sleepTimerMinutes(1);
                break;

            case TURN_DISPLAY_OFF_INACTIVE_15_MINUTES:
                set_displayPowerManagement(true);
                set_sleepTimerMinutes(15);
                break;

            case TURN_DISPLAY_OFF_INACTIVE_1_HOUR:
                set_displayPowerManagement(true);
                set_sleepTimerMinutes(60);
                break;

            default:
                set_displayPowerManagement(true);
                set_sleepTimerMinutes(60 * 3);
                break;
        }
    }

    function mapSampleRate() {
        if (multipleEdit && !checked_sampleRate) return null;
        let retval = [];
        retval.push(<option key={SAMPLE_RATE_44100} value={SAMPLE_RATE_44100}>44100 Hz</option>);
        retval.push(<option key={SAMPLE_RATE_48000} value={SAMPLE_RATE_48000}>48000 Hz</option>);
        retval.push(<option key={SAMPLE_RATE_96000} value={SAMPLE_RATE_96000}>96000 Hz</option>);
        retval.push(<option key={SAMPLE_RATE_192000} value={SAMPLE_RATE_192000}>192000 Hz</option>);
        return retval;
    }

    function dropdownChangeSampleRate(e) {
        set_sampleRate(e.target.value);
    }

    return (
        <Form>
            <Form.Label className='tabLabel'>Audio and Video</Form.Label>
            <Form.Group controlId="form_displayRotation">
                <Form.Row>

                    <Col xs='8'>

                        <Form.Group>
                            {/*AirPlay Audio Receiver*/}
                            <Form.Row>
                                <Col className='switchCol' xs='9'>
                                    <Switch isOn={airplayAudioReceiver} handleToggle={handleAirPlayAudioReceiverChange} labelText='AirPlay Audio Receiver' visible={!(multipleEdit && !checked_airplayAudioReceiver)} />
                                </Col>
                                <Col>
                                    <PlusX name='plusX_airplayAudioReceiver' checked={checked_airplayAudioReceiver} setChecked={setChecked_airplayAudioReceiver} multipleEdit={multipleEdit} className='plusX_airplayAudioReceiver' />
                                </Col>
                            </Form.Row>
                            {/*Force Low Resolution*/}
                            <Form.Row>
                                <Col className='switchCol' xs='9'>
                                    <Switch isOn={forceLowResolution} handleToggle={handleForceLowResolutionChange} labelText='Lower Video Quality' visible={!(multipleEdit && !checked_forceLowResolution)} />
                                </Col>
                                <Col>
                                    <PlusX name='plusX_forceLowResolution' checked={checked_forceLowResolution} setChecked={setChecked_forceLowResolution} multipleEdit={multipleEdit} className='plusX_forceLowResolution' />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col className='switchCol' xs='9'>
                                    <Switch isOn={hdmiCecSupported && hdmiCecEnabled} handleToggle={handleHdmiCecEnableChanged} labelText={multipleEdit || hdmiCecSupported ? 'HDMI-CEC' : 'HDMI-CEC (not supported)'} readonly={!multipleEdit && !hdmiCecSupported} visible={!(multipleEdit && !checked_hdmiCecEnabled)} />
                                </Col>
                                <Col>
                                    <PlusX name='plusX_hdmiCecEnabled' checked={checked_hdmiCecEnabled} setChecked={setChecked_hdmiCecEnabled} multipleEdit={multipleEdit} className='plusX_hdmiCecEnabled' />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                    </Col>

                </Form.Row>
                <Form.Row>
                    <Col xs='12'>
                        {/*Display Rotation*/}
                        <Form.Group>
                            <Form.Row>
                                <Col xs='7'>
                                    <Form.Label>Screen Rotation</Form.Label>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs='6'>
                                    <Form.Control as='select' onChange={handleDisplayRotationChange} value={softRotation}>
                                        {mapDisplayRotation()}
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <PlusX name='plusX_displayRotation' checked={checked_displayRotation} setChecked={setChecked_displayRotation} multipleEdit={multipleEdit} className='plusX_displayRotation' />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        {/*Turn Display Output off When Inactive*/}
                        <Form.Group>
                            <Form.Row>
                                <Col xs='7'>
                                    <Form.Label>Turn Display Output off When Inactive</Form.Label>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs='6'>
                                    <Form.Control as='select' onChange={dropdownChangeTurnDisplayOutOffWhenInactive} value={getTurnDisplayOutOffWhenInactive()}>
                                        {mapTurnDisplayOutOffWhenInactive()}
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <PlusX name='plusX_turnDisplayOutOffWhenInactive' checked={checked_turnDisplayOutOffWhenInactive} setChecked={setChecked_turnDisplayOutOffWhenInactive} multipleEdit={multipleEdit}
                                        className='plusX_turnDisplayOutOffWhenInactive' />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        {/*Sample Rate*/}
                        <Form.Group className='groupSampleRate'>
                            <Form.Row>
                                <Col xs='7'>
                                    <Form.Label>Sample Rate</Form.Label>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs='6'>
                                    <Form.Control as='select' onChange={dropdownChangeSampleRate} value={sampleRate}>
                                        {mapSampleRate()}
                                    </Form.Control>
                                </Col>
                                <Col xs='6'>
                                    <PlusX name='plusX_sampleRate' checked={checked_sampleRate} setChecked={setChecked_sampleRate} multipleEdit={multipleEdit}
                                        className='plusX_sampleRate' />
                                </Col>

                            </Form.Row>
                        </Form.Group>

                        {/*Screen Resolution*/}
                        {/*<Form.Group>*/}
                        {/*    <Form.Row>*/}
                        {/*        <Col xs='11'>*/}
                        {/*            <Form.Label>Screen Resolution - Coming Soon</Form.Label>*/}
                        {/*        </Col>*/}
                        {/*    </Form.Row>*/}
                        {/*</Form.Group>*/}

                    </Col>
                </Form.Row>


            </Form.Group>
        </Form>
    );
}
