import React, { useState } from 'react';
import { Button, Container, Col, Row, Form } from 'react-bootstrap';
import axios from 'axios';
import '../main.css';
import { cloneDeep } from 'lodash';
import authService from './api-authorization/AuthorizeService';
import Utils from './Utils';

export default function AddDevicePane({ paneVisible, groups, set_groups, closeAddDevicePane, displayError }) {
    const [cloudToken, set_cloudToken] = useState('');
    const [groupId, set_groupId] = useState('');

    function handleCloudTokenChanged(value) {
        if ((Utils.isNumeric(value) || value === '') && !value.includes('.') && !value.includes(',')) {
            set_cloudToken(value);
        }
    }

    function handleCloudTokenKeyPress(e) {
        var code = e.keyCode || e.which;
        if (code === 13) { //13 is the enter keycode
            handleApplyClick();
        }
    }

    function handleApplyClick() {
        authService.getAccessToken()
            .then(authToken => {
                callAdoptDevice(authToken);
            });
    }

    function closePane() {
        set_cloudToken('');
        set_groupId('');
        closeAddDevicePane();
    }

    function callAdoptDevice(authToken) {
        let chosenGroup = groupId || groups[0].deviceId;
        let adoptInput = { cloudToken, groupId: chosenGroup };

        axios.post(`CloudManagement/AdoptDevice`, adoptInput, { headers: !authToken ? {} : { 'Authorization': `Bearer ${authToken}` } })
            .then(response => {
                try {
                    console.log('AdoptDevice returned: ', response.data);
                    let device = response.data;
                    let group = groups.find(g => g.deviceId === device.tags.groupId);
                    if (group) {
                        const deviceIndex = group.devices.findIndex(d => d.deviceId === device.deviceId);
                        if (deviceIndex === -1) {
                            device.config = {
                                device: { name: cloudToken },
                                services: {},
                            };
                            group.devices.push(device);
                            set_groups(cloneDeep(groups));
                        }
                    }
                    closePane();
                } catch (e) {
                    console.error("error in adopting device", e)
                }
            })
            .catch(error => {
                console.error('AdoptDevice returned an error: ' + JSON.stringify(error));
                if (error.message.includes('401')) {
                    displayError('Please log in again');
                } else if (error.message.includes('404')) {
                    displayError(`Could not locate the device using token: ${cloudToken}. Please validate the token and try again.`);
                } else {
                    displayError("Unexpected error occurred: " + error.message);
                }

                closePane();
            }
        );

    }

    if (!paneVisible) {
        return (
            <div id='addDevicePane' className='hide'>
            </div>
        );
    } else {
        return (
            <Container id='addDevicePane' className='show'>
                {/*Header and buttons*/}
                <Row>
                    <Col xs='8'>
                        <h3>Attach Device to Cloud</h3>
                    </Col>
                    <Col className='text-right noLeftAndRightPadding' xs='4'>
                        <Button className='saveButton elementMargin' variant='primary' onClick={handleApplyClick} disabled={cloudToken.length === 0 ? true : false} >ATTACH</Button>
                        <Button className='saveButton' variant='secondary' onClick={closePane}>CANCEL</Button>
                    </Col>
                </Row>

                {/*Cloud token*/}
                <Row className='addDeviceRow'>
                    <Col xs='8'>
                        <Form.Label>Cloud Token</Form.Label>
                        <Form.Control autoFocus type='text' placeholder='Add Cloud token' value={cloudToken}
                            onChange={(e) => { handleCloudTokenChanged(e.target.value) }} onKeyPress={handleCloudTokenKeyPress} maxLength='8' className='cloudTokenTextBox' />
                    </Col>
                </Row>

                {/*Group list*/}
                <Row className='addDeviceRow'>
                    <Col xs='10'>
                        <Form.Label>Group</Form.Label>
                        <Form.Control as="select" onChange={(e) => set_groupId(e.target.value)}>
                            {groups.map(group => <option value={group.deviceId} key={group.deviceId}>{group.groupName}</option>)}
                        </Form.Control>
                    </Col>
                </Row>
            </Container>
        );
    }
}
