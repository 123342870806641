import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Switch from './Switch';

export default function ConfirmationDialog({ show, text, result, header = 'Confirmation', extraQuestion = '' }) {
    const [questionChecked, set_questionChecked] = useState(false);

    function callTrueResult() {
        if (extraQuestion === '') {
            result(true);
        } else {
            result(true, questionChecked);
        }
        
        set_questionChecked(false);
    }

    function callFalseResult() {
        result(false);
        set_questionChecked(false);
    }

    return (
        <Modal show={show} onHide={callFalseResult}>
            <Modal.Header closeButton>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
                {
                    extraQuestion === '' ? null : (<div className='confirmExtraQ'><Switch isOn={questionChecked} handleToggle={() => { set_questionChecked(!questionChecked) }} labelText={extraQuestion} addClassName='questionColor' /></div>)
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={callTrueResult}>
                    Yes
                </Button>
                <Button variant="secondary" onClick={callFalseResult}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
