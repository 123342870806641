import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import 'react-tippy/dist/tippy.css';
import { Tooltip, } from 'react-tippy';

export default function PlusX({ name, checked, setChecked, multipleEdit, plusXPadding = '' }) {
    function handleChange() {
        setChecked(!checked);
    }

    return (
        <React.Fragment>
            <Tooltip title={checked ? "Stop changing this property" : "Change this property"} position='right' trigger='mouseenter' arrow='true'>
                <input checked={checked} onChange={handleChange} className='plus-x-checkbox' id={name} type="checkbox" />
                <label htmlFor={name} className={multipleEdit ? 'plusXShow ' + plusXPadding : 'plusXHide'} >
                    <FontAwesomeIcon icon={checked ? faTimesCircle : faPlusCircle} className='plusX' size='lg' color={checked ? 'red' : 'green'} />
                </label>
            </Tooltip>
        </React.Fragment>
    );
}
