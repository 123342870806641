import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import PlusX from '../PlusX';
import Switch from '../Switch';
import ConfirmationDialog from '../ConfirmationDialog';

export default function ModeratorModeTab({ multipleEdit, moderator, set_moderator, checked_moderator, setChecked_moderator,
	showEndSessionConfirmation, showConfirmationEndSession, endSessionConfirmationResult }) {

    return (
        <Form className='moderatorForm'>
            <Form.Label className='tabLabel'>Moderator</Form.Label>
            <Form.Group controlId="form_moderatorMode">
                <Form.Row>
                    <Col xs='6' className='switchCol moderatorSwitch'>
                        <Switch isOn={moderator} handleToggle={() => { set_moderator(!moderator) }} labelText='Moderator' visible={!(multipleEdit && !checked_moderator)} />
                    </Col>
                    <Col xs='6'>
                        <PlusX name='plusX_moderator' checked={checked_moderator} setChecked={setChecked_moderator} multipleEdit={multipleEdit} />
                    </Col>
                </Form.Row>
            </Form.Group>

			<Form.Group>
				<Form.Row className='toolsButtonRow'>
                    <Col xs='6'>
                        <Button className='editButton toolsButton endSessionButton' variant='primary' onClick={showEndSessionConfirmation} >
                            END ALL SESSIONS
                        </Button>
                        <ConfirmationDialog show={showConfirmationEndSession} text={`Are you sure you want to end the session${multipleEdit ? 's' : ''}?`} result={endSessionConfirmationResult} />
                    </Col>
                </Form.Row>
			</Form.Group>
        </Form>
    );
}
