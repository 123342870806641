import { v4 as uuidv4 } from 'uuid';
import authService from '../api-authorization/AuthorizeService';
import axios from "axios";
import { toast } from 'react-toastify';

export default class UserHandler {
	USERMANAGEMENT_TIMEOUT = 20000; // Timeout in milliseconds.

	static async fetchOrganizationData(set_error500) {
		try {
			set_error500('');

			let token = await authService.getAccessToken();

			let response = await axios.get('UserManagement/GetOrganizationData', {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
				timeout: UserHandler.USERMANAGEMENT_TIMEOUT
			});

			const data = response.data;

			return { organizationId: data.organizationId, organizationName: data.organizationName };
		} catch (error) {
			console.log('Error getting User Management Data: ' + error);
			set_error500(error);
		}
	}

	static async changeOrganizationName(orgName, set_orgName, orgNameForRenaming, set_orgNameForRenaming, set_isRenamingOrgName, set_error500) {
        set_orgNameForRenaming(null);
		set_isRenamingOrgName(false);

        if (orgName !== orgNameForRenaming) {
			// Change the organization name in the UI:
			const savedOrgName = orgName;
			set_orgName(orgNameForRenaming);

			try {
				// Call the backend:
				set_error500('');

				let token = await authService.getAccessToken();

				await axios({
					url: `UserManagement/ChangeOrganizationName/${orgNameForRenaming}`,
					method: 'PUT',
					headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
				});
			} catch (error) {
				console.log('Error changing the organization name: ' + error);
				set_error500(error);
				set_orgName(savedOrgName);
			}
        }
    }

	static async fetchUserManagementData(set_roles, set_users, set_error500) {
		try {
			set_error500('');

			let token = await authService.getAccessToken();

			let response = await axios.get('UserManagement/GetUserManagementData', {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
				timeout: UserHandler.USERMANAGEMENT_TIMEOUT
			});

			const data = response.data;

			// Save the data:
			set_roles(data.roles);
			set_users(data.users);
		} catch (error) {
			console.log('Error getting User Management Data: ' + error);
			set_error500(error);
		}
	}

	static async addUser(RoleId, Name, Email, users, set_users, hideModal, set_error500, roles) {
		// The front end creates the uuid for the user id:
		const UserId = uuidv4();
		hideModal();
		toast.success(`Creating user ${Name}...`);

		try {
			// Call the backend:
			set_error500('');

			let token = await authService.getAccessToken();

			await axios({
				url: 'UserManagement/AddUser',
				method: 'POST',
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
				data: { UserId, RoleId, Name, Email }
			});

			// Update the UI:
			const newUser = this.constructUser(UserId, RoleId, Name, Email, roles);
			let newUsers = [...users, newUser];
			set_users(newUsers.sort((u1, u2) => u1.name.localeCompare(u2.name)));
			toast.success(`${Name} created`);
		} catch (error) {
			if (error.response.status === 409) {
				toast.error('User with that email already exists');
			} else {
				console.log('Unexpected error from AddUser: ' + JSON.stringify(error));
				toast.error('Internal error');
            }
		}
	}

	static async editUser(UserId, RoleId, Name, Email, users, roles, hideModal, set_error500, set_users) {
		const tmpUsers = JSON.parse(JSON.stringify(users)); // Deep copy.
		let user = tmpUsers.find(u => u.id === UserId);
		const prevRole = user.role;
		const nextRole = roles.find(r => r.id === RoleId);
		
		if (prevRole.canManageUsers && !nextRole.canManageUsers && UserHandler.noOtherCloudAdmin(user, users)) {
			// This change is not allowed because there has to be at least one Cloud Admin.
			toast.success('There has to be at least one Cloud Admin');
			hideModal();
			return;
		}

		try {
			// Call the backend:
			set_error500('');

			let token = await authService.getAccessToken();

			await axios({
				url: `UserManagement/EditUser/${UserId}`,
				method: 'PUT',
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
				data: { RoleId, Name, Email }
			});

			// Update the UI:
			if (user) {
				user.name = Name;
				user.email = Email;
				user.role = roles.find(r => r.id === RoleId);
				set_users(tmpUsers);
				toast.success(`User ${Name} was changed`);
			} else {
				console.log(`Couldn't find user ${UserId} - ${Name} in users: ${tmpUsers}`);
				toast.error('Unexpected error happened');
			}
		} catch (error) {
			if (error.response.status === 409) {
				toast.error('User with that email already exists');
			} else if (error.response.status === 404) {
				toast.error(`User ${Name} not found in the database. Please contact administrator`);
			}
			else {
				console.log('Unexpected error from EditUser: ' + JSON.stringify(error));
				toast.error('Unexpected error happened');
            }
		}

		hideModal();
	}

	static removeUserClick(user, set_showRemoveUserConfirmation, set_userForRemoval, users) {
		if (user.role.canManageUsers && UserHandler.noOtherCloudAdmin(user, users)) {
			// The end user is about to remove the very last Cloud Admin.
			// This is not allowed because then no one will be able to create new users.
			toast.error('There has to be at least one Cloud Admin');
		} else {
			// Present confirmation about the user removal:
			set_showRemoveUserConfirmation(true);
			set_userForRemoval(user);
		}
	}

	static removeUserConfirmationResult(result, set_showRemoveUserConfirmation, set_userForRemoval, userForRemoval, users, set_users, set_error500) {
		set_showRemoveUserConfirmation(false);
		if (result === true) {
			UserHandler.removeUser(userForRemoval, users, set_users, set_error500);
		}
		set_userForRemoval(null);
	}

	static async removeUser(userForRemoval, users, set_users, set_error500) {
		try {
			set_error500('');

			let token = await authService.getAccessToken();

			await axios({
				url: 'UserManagement/RemoveUser',
				method: 'DELETE',
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
				data: { UserId: userForRemoval.id }
			});

			// Remove user from 'users':
			let tmp = [...users];
			tmp = tmp.filter(u => u.id !== userForRemoval.id);
			set_users(tmp);
			toast.success(userForRemoval.name + ' removed');
		} catch (error) {
			console.log('Error removing user: ' + error);
		}
	}

	static noOtherCloudAdmin(user, users) {
		return !users.find(u => u.role.canManageUsers && u.id !== user.id);
	}

	static constructUser(UserId, RoleId, Name, Email, roles) {
		const role = roles.find(r => r.id === RoleId);

		const retval = {
			id: UserId,
			name: Name,
			email: Email,
			role,
			created: new Date()
		};

		return retval;
	}

	static async confirmAccount(Email, Token, Password, succeeded, failed) {
		try {
			await axios({
				url: 'UserManagement/ConfirmAccount',
				method: 'POST',
				data: { Email, Token, Password }
			});

			succeeded();
		} catch (error) {
			failed();
		}
	}
}
