import React from 'react';

import { Form, Col } from 'react-bootstrap';
import PlusX from '../PlusX';
import {ENGLISH, HOUR_OFF, HOUR_12, HOUR_24 } from '../Utils';

export default function LocalizationTab({ multipleEdit, wifiCountry, set_wifiCountry, checked_country, setChecked_country, language, set_language, checked_language, setChecked_language, clock, set_clock, checked_clock, setChecked_clock,
    timezone, set_timezone, checked_timezone, setChecked_timezone }) {

    function mapLanguage() {
        if (multipleEdit && !checked_language) return null;
        let retval = [];
        retval.push(<option key={ENGLISH} value={ENGLISH}>English</option>);
        return retval;
    }

    function dropdownChangeWiFiCountry(e) {
        set_wifiCountry(e.target.value);
    }

    function dropdownChangeLanguage(e) {
        set_language(e.target.value);
    }

    function mapClock() {
        if (multipleEdit && !checked_clock) return null;
        let retval = [];
        retval.push(<option key={HOUR_OFF} value={HOUR_OFF}>Off</option>);
        retval.push(<option key={HOUR_12} value={HOUR_12}>12-hour</option>);
        retval.push(<option key={HOUR_24} value={HOUR_24}>24-hour</option>);
        return retval;
    }

    function dropdownChangeClock(e) {
        set_clock(e.target.value);
    }

    function mapCountry() {
        if (multipleEdit && !checked_country) return null;
        let retval = [];
        retval.push(<option key="us" value="us">United States</option>)
        retval.push(<option key="gb" value="gb">United Kingdom</option>)
        retval.push(<option key="al" value="al">Albania</option>)
        retval.push(<option key="dz" value="dz">Algeria</option>)
        retval.push(<option key="ar" value="ar">Argentina</option>)
        retval.push(<option key="am" value="am">Armenia</option>)
        retval.push(<option key="aw" value="aw">Aruba</option>)
        retval.push(<option key="au" value="au">Australia</option>)
        retval.push(<option key="at" value="at">Austria</option>)
        retval.push(<option key="az" value="az">Azerbaijan</option>)
        retval.push(<option key="bs" value="bs">Bahamas</option>)
        retval.push(<option key="bh" value="bh">Bahrain</option>)
        retval.push(<option key="bd" value="bd">Bangladesh</option>)
        retval.push(<option key="bb" value="bb">Barbados</option>)
        retval.push(<option key="by" value="by">Belarus</option>)
        retval.push(<option key="be" value="be">Belgium</option>)
        retval.push(<option key="bz" value="bz">Belize</option>)
        retval.push(<option key="bm" value="bm">Bermuda</option>)
        retval.push(<option key="bo" value="bo">Bolivia</option>)
        retval.push(<option key="ba" value="ba">Bosnia Herz</option>)
        retval.push(<option key="br" value="br">Brazil</option>)
        retval.push(<option key="bn" value="bn">Brunei Darussalam</option>)
        retval.push(<option key="bg" value="bg">Bulgaria</option>)
        retval.push(<option key="kh" value="kh">Cambodia</option>)
        retval.push(<option key="ca" value="ca">Canada</option>)
        retval.push(<option key="cl" value="cl">Chile</option>)
        retval.push(<option key="cn" value="cn">China</option>)
        retval.push(<option key="co" value="co">Colombia</option>)
        retval.push(<option key="cr" value="cr">Costa Rica</option>)
        retval.push(<option key="hr" value="hr">Croatia</option>)
        retval.push(<option key="cy" value="cy">Cyprus</option>)
        retval.push(<option key="cz" value="cz">Czech</option>)
        retval.push(<option key="dk" value="dk">Denmark</option>)
        retval.push(<option key="do" value="do">Dominican Republic</option>)
        retval.push(<option key="ec" value="ec">Ecuador</option>)
        retval.push(<option key="eg" value="eg">Egypt</option>)
        retval.push(<option key="sv" value="sv">El Salvador</option>)
        retval.push(<option key="ee" value="ee">Estonia</option>)
        retval.push(<option key="fi" value="fi">Finland</option>)
        retval.push(<option key="fr" value="fr">France</option>)
        retval.push(<option key="ge" value="ge">Georgia</option>)
        retval.push(<option key="de" value="de">Germany</option>)
        retval.push(<option key="gr" value="gr">Greece</option>)
        retval.push(<option key="gl" value="gl">Greenland</option>)
        retval.push(<option key="gd" value="gd">Grenada</option>)
        retval.push(<option key="gu" value="gu">Guam</option>)
        retval.push(<option key="gt" value="gt">Guatemala</option>)
        retval.push(<option key="ht" value="ht">Haiti</option>)
        retval.push(<option key="hn" value="hn">Honduras</option>)
        retval.push(<option key="hk" value="hk">Hong Kong</option>)
        retval.push(<option key="hu" value="hu">Hungary</option>)
        retval.push(<option key="is" value="is">Iceland</option>)
        retval.push(<option key="in" value="in">India</option>)
        retval.push(<option key="id" value="id">Indonesia</option>)
        retval.push(<option key="ir" value="ir">Iran</option>)
        retval.push(<option key="ie" value="ie">Ireland</option>)
        retval.push(<option key="il" value="il">Israel</option>)
        retval.push(<option key="it" value="it">Italy</option>)
        retval.push(<option key="jm" value="jm">Jamaica</option>)
        retval.push(<option key="jp" value="jp">Japan</option>)
        retval.push(<option key="jo" value="jo">Jordan</option>)
        retval.push(<option key="kz" value="kz">Kazakhstan</option>)
        retval.push(<option key="ke" value="ke">Kenya</option>)
        retval.push(<option key="kw" value="kw">Kuwait</option>)
        retval.push(<option key="lv" value="lv">Latvia</option>)
        retval.push(<option key="lb" value="lb">Lebanon</option>)
        retval.push(<option key="li" value="li">Liechtenstein</option>)
        retval.push(<option key="lt" value="lt">Lithuania</option>)
        retval.push(<option key="lu" value="lu">Luxembourg</option>)
        retval.push(<option key="mo" value="mo">Macau</option>)
        retval.push(<option key="mk" value="mk">Macedonia</option>)
        retval.push(<option key="my" value="my">Malaysia</option>)
        retval.push(<option key="mt" value="mt">Malta</option>)
        retval.push(<option key="mu" value="mu">Maritius</option>)
        retval.push(<option key="mx" value="mx">Mexico</option>)
        retval.push(<option key="mc" value="mc">Monaco</option>)
        retval.push(<option key="me" value="me">Montenegro</option>)
        retval.push(<option key="ma" value="ma">Morocco</option>)
        retval.push(<option key="np" value="np">Nepal</option>)
        retval.push(<option key="nl" value="nl">Netherlands</option>)
        retval.push(<option key="an" value="an">Netherlands Antilles</option>)
        retval.push(<option key="nz" value="nz">New Zealand</option>)
        retval.push(<option key="kp" value="kp">North Korea</option>)
        retval.push(<option key="ni" value="ni">Nicaragua</option>)
        retval.push(<option key="no" value="no">Norway</option>)
        retval.push(<option key="om" value="om">Oman</option>)
        retval.push(<option key="pk" value="pk">Pakistan</option>)
        retval.push(<option key="pa" value="pa">Panama</option>)
        retval.push(<option key="pg" value="pg">Papua New Guinea</option>)
        retval.push(<option key="py" value="py">Paraguay</option>)
        retval.push(<option key="pe" value="pe">Peru</option>)
        retval.push(<option key="ph" value="ph">Philippines</option>)
        retval.push(<option key="pl" value="pl">Poland</option>)
        retval.push(<option key="pt" value="pt">Portugal</option>)
        retval.push(<option key="pr" value="pr">Puerto Rico</option>)
        retval.push(<option key="qa" value="qa">Qatar</option>)
        retval.push(<option key="ro" value="ro">Romania</option>)
        retval.push(<option key="ru" value="ru">Russia</option>)
        retval.push(<option key="sa" value="sa">Saudi Arabia</option>)
        retval.push(<option key="rs" value="rs">Serbia</option>)
        retval.push(<option key="cs" value="cs">Serbia Montenegro</option>)
        retval.push(<option key="sg" value="sg">Singapore</option>)
        retval.push(<option key="sk" value="sk">Slovakia</option>)
        retval.push(<option key="si" value="si">Slovenia</option>)
        retval.push(<option key="za" value="za">South Africa</option>)
        retval.push(<option key="kr" value="kr">South Korea</option>)
        retval.push(<option key="es" value="es">Spain</option>)
        retval.push(<option key="lk" value="lk">Sri Lanka</option>)
        retval.push(<option key="se" value="se">Sweden</option>)
        retval.push(<option key="ch" value="ch">Switzerland</option>)
        retval.push(<option key="sy" value="sy">Syria</option>)
        retval.push(<option key="tw" value="tw">Taiwan</option>)
        retval.push(<option key="tz" value="tz">Tanzania</option>)
        retval.push(<option key="th" value="th">Thailand</option>)
        retval.push(<option key="tt" value="tt">Trinidad and Tobago</option>)
        retval.push(<option key="tn" value="tn">Tunisia</option>)
        retval.push(<option key="tr" value="tr">Turkey</option>)
        retval.push(<option key="ug" value="ug">Uganda</option>)
        retval.push(<option key="ua" value="ua">Ukraine</option>)
        retval.push(<option key="ae" value="ae">UAE</option>)
        retval.push(<option key="uy" value="uy">Uruguay</option>)
        retval.push(<option key="uz" value="uz">Uzbekistan</option>)
        retval.push(<option key="ve" value="ve">Venezuela</option>)
        retval.push(<option key="vn" value="vn">Vietnam</option>)
        retval.push(<option key="ye" value="ye">Yemen</option>)
        retval.push(<option key="zw" value="zw">Zimbabwe</option>)
        return retval;
    }

    function mapTimezone() {
        if (multipleEdit && !checked_timezone) return null;
        let retval = [];
        retval.push(<option key="Africa/Abidjan" value="Africa/Abidjan">Abidjan (Africa)</option>);
        retval.push(<option key="America/Adak" value="America/Adak">Adak (America)</option>);
        retval.push(<option key="Australia/Adelaide" value="Australia/Adelaide">Adelaide (Australia)</option>);
        retval.push(<option key="Africa/Algiers" value="Africa/Algiers">Algiers (Africa)</option>);
        retval.push(<option key="Asia/Almaty" value="Asia/Almaty">Almaty (Asia)</option>);
        retval.push(<option key="Asia/Amman" value="Asia/Amman">Amman (Asia)</option>);
        retval.push(<option key="Europe/Amsterdam" value="Europe/Amsterdam">Amsterdam (Europe)</option>);
        retval.push(<option key="Asia/Anadyr" value="Asia/Anadyr">Anadyr (Asia)</option>);
        retval.push(<option key="America/Anchorage" value="America/Anchorage">Anchorage (America)</option>);
        retval.push(<option key="Europe/Andorra" value="Europe/Andorra">Andorra (Europe)</option>);
        retval.push(<option key="Pacific/Apia" value="Pacific/Apia">Apia (Pacific)</option>);
        retval.push(<option key="Asia/Aqtau" value="Asia/Aqtau">Aqtau (Asia)</option>);
        retval.push(<option key="Asia/Aqtobe" value="Asia/Aqtobe">Aqtobe (Asia)</option>);
        retval.push(<option key="America/Araguaina" value="America/Araguaina">Araguaina (America)</option>);
        retval.push(<option key="Asia/Ashgabat" value="Asia/Ashgabat">Ashgabat (Asia)</option>);
        retval.push(<option key="Europe/Astrakhan" value="Europe/Astrakhan">Astrakhan (Europe)</option>);
        retval.push(<option key="America/Asuncion" value="America/Asuncion">Asuncion (America)</option>);
        retval.push(<option key="Europe/Athens" value="Europe/Athens">Athens (Europe)</option>);
        retval.push(<option key="Asia/Atyrau" value="Asia/Atyrau">Atyrau (Asia)</option>);
        retval.push(<option key="Pacific/Auckland" value="Pacific/Auckland">Auckland (Pacific)</option>);
        retval.push(<option key="Atlantic/Azores" value="Atlantic/Azores">Azores (Atlantic)</option>);
        retval.push(<option key="Asia/Baghdad" value="Asia/Baghdad">Baghdad (Asia)</option>);
        retval.push(<option key="America/Bahia" value="America/Bahia">Bahia (America)</option>);
        retval.push(<option key="America/Bahia_Banderas" value="America/Bahia_Banderas">Bahia Banderas (America)</option>);
        retval.push(<option key="Asia/Baku" value="Asia/Baku">Baku (Asia)</option>);
        retval.push(<option key="Asia/Bangkok" value="Asia/Bangkok">Bangkok (Asia)</option>);
        retval.push(<option key="America/Barbados" value="America/Barbados">Barbados (America)</option>);
        retval.push(<option key="Asia/Barnaul" value="Asia/Barnaul">Barnaul (Asia)</option>);
        retval.push(<option key="Asia/Beirut" value="Asia/Beirut">Beirut (Asia)</option>);
        retval.push(<option key="America/Belem" value="America/Belem">Belem (America)</option>);
        retval.push(<option key="Europe/Belgrade" value="Europe/Belgrade">Belgrade (Europe)</option>);
        retval.push(<option key="America/Belize" value="America/Belize">Belize (America)</option>);
        retval.push(<option key="Europe/Berlin" value="Europe/Berlin">Berlin (Europe)</option>);
        retval.push(<option key="Atlantic/Bermuda" value="Atlantic/Bermuda">Bermuda (Atlantic)</option>);
        retval.push(<option key="America/North_Dakota/Beulah" value="America/North_Dakota/Beulah">Beulah (America)</option>);
        retval.push(<option key="Asia/Bishkek" value="Asia/Bishkek">Bishkek (Asia)</option>);
        retval.push(<option key="Africa/Bissau" value="Africa/Bissau">Bissau (Africa)</option>);
        retval.push(<option key="America/Boa_Vista" value="America/Boa_Vista">Boa Vista (America)</option>);
        retval.push(<option key="America/Bogota" value="America/Bogota">Bogota (America)</option>);
        retval.push(<option key="America/Boise" value="America/Boise">Boise (America)</option>);
        retval.push(<option key="Pacific/Bougainville" value="Pacific/Bougainville">Bougainville (Pacific)</option>);
        retval.push(<option key="Australia/Brisbane" value="Australia/Brisbane">Brisbane (Australia)</option>);
        retval.push(<option key="Australia/Broken_Hill" value="Australia/Broken_Hill">Broken Hill (Australia)</option>);
        retval.push(<option key="Asia/Brunei" value="Asia/Brunei">Brunei (Asia)</option>);
        retval.push(<option key="Europe/Brussels" value="Europe/Brussels">Brussels (Europe)</option>);
        retval.push(<option key="Europe/Bucharest" value="Europe/Bucharest">Bucharest (Europe)</option>);
        retval.push(<option key="Europe/Budapest" value="Europe/Budapest">Budapest (Europe)</option>);
        retval.push(<option key="America/Argentina/Buenos_Aires" value="America/Argentina/Buenos_Aires">Buenos Aires (America)</option>);
        retval.push(<option key="Africa/Cairo" value="Africa/Cairo">Cairo (Africa)</option>);
        retval.push(<option key="America/Cambridge_Bay" value="America/Cambridge_Bay">Cambridge Bay (America)</option>);
        retval.push(<option key="America/Campo_Grande" value="America/Campo_Grande">Campo Grande (America)</option>);
        retval.push(<option key="Atlantic/Canary" value="Atlantic/Canary">Canary (Atlantic)</option>);
        retval.push(<option key="America/Cancun" value="America/Cancun">Cancun (America)</option>);
        retval.push(<option key="Atlantic/Cape_Verde" value="Atlantic/Cape_Verde">Cape Verde (Atlantic)</option>);
        retval.push(<option key="America/Caracas" value="America/Caracas">Caracas (America)</option>);
        retval.push(<option key="Africa/Casablanca" value="Africa/Casablanca">Casablanca (Africa)</option>);
        retval.push(<option key="Antarctica/Casey" value="Antarctica/Casey">Casey (Antarctica)</option>);
        retval.push(<option key="America/Argentina/Catamarca" value="America/Argentina/Catamarca">Catamarca (America)</option>);
        retval.push(<option key="America/Cayenne" value="America/Cayenne">Cayenne (America)</option>);
        retval.push(<option key="America/North_Dakota/Center" value="America/North_Dakota/Center">Center (America)</option>);
        retval.push(<option key="Africa/Ceuta" value="Africa/Ceuta">Ceuta (Africa)</option>);
        retval.push(<option key="Indian/Chagos" value="Indian/Chagos">Chagos (Indian)</option>);
        retval.push(<option key="Pacific/Chatham" value="Pacific/Chatham">Chatham (Pacific)</option>);
        retval.push(<option key="America/Chicago" value="America/Chicago">Chicago (America)</option>);
        retval.push(<option key="America/Chihuahua" value="America/Chihuahua">Chihuahua (America)</option>);
        retval.push(<option key="Europe/Chisinau" value="Europe/Chisinau">Chisinau (Europe)</option>);
        retval.push(<option key="Asia/Chita" value="Asia/Chita">Chita (Asia)</option>);
        retval.push(<option key="Asia/Choibalsan" value="Asia/Choibalsan">Choibalsan (Asia)</option>);
        retval.push(<option key="Indian/Christmas" value="Indian/Christmas">Christmas (Indian)</option>);
        retval.push(<option key="Pacific/Chuuk" value="Pacific/Chuuk">Chuuk (Pacific)</option>);
        retval.push(<option key="Indian/Cocos" value="Indian/Cocos">Cocos (Indian)</option>);
        retval.push(<option key="Asia/Colombo" value="Asia/Colombo">Colombo (Asia)</option>);
        retval.push(<option key="Europe/Copenhagen" value="Europe/Copenhagen">Copenhagen (Europe)</option>);
        retval.push(<option key="America/Argentina/Cordoba" value="America/Argentina/Cordoba">Cordoba (America)</option>);
        retval.push(<option key="America/Costa_Rica" value="America/Costa_Rica">Costa Rica (America)</option>);
        retval.push(<option key="America/Cuiaba" value="America/Cuiaba">Cuiaba (America)</option>);
        retval.push(<option key="Asia/Damascus" value="Asia/Damascus">Damascus (Asia)</option>);
        retval.push(<option key="America/Danmarkshavn" value="America/Danmarkshavn">Danmarkshavn (America)</option>);
        retval.push(<option key="Australia/Darwin" value="Australia/Darwin">Darwin (Australia)</option>);
        retval.push(<option key="Antarctica/Davis" value="Antarctica/Davis">Davis (Antarctica)</option>);
        retval.push(<option key="America/Dawson" value="America/Dawson">Dawson (America)</option>);
        retval.push(<option key="America/Dawson_Creek" value="America/Dawson_Creek">Dawson Creek (America)</option>);
        retval.push(<option key="America/Denver" value="America/Denver">Denver (America)</option>);
        retval.push(<option key="America/Detroit" value="America/Detroit">Detroit (America)</option>);
        retval.push(<option key="Asia/Dhaka" value="Asia/Dhaka">Dhaka (Asia)</option>);
        retval.push(<option key="Asia/Dili" value="Asia/Dili">Dili (Asia)</option>);
        retval.push(<option key="Asia/Dubai" value="Asia/Dubai">Dubai (Asia)</option>);
        retval.push(<option key="Europe/Dublin" value="Europe/Dublin">Dublin (Europe)</option>);
        retval.push(<option key="Asia/Dushanbe" value="Asia/Dushanbe">Dushanbe (Asia)</option>);
        retval.push(<option key="Pacific/Easter" value="Pacific/Easter">Easter (Pacific)</option>);
        retval.push(<option key="America/Edmonton" value="America/Edmonton">Edmonton (America)</option>);
        retval.push(<option key="Pacific/Efate" value="Pacific/Efate">Efate (Pacific)</option>);
        retval.push(<option key="America/Eirunepe" value="America/Eirunepe">Eirunepe (America)</option>);
        retval.push(<option key="Africa/El_Aaiun" value="Africa/El_Aaiun">El Aaiun (Africa)</option>);
        retval.push(<option key="America/El_Salvador" value="America/El_Salvador">El Salvador (America)</option>);
        retval.push(<option key="Australia/Eucla" value="Australia/Eucla">Eucla (Australia)</option>);
        retval.push(<option key="Pacific/Fakaofo" value="Pacific/Fakaofo">Fakaofo (Pacific)</option>);
        retval.push(<option key="Asia/Famagusta" value="Asia/Famagusta">Famagusta (Asia)</option>);
        retval.push(<option key="Atlantic/Faroe" value="Atlantic/Faroe">Faroe (Atlantic)</option>);
        retval.push(<option key="Pacific/Fiji" value="Pacific/Fiji">Fiji (Pacific)</option>);
        retval.push(<option key="America/Fort_Nelson" value="America/Fort_Nelson">Fort Nelson (America)</option>);
        retval.push(<option key="America/Fortaleza" value="America/Fortaleza">Fortaleza (America)</option>);
        retval.push(<option key="Pacific/Funafuti" value="Pacific/Funafuti">Funafuti (Pacific)</option>);
        retval.push(<option key="Pacific/Galapagos" value="Pacific/Galapagos">Galapagos (Pacific)</option>);
        retval.push(<option key="Pacific/Gambier" value="Pacific/Gambier">Gambier (Pacific)</option>);
        retval.push(<option key="Asia/Gaza" value="Asia/Gaza">Gaza (Asia)</option>);
        retval.push(<option key="Europe/Gibraltar" value="Europe/Gibraltar">Gibraltar (Europe)</option>);
        retval.push(<option key="America/Glace_Bay" value="America/Glace_Bay">Glace Bay (America)</option>);
        retval.push(<option key="America/Goose_Bay" value="America/Goose_Bay">Goose Bay (America)</option>);
        retval.push(<option key="America/Grand_Turk" value="America/Grand_Turk">Grand Turk (America)</option>);
        retval.push(<option key="Pacific/Guadalcanal" value="Pacific/Guadalcanal">Guadalcanal (Pacific)</option>);
        retval.push(<option key="Pacific/Guam" value="Pacific/Guam">Guam (Pacific)</option>);
        retval.push(<option key="America/Guatemala" value="America/Guatemala">Guatemala (America)</option>);
        retval.push(<option key="America/Guayaquil" value="America/Guayaquil">Guayaquil (America)</option>);
        retval.push(<option key="America/Guyana" value="America/Guyana">Guyana (America)</option>);
        retval.push(<option key="America/Halifax" value="America/Halifax">Halifax (America)</option>);
        retval.push(<option key="America/Havana" value="America/Havana">Havana (America)</option>);
        retval.push(<option key="Asia/Hebron" value="Asia/Hebron">Hebron (Asia)</option>);
        retval.push(<option key="Europe/Helsinki" value="Europe/Helsinki">Helsinki (Europe)</option>);
        retval.push(<option key="America/Hermosillo" value="America/Hermosillo">Hermosillo (America)</option>);
        retval.push(<option key="Asia/Ho_Chi_Minh" value="Asia/Ho_Chi_Minh">Ho Chi Minh (Asia)</option>);
        retval.push(<option key="Australia/Hobart" value="Australia/Hobart">Hobart (Australia)</option>);
        retval.push(<option key="Asia/Hong_Kong" value="Asia/Hong_Kong">Hong Kong (Asia)</option>);
        retval.push(<option key="Pacific/Honolulu" value="Pacific/Honolulu">Honolulu (Pacific)</option>);
        retval.push(<option key="Asia/Hovd" value="Asia/Hovd">Hovd (Asia)</option>);
        retval.push(<option key="America/Indiana/Indianapolis" value="America/Indiana/Indianapolis">Indianapolis (America)</option>);
        retval.push(<option key="America/Inuvik" value="America/Inuvik">Inuvik (America)</option>);
        retval.push(<option key="America/Iqaluit" value="America/Iqaluit">Iqaluit (America)</option>);
        retval.push(<option key="Asia/Irkutsk" value="Asia/Irkutsk">Irkutsk (Asia)</option>);
        retval.push(<option key="Europe/Istanbul" value="Europe/Istanbul">Istanbul (Europe)</option>);
        retval.push(<option key="Asia/Jakarta" value="Asia/Jakarta">Jakarta (Asia)</option>);
        retval.push(<option key="America/Jamaica" value="America/Jamaica">Jamaica (America)</option>);
        retval.push(<option key="Asia/Jayapura" value="Asia/Jayapura">Jayapura (Asia)</option>);
        retval.push(<option key="Asia/Jerusalem" value="Asia/Jerusalem">Jerusalem (Asia)</option>);
        retval.push(<option key="Africa/Johannesburg" value="Africa/Johannesburg">Johannesburg (Africa)</option>);
        retval.push(<option key="Africa/Juba" value="Africa/Juba">Juba (Africa)</option>);
        retval.push(<option key="America/Argentina/Jujuy" value="America/Argentina/Jujuy">Jujuy (America)</option>);
        retval.push(<option key="America/Juneau" value="America/Juneau">Juneau (America)</option>);
        retval.push(<option key="Asia/Kabul" value="Asia/Kabul">Kabul (Asia)</option>);
        retval.push(<option key="Europe/Kaliningrad" value="Europe/Kaliningrad">Kaliningrad (Europe)</option>);
        retval.push(<option key="Asia/Kamchatka" value="Asia/Kamchatka">Kamchatka (Asia)</option>);
        retval.push(<option key="Pacific/Kanton" value="Pacific/Kanton">Kanton (Pacific)</option>);
        retval.push(<option key="Asia/Karachi" value="Asia/Karachi">Karachi (Asia)</option>);
        retval.push(<option key="Asia/Kathmandu" value="Asia/Kathmandu">Kathmandu (Asia)</option>);
        retval.push(<option key="Indian/Kerguelen" value="Indian/Kerguelen">Kerguelen (Indian)</option>);
        retval.push(<option key="Asia/Khandyga" value="Asia/Khandyga">Khandyga (Asia)</option>);
        retval.push(<option key="Africa/Khartoum" value="Africa/Khartoum">Khartoum (Africa)</option>);
        retval.push(<option key="Europe/Kiev" value="Europe/Kiev">Kiev (Europe)</option>);
        retval.push(<option key="Pacific/Kiritimati" value="Pacific/Kiritimati">Kiritimati (Pacific)</option>);
        retval.push(<option key="Europe/Kirov" value="Europe/Kirov">Kirov (Europe)</option>);
        retval.push(<option key="America/Indiana/Knox" value="America/Indiana/Knox">Knox (America)</option>);
        retval.push(<option key="Asia/Kolkata" value="Asia/Kolkata">Kolkata (Asia)</option>);
        retval.push(<option key="Pacific/Kosrae" value="Pacific/Kosrae">Kosrae (Pacific)</option>);
        retval.push(<option key="Asia/Krasnoyarsk" value="Asia/Krasnoyarsk">Krasnoyarsk (Asia)</option>);
        retval.push(<option key="Asia/Kuala_Lumpur" value="Asia/Kuala_Lumpur">Kuala Lumpur (Asia)</option>);
        retval.push(<option key="Asia/Kuching" value="Asia/Kuching">Kuching (Asia)</option>);
        retval.push(<option key="Pacific/Kwajalein" value="Pacific/Kwajalein">Kwajalein (Pacific)</option>);
        retval.push(<option key="America/La_Paz" value="America/La_Paz">La Paz (America)</option>);
        retval.push(<option key="America/Argentina/La_Rioja" value="America/Argentina/La_Rioja">La Rioja (America)</option>);
        retval.push(<option key="Africa/Lagos" value="Africa/Lagos">Lagos (Africa)</option>);
        retval.push(<option key="America/Lima" value="America/Lima">Lima (America)</option>);
        retval.push(<option key="Australia/Lindeman" value="Australia/Lindeman">Lindeman (Australia)</option>);
        retval.push(<option key="Europe/Lisbon" value="Europe/Lisbon">Lisbon (Europe)</option>);
        retval.push(<option key="Europe/London" value="Europe/London">London (Europe)</option>);
        retval.push(<option key="Australia/Lord_Howe" value="Australia/Lord_Howe">Lord Howe (Australia)</option>);
        retval.push(<option key="America/Los_Angeles" value="America/Los_Angeles">Los Angeles (America)</option>);
        retval.push(<option key="America/Kentucky/Louisville" value="America/Kentucky/Louisville">Louisville (America)</option>);
        retval.push(<option key="Europe/Luxembourg" value="Europe/Luxembourg">Luxembourg (Europe)</option>);
        retval.push(<option key="Asia/Macau" value="Asia/Macau">Macau (Asia)</option>);
        retval.push(<option key="America/Maceio" value="America/Maceio">Maceio (America)</option>);
        retval.push(<option key="Antarctica/Macquarie" value="Antarctica/Macquarie">Macquarie (Antarctica)</option>);
        retval.push(<option key="Atlantic/Madeira" value="Atlantic/Madeira">Madeira (Atlantic)</option>);
        retval.push(<option key="Europe/Madrid" value="Europe/Madrid">Madrid (Europe)</option>);
        retval.push(<option key="Asia/Magadan" value="Asia/Magadan">Magadan (Asia)</option>);
        retval.push(<option key="Indian/Mahe" value="Indian/Mahe">Mahe (Indian)</option>);
        retval.push(<option key="Pacific/Majuro" value="Pacific/Majuro">Majuro (Pacific)</option>);
        retval.push(<option key="Asia/Makassar" value="Asia/Makassar">Makassar (Asia)</option>);
        retval.push(<option key="Indian/Maldives" value="Indian/Maldives">Maldives (Indian)</option>);
        retval.push(<option key="Europe/Malta" value="Europe/Malta">Malta (Europe)</option>);
        retval.push(<option key="America/Managua" value="America/Managua">Managua (America)</option>);
        retval.push(<option key="America/Manaus" value="America/Manaus">Manaus (America)</option>);
        retval.push(<option key="Asia/Manila" value="Asia/Manila">Manila (Asia)</option>);
        retval.push(<option key="Africa/Maputo" value="Africa/Maputo">Maputo (Africa)</option>);
        retval.push(<option key="America/Indiana/Marengo" value="America/Indiana/Marengo">Marengo (America)</option>);
        retval.push(<option key="Pacific/Marquesas" value="Pacific/Marquesas">Marquesas (Pacific)</option>);
        retval.push(<option key="America/Martinique" value="America/Martinique">Martinique (America)</option>);
        retval.push(<option key="America/Matamoros" value="America/Matamoros">Matamoros (America)</option>);
        retval.push(<option key="Indian/Mauritius" value="Indian/Mauritius">Mauritius (Indian)</option>);
        retval.push(<option key="Antarctica/Mawson" value="Antarctica/Mawson">Mawson (Antarctica)</option>);
        retval.push(<option key="America/Mazatlan" value="America/Mazatlan">Mazatlan (America)</option>);
        retval.push(<option key="Australia/Melbourne" value="Australia/Melbourne">Melbourne (Australia)</option>);
        retval.push(<option key="America/Argentina/Mendoza" value="America/Argentina/Mendoza">Mendoza (America)</option>);
        retval.push(<option key="America/Menominee" value="America/Menominee">Menominee (America)</option>);
        retval.push(<option key="America/Merida" value="America/Merida">Merida (America)</option>);
        retval.push(<option key="America/Metlakatla" value="America/Metlakatla">Metlakatla (America)</option>);
        retval.push(<option key="America/Mexico_City" value="America/Mexico_City">Mexico City (America)</option>);
        retval.push(<option key="Europe/Minsk" value="Europe/Minsk">Minsk (Europe)</option>);
        retval.push(<option key="America/Miquelon" value="America/Miquelon">Miquelon (America)</option>);
        retval.push(<option key="Europe/Monaco" value="Europe/Monaco">Monaco (Europe)</option>);
        retval.push(<option key="America/Moncton" value="America/Moncton">Moncton (America)</option>);
        retval.push(<option key="Africa/Monrovia" value="Africa/Monrovia">Monrovia (Africa)</option>);
        retval.push(<option key="America/Monterrey" value="America/Monterrey">Monterrey (America)</option>);
        retval.push(<option key="America/Montevideo" value="America/Montevideo">Montevideo (America)</option>);
        retval.push(<option key="America/Kentucky/Monticello" value="America/Kentucky/Monticello">Monticello (America)</option>);
        retval.push(<option key="Europe/Moscow" value="Europe/Moscow">Moscow (Europe)</option>);
        retval.push(<option key="Africa/Nairobi" value="Africa/Nairobi">Nairobi (Africa)</option>);
        retval.push(<option key="Pacific/Nauru" value="Pacific/Nauru">Nauru (Pacific)</option>);
        retval.push(<option key="Africa/Ndjamena" value="Africa/Ndjamena">Ndjamena (Africa)</option>);
        retval.push(<option key="America/North_Dakota/New_Salem" value="America/North_Dakota/New_Salem">New Salem (America)</option>);
        retval.push(<option key="America/New_York" value="America/New_York">New York (America)</option>);
        retval.push(<option key="Asia/Nicosia" value="Asia/Nicosia">Nicosia (Asia)</option>);
        retval.push(<option key="America/Nipigon" value="America/Nipigon">Nipigon (America)</option>);
        retval.push(<option key="Pacific/Niue" value="Pacific/Niue">Niue (Pacific)</option>);
        retval.push(<option key="America/Nome" value="America/Nome">Nome (America)</option>);
        retval.push(<option key="Pacific/Norfolk" value="Pacific/Norfolk">Norfolk (Pacific)</option>);
        retval.push(<option key="America/Noronha" value="America/Noronha">Noronha (America)</option>);
        retval.push(<option key="Pacific/Noumea" value="Pacific/Noumea">Noumea (Pacific)</option>);
        retval.push(<option key="Asia/Novokuznetsk" value="Asia/Novokuznetsk">Novokuznetsk (Asia)</option>);
        retval.push(<option key="Asia/Novosibirsk" value="Asia/Novosibirsk">Novosibirsk (Asia)</option>);
        retval.push(<option key="America/Nuuk" value="America/Nuuk">Nuuk (America)</option>);
        retval.push(<option key="America/Ojinaga" value="America/Ojinaga">Ojinaga (America)</option>);
        retval.push(<option key="Asia/Omsk" value="Asia/Omsk">Omsk (Asia)</option>);
        retval.push(<option key="Asia/Oral" value="Asia/Oral">Oral (Asia)</option>);
        retval.push(<option key="Europe/Oslo" value="Europe/Oslo">Oslo (Europe)</option>);
        retval.push(<option key="Pacific/Pago_Pago" value="Pacific/Pago_Pago">Pago Pago (Pacific)</option>);
        retval.push(<option key="Pacific/Palau" value="Pacific/Palau">Palau (Pacific)</option>);
        retval.push(<option key="Antarctica/Palmer" value="Antarctica/Palmer">Palmer (Antarctica)</option>);
        retval.push(<option key="America/Panama" value="America/Panama">Panama (America)</option>);
        retval.push(<option key="America/Pangnirtung" value="America/Pangnirtung">Pangnirtung (America)</option>);
        retval.push(<option key="America/Paramaribo" value="America/Paramaribo">Paramaribo (America)</option>);
        retval.push(<option key="Europe/Paris" value="Europe/Paris">Paris (Europe)</option>);
        retval.push(<option key="Australia/Perth" value="Australia/Perth">Perth (Australia)</option>);
        retval.push(<option key="America/Indiana/Petersburg" value="America/Indiana/Petersburg">Petersburg (America)</option>);
        retval.push(<option key="America/Phoenix" value="America/Phoenix">Phoenix (America)</option>);
        retval.push(<option key="Pacific/Pitcairn" value="Pacific/Pitcairn">Pitcairn (Pacific)</option>);
        retval.push(<option key="Pacific/Pohnpei" value="Pacific/Pohnpei">Pohnpei (Pacific)</option>);
        retval.push(<option key="Asia/Pontianak" value="Asia/Pontianak">Pontianak (Asia)</option>);
        retval.push(<option key="America/Port-au-Prince" value="America/Port-au-Prince">Port-au-Prince (America)</option>);
        retval.push(<option key="Pacific/Port_Moresby" value="Pacific/Port_Moresby">Port Moresby (Pacific)</option>);
        retval.push(<option key="America/Porto_Velho" value="America/Porto_Velho">Porto Velho (America)</option>);
        retval.push(<option key="Europe/Prague" value="Europe/Prague">Prague (Europe)</option>);
        retval.push(<option key="America/Puerto_Rico" value="America/Puerto_Rico">Puerto Rico (America)</option>);
        retval.push(<option key="America/Punta_Arenas" value="America/Punta_Arenas">Punta Arenas (America)</option>);
        retval.push(<option key="Asia/Pyongyang" value="Asia/Pyongyang">Pyongyang (Asia)</option>);
        retval.push(<option key="Asia/Qatar" value="Asia/Qatar">Qatar (Asia)</option>);
        retval.push(<option key="Asia/Qostanay" value="Asia/Qostanay">Qostanay (Asia)</option>);
        retval.push(<option key="Asia/Qyzylorda" value="Asia/Qyzylorda">Qyzylorda (Asia)</option>);
        retval.push(<option key="America/Rainy_River" value="America/Rainy_River">Rainy River (America)</option>);
        retval.push(<option key="America/Rankin_Inlet" value="America/Rankin_Inlet">Rankin Inlet (America)</option>);
        retval.push(<option key="Pacific/Rarotonga" value="Pacific/Rarotonga">Rarotonga (Pacific)</option>);
        retval.push(<option key="America/Recife" value="America/Recife">Recife (America)</option>);
        retval.push(<option key="America/Regina" value="America/Regina">Regina (America)</option>);
        retval.push(<option key="America/Resolute" value="America/Resolute">Resolute (America)</option>);
        retval.push(<option key="Indian/Reunion" value="Indian/Reunion">Reunion (Indian)</option>);
        retval.push(<option key="Atlantic/Reykjavik" value="Atlantic/Reykjavik">Reykjavik (Atlantic)</option>);
        retval.push(<option key="Europe/Riga" value="Europe/Riga">Riga (Europe)</option>);
        retval.push(<option key="America/Rio_Branco" value="America/Rio_Branco">Rio Branco (America)</option>);
        retval.push(<option key="America/Argentina/Rio_Gallegos" value="America/Argentina/Rio_Gallegos">Rio Gallegos (America)</option>);
        retval.push(<option key="Asia/Riyadh" value="Asia/Riyadh">Riyadh (Asia)</option>);
        retval.push(<option key="Europe/Rome" value="Europe/Rome">Rome (Europe)</option>);
        retval.push(<option key="Antarctica/Rothera" value="Antarctica/Rothera">Rothera (Antarctica)</option>);
        retval.push(<option key="Asia/Sakhalin" value="Asia/Sakhalin">Sakhalin (Asia)</option>);
        retval.push(<option key="America/Argentina/Salta" value="America/Argentina/Salta">Salta (America)</option>);
        retval.push(<option key="Europe/Samara" value="Europe/Samara">Samara (Europe)</option>);
        retval.push(<option key="Asia/Samarkand" value="Asia/Samarkand">Samarkand (Asia)</option>);
        retval.push(<option key="America/Argentina/San_Juan" value="America/Argentina/San_Juan">San Juan (America)</option>);
        retval.push(<option key="America/Argentina/San_Luis" value="America/Argentina/San_Luis">San Luis (America)</option>);
        retval.push(<option key="America/Santarem" value="America/Santarem">Santarem (America)</option>);
        retval.push(<option key="America/Santiago" value="America/Santiago">Santiago (America)</option>);
        retval.push(<option key="America/Santo_Domingo" value="America/Santo_Domingo">Santo Domingo (America)</option>);
        retval.push(<option key="America/Sao_Paulo" value="America/Sao_Paulo">Sao Paulo (America)</option>);
        retval.push(<option key="Africa/Sao_Tome" value="Africa/Sao_Tome">Sao Tome (Africa)</option>);
        retval.push(<option key="Europe/Saratov" value="Europe/Saratov">Saratov (Europe)</option>);
        retval.push(<option key="America/Scoresbysund" value="America/Scoresbysund">Scoresbysund (America)</option>);
        retval.push(<option key="Asia/Seoul" value="Asia/Seoul">Seoul (Asia)</option>);
        retval.push(<option key="Asia/Shanghai" value="Asia/Shanghai">Shanghai (Asia)</option>);
        retval.push(<option key="Europe/Simferopol" value="Europe/Simferopol">Simferopol (Europe)</option>);
        retval.push(<option key="Asia/Singapore" value="Asia/Singapore">Singapore (Asia)</option>);
        retval.push(<option key="America/Sitka" value="America/Sitka">Sitka (America)</option>);
        retval.push(<option key="Europe/Sofia" value="Europe/Sofia">Sofia (Europe)</option>);
        retval.push(<option key="Atlantic/South_Georgia" value="Atlantic/South_Georgia">South Georgia (Atlantic)</option>);
        retval.push(<option key="Asia/Srednekolymsk" value="Asia/Srednekolymsk">Srednekolymsk (Asia)</option>);
        retval.push(<option key="America/St_Johns" value="America/St_Johns">St Johns (America)</option>);
        retval.push(<option key="Atlantic/Stanley" value="Atlantic/Stanley">Stanley (Atlantic)</option>);
        retval.push(<option key="Europe/Stockholm" value="Europe/Stockholm">Stockholm (Europe)</option>);
        retval.push(<option key="America/Swift_Current" value="America/Swift_Current">Swift Current (America)</option>);
        retval.push(<option key="Australia/Sydney" value="Australia/Sydney">Sydney (Australia)</option>);
        retval.push(<option key="Pacific/Tahiti" value="Pacific/Tahiti">Tahiti (Pacific)</option>);
        retval.push(<option key="Asia/Taipei" value="Asia/Taipei">Taipei (Asia)</option>);
        retval.push(<option key="Europe/Tallinn" value="Europe/Tallinn">Tallinn (Europe)</option>);
        retval.push(<option key="Pacific/Tarawa" value="Pacific/Tarawa">Tarawa (Pacific)</option>);
        retval.push(<option key="Asia/Tashkent" value="Asia/Tashkent">Tashkent (Asia)</option>);
        retval.push(<option key="Asia/Tbilisi" value="Asia/Tbilisi">Tbilisi (Asia)</option>);
        retval.push(<option key="America/Tegucigalpa" value="America/Tegucigalpa">Tegucigalpa (America)</option>);
        retval.push(<option key="Asia/Tehran" value="Asia/Tehran">Tehran (Asia)</option>);
        retval.push(<option key="America/Indiana/Tell_City" value="America/Indiana/Tell_City">Tell City (America)</option>);
        retval.push(<option key="Asia/Thimphu" value="Asia/Thimphu">Thimphu (Asia)</option>);
        retval.push(<option key="America/Thule" value="America/Thule">Thule (America)</option>);
        retval.push(<option key="America/Thunder_Bay" value="America/Thunder_Bay">Thunder Bay (America)</option>);
        retval.push(<option key="America/Tijuana" value="America/Tijuana">Tijuana (America)</option>);
        retval.push(<option key="Europe/Tirane" value="Europe/Tirane">Tirane (Europe)</option>);
        retval.push(<option key="Asia/Tokyo" value="Asia/Tokyo">Tokyo (Asia)</option>);
        retval.push(<option key="Asia/Tomsk" value="Asia/Tomsk">Tomsk (Asia)</option>);
        retval.push(<option key="Pacific/Tongatapu" value="Pacific/Tongatapu">Tongatapu (Pacific)</option>);
        retval.push(<option key="America/Toronto" value="America/Toronto">Toronto (America)</option>);
        retval.push(<option key="Africa/Tripoli" value="Africa/Tripoli">Tripoli (Africa)</option>);
        retval.push(<option key="Antarctica/Troll" value="Antarctica/Troll">Troll (Antarctica)</option>);
        retval.push(<option key="America/Argentina/Tucuman" value="America/Argentina/Tucuman">Tucuman (America)</option>);
        retval.push(<option key="Africa/Tunis" value="Africa/Tunis">Tunis (Africa)</option>);
        retval.push(<option key="UTC" value="UTC">UTC</option>);
        retval.push(<option key="Asia/Ulaanbaatar" value="Asia/Ulaanbaatar">Ulaanbaatar (Asia)</option>);
        retval.push(<option key="Europe/Ulyanovsk" value="Europe/Ulyanovsk">Ulyanovsk (Europe)</option>);
        retval.push(<option key="Asia/Urumqi" value="Asia/Urumqi">Urumqi (Asia)</option>);
        retval.push(<option key="America/Argentina/Ushuaia" value="America/Argentina/Ushuaia">Ushuaia (America)</option>);
        retval.push(<option key="Asia/Ust-Nera" value="Asia/Ust-Nera">Ust-Nera (Asia)</option>);
        retval.push(<option key="Europe/Uzhgorod" value="Europe/Uzhgorod">Uzhgorod (Europe)</option>);
        retval.push(<option key="America/Vancouver" value="America/Vancouver">Vancouver (America)</option>);
        retval.push(<option key="America/Indiana/Vevay" value="America/Indiana/Vevay">Vevay (America)</option>);
        retval.push(<option key="Europe/Vienna" value="Europe/Vienna">Vienna (Europe)</option>);
        retval.push(<option key="Europe/Vilnius" value="Europe/Vilnius">Vilnius (Europe)</option>);
        retval.push(<option key="America/Indiana/Vincennes" value="America/Indiana/Vincennes">Vincennes (America)</option>);
        retval.push(<option key="Asia/Vladivostok" value="Asia/Vladivostok">Vladivostok (Asia)</option>);
        retval.push(<option key="Europe/Volgograd" value="Europe/Volgograd">Volgograd (Europe)</option>);
        retval.push(<option key="Antarctica/Vostok" value="Antarctica/Vostok">Vostok (Antarctica)</option>);
        retval.push(<option key="Pacific/Wake" value="Pacific/Wake">Wake (Pacific)</option>);
        retval.push(<option key="Pacific/Wallis" value="Pacific/Wallis">Wallis (Pacific)</option>);
        retval.push(<option key="Europe/Warsaw" value="Europe/Warsaw">Warsaw (Europe)</option>);
        retval.push(<option key="America/Whitehorse" value="America/Whitehorse">Whitehorse (America)</option>);
        retval.push(<option key="America/Indiana/Winamac" value="America/Indiana/Winamac">Winamac (America)</option>);
        retval.push(<option key="Africa/Windhoek" value="Africa/Windhoek">Windhoek (Africa)</option>);
        retval.push(<option key="America/Winnipeg" value="America/Winnipeg">Winnipeg (America)</option>);
        retval.push(<option key="America/Yakutat" value="America/Yakutat">Yakutat (America)</option>);
        retval.push(<option key="Asia/Yakutsk" value="Asia/Yakutsk">Yakutsk (Asia)</option>);
        retval.push(<option key="Asia/Yangon" value="Asia/Yangon">Yangon (Asia)</option>);
        retval.push(<option key="Asia/Yekaterinburg" value="Asia/Yekaterinburg">Yekaterinburg (Asia)</option>);
        retval.push(<option key="America/Yellowknife" value="America/Yellowknife">Yellowknife (America)</option>);
        retval.push(<option key="Asia/Yerevan" value="Asia/Yerevan">Yerevan (Asia)</option>);
        retval.push(<option key="Europe/Zaporozhye" value="Europe/Zaporozhye">Zaporozhye (Europe)</option>);
        retval.push(<option key="Europe/Zurich" value="Europe/Zurich">Zurich (Europe)</option>);
        return retval;
    }

    function dropdownChangeTimezone(e) {
        set_timezone(e.target.value);
    }

    return (
		<Form>
			<Form.Label className='tabLabel'>Localization</Form.Label>

            {/*Country*/}
            <Form.Group>
                <Form.Row>
                    <Col xs='11'>
                        <Form.Label>Country</Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Control as='select' onChange={dropdownChangeWiFiCountry} value={wifiCountry}>
                            {mapCountry()}
                        </Form.Control>
                    </Col>
                    <Col xs='2'>
                        <PlusX name='plusX_country' checked={checked_country} setChecked={setChecked_country} multipleEdit={multipleEdit} className='plusX_country' />
                    </Col>
                </Form.Row>
            </Form.Group>

            {/*Language*/}
            <Form.Group>
                <Form.Row>
                    <Col xs='11'>
                        <Form.Label>Language</Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Control as='select' onChange={dropdownChangeLanguage} value={language}>
                            {mapLanguage()}
                        </Form.Control>
                    </Col>
                    <Col xs='2'>
                        <PlusX name='plusX_language' checked={checked_language} setChecked={setChecked_language} multipleEdit={multipleEdit}
                            className='plusX_language' />
                    </Col>
                </Form.Row>
            </Form.Group>

            {/*Clock*/}
            <Form.Group>
                <Form.Row>
                    <Col xs='11'>
                        <Form.Label>Clock</Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Control as='select' onChange={dropdownChangeClock} value={clock}>
                            {mapClock()}
                        </Form.Control>
                    </Col>
                    <Col xs='2'>
                        <PlusX name='plusX_clock' checked={checked_clock} setChecked={setChecked_clock} multipleEdit={multipleEdit}
                            className='plusX_clock' />
                    </Col>
                </Form.Row>
            </Form.Group>

            {/*Timezone*/}
            <Form.Group>
                <Form.Row>
                    <Col xs='11'>
                        <Form.Label>Timezone</Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Control as='select' onChange={dropdownChangeTimezone} value={timezone}>
                            {mapTimezone()}
                        </Form.Control>
                    </Col>
                    <Col xs='2'>
                        <PlusX name='plusX_timezone' checked={checked_timezone} setChecked={setChecked_timezone} multipleEdit={multipleEdit}
                            className='plusX_timezone' />
                    </Col>
                </Form.Row>
            </Form.Group>
		</Form>
	);
}
