import React, { useState, Fragment } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import PlusX from '../PlusX';
import Switch from '../Switch';
import ConfirmationDialog from '../ConfirmationDialog';
import InfoRow from './subComponents/InfoRow';

export default function AboutTab({ deviceInfo, deviceConfig, automaticUpdateEnabled, set_automaticUpdateEnabled, multipleEdit, checked_automaticUpdateEnabled, setChecked_automaticUpdateEnabled,
    installUpdatesConfirmationResult, version, latestDeviceVersion, connectedState}) {
    const [showConfirmationCheckAndInstallUpdates, set_showConfirmationCheckAndInstallUpdates] = useState(false);

    function showCheckAndInstallUpdates() {
        set_showConfirmationCheckAndInstallUpdates(true);
    }

    function handleInstallUpdatesConfirmationResult(result) {
        set_showConfirmationCheckAndInstallUpdates(false);
        installUpdatesConfirmationResult(result);
    }

    function updateAvailable() {
        return (new Date(version) < new Date(latestDeviceVersion));
    }

    function getUpToDateText() {
        if (updateAvailable()) {
            return `Update Available: ${latestDeviceVersion}`;
        } else {
            return 'This device is up to date';
        }
    }
    function getStatusTip() {
        if (!deviceConfig["$metadata"]) return "";
        return " since " + new Date(deviceConfig["$metadata"]["$lastUpdated"]).toLocaleString();
    }
    return (
        <Form className='aboutForm'>
            <Form.Label className='tabLabel'>About</Form.Label>
            <Form.Group>
                <Form.Row className="tabInfoSection">
                    <Col xs='12'>
                        <InfoRow label='Cloud Organization' value={deviceConfig && deviceConfig["v2"] && deviceConfig["v2"]["device"] && deviceConfig["v2"]["device"]["cloudOrganization"] ? deviceConfig["v2"]["device"]["cloudOrganization"] : ''} />
            {
                multipleEdit ? null : (
                    <Fragment>
                        <InfoRow label='Serial Number' value={deviceInfo.deviceSerial || ''} />
                        <InfoRow label='Status' value={(connectedState === 'Disconnected' ? 'Offline' : 'Online') + getStatusTip()} />
                        <InfoRow label='Version' value={deviceInfo.version || ''} valueLink={deviceInfo.version ? 'https://support.airserver.com/support/solutions/articles/43000512412' : ''} />
                    </Fragment>
                )
            }
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col className='updatesHeader col-auto col-form-label-lg'><Form.Label>Updates</Form.Label></Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {/*Automatic updates*/}
                        <Form.Row>
                            <Col xs='6' className='automaticUpdateEnabledCol'>
                                <Switch isOn={automaticUpdateEnabled} handleToggle={() => { set_automaticUpdateEnabled(!automaticUpdateEnabled) }} labelText='Automatic Updates' visible={!(multipleEdit && !checked_automaticUpdateEnabled)} />
                            </Col>
                            <Col xs='6'>
                                <PlusX name='plusX_automaticUpdateEnabled' checked={checked_automaticUpdateEnabled} setChecked={setChecked_automaticUpdateEnabled} multipleEdit={multipleEdit} className='plusX_automaticUpdateEnabled' />
                            </Col>
                        </Form.Row>
                        {/*Release notes*/}
                        {
                            multipleEdit ? null : (
                                <Form.Row>
                                    <Col xs='12'>
                                    {
                                        latestDeviceVersion ? (
                                            <p>
                                                {
                                                    getUpToDateText()
                                                }
                                            </p>
                                        ) : null
                                    }
                                    </Col>
                                </Form.Row>
                            )
                        }

                        {
                            automaticUpdateEnabled ? null :
                                <p>
                                    Automatic updates provide new features and security
                                    enhancements required to protect this device and others
                                                        on the same network from potential threats. We <strong>strongly</strong> recommend
                                    that you always have automatic updates enabled.
                                </p>
                        }

                        {/*Updates button and confirmation dialog*/}
                        {
                            multipleEdit ? null : (
                                <Form.Row>
                                    <Col xs='6'>
                                    <Button className='toolsButton updatesButton' variant='outline-success' onClick={showCheckAndInstallUpdates} >
                                        CHECK AND INSTALL UPDATES
                                    </Button>
                                    <ConfirmationDialog show={showConfirmationCheckAndInstallUpdates} text={'Are you sure you want to check for and install updates?'}
                                        result={handleInstallUpdatesConfirmationResult} />
                                    </Col>
                                </Form.Row>
                            )
                        }

                    </Col>
                </Form.Row>
            </Form.Group>
        </Form>
    );
}
