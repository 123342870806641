import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

export default function Layout({ children, editMultipleDevices, createGroup, addDevice, detailsPaneVisible, numSelectedDevices, loggedIn, noGroupsCreated, selectedPage,
	set_selectedPage, canManageUsers }) {
		
    return (
        <div id='pageContainer'>
            <NavMenu editMultipleDevices={editMultipleDevices} createGroup={createGroup} addDevice={addDevice} detailsPaneVisible={detailsPaneVisible} numSelectedDevices={numSelectedDevices}
                loggedIn={loggedIn} noGroupsCreated={noGroupsCreated} selectedPage={selectedPage} set_selectedPage={set_selectedPage} canManageUsers={canManageUsers} />
            <Container id='pageBody'>
                {children}
            </Container>
        </div>
    );
}
