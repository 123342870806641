import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import Switch from "../Switch";

export default function RoleAddEdit({ showModal, handleAddRole, handleEditRole, hideModal, iotGroups, type, roleForEditing }) {
	const [name, set_name] = useState('');
	const [canManageUsers, set_canManageUsers] = useState(false);
	const [managesAllGroups, set_managesAllGroups] = useState(true);
	const [myIotGroups, set_myIotGroups] = useState([]);

	const initState = useCallback(() => {

		// Copy from props to state:
		let tmpGroups = [...iotGroups];

		if (roleForEditing !== null) {
			// The component is set for EDIT USER.
			set_name(roleForEditing.name);
			set_canManageUsers(roleForEditing.canManageUsers);
			set_managesAllGroups(roleForEditing.managesAllGroups);

			// Select the iot groups that this role manages:
			for (const group of tmpGroups) {
				const groupIsManagedByRole = roleForEditing.deviceGroupsManaged.find(g => g === group.deviceId);
				if (groupIsManagedByRole) {
					group.selected = true;
				} else {
					group.selected = false;
				}
			}
		} else {
			// The component is set for ADD USER.
			set_name('');
			set_canManageUsers(false);
			set_managesAllGroups(true);

			// Select all groups:
			for (const group of tmpGroups) {
				if (group.selected != null) {
					group.selected = true;
				}
			}
		}

		set_myIotGroups(tmpGroups);
		// Disabling eslint in the line below removes a warning. The compiler wants to add 'myIotGroups' in the depency list
		// but an infinite loop occurs if I do it. Investige later if needed.
	}, [iotGroups, roleForEditing]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		initState();
	}, [roleForEditing, initState]);

	function toggleGroupSelected(group) {
		let tmpGroups = [...myIotGroups];
		let foundGroup = tmpGroups.find(g => g.deviceId === group.deviceId);
		if (foundGroup) foundGroup.selected = !foundGroup.selected;

		// Check if all iot groups are selected, in which case set 'managesAllGroups' to true:
		const unselectedGroup = tmpGroups.find(g => g.selected === false);
		if (!unselectedGroup) {
			set_managesAllGroups(true);
		}

		set_myIotGroups(tmpGroups);
	}

	function title() {
		if (type === 'add') return 'Add Role';
		else return `Edit ${name}`;
	}

	async function actionButtonClicked() {
		hideModal();

		if (type === 'add') await handleAddRole(name, canManageUsers, managesAllGroups);
		else await handleEditRole(roleForEditing.id, name, canManageUsers, managesAllGroups);

		initState();
	}
	
	function cancelButtonClicked() {
		initState();
		hideModal();
	}

	function toggleManagesAllGroups() {
		if (!managesAllGroups) {
			// Select all iot groups: 
			let temp = [...myIotGroups];
			for (const group of temp) {
				group.selected = true;
			}
			set_myIotGroups(temp);
		} else {
			// Unselect all iot groups:
			let temp = [...myIotGroups];
			for (const group of temp) {
				group.selected = false;
			}

			set_myIotGroups(temp);
		}
		
		set_managesAllGroups(!managesAllGroups);
	}

	return (
		<Modal show={showModal} onHide={hideModal} centered className='addUserModal'>
			<Modal.Header closeButton>
				<Modal.Title>{title()}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group>
					<Form.Label>Name</Form.Label>
					<Form.Control placeholder='Enter Name' value={name} onChange={e => set_name(e.target.value)} />
				</Form.Group>

				<Form.Group className='formGroupCanEditUsers'>
					<Switch isOn={canManageUsers} handleToggle={() => { set_canManageUsers(!canManageUsers) }} labelText='Can Manage Users' />
				</Form.Group>

				{
					myIotGroups.length > 0 &&
					<Form.Group>
						<div>
							<Form.Label>Manages These Device Groups</Form.Label>
						</div>
						<Switch isOn={managesAllGroups} handleToggle={toggleManagesAllGroups} labelText='ALL' />
						<div>
							<Form.Label className={managesAllGroups ? 'disabled-element' : ''}>Or select specific groups:</Form.Label>
						</div>
						{
							myIotGroups.map(g =>
								<Form.Check key={g.deviceId} type='checkbox' label={g.groupName} disabled={managesAllGroups}
								className={managesAllGroups ? 'disabled-element' : ''} checked={g.selected} onChange={() => toggleGroupSelected(g)} />
							)
						}
					</Form.Group>
				}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={actionButtonClicked}>
					APPLY
				</Button>
				<Button variant="secondary" onClick={cancelButtonClicked}>
					CANCEL
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
