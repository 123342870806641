import React from 'react';
import { Form, Container, Button, Col } from 'react-bootstrap';
import ConfirmationDialog from '../ConfirmationDialog';
import PlusX from '../PlusX';

export default function DeviceManagementTab({ multipleEdit,
    showPowerOffDeviceConfirmation, showConfirmationPowerOffDevice, powerOffDeviceConfirmationResult,
    showRebootDeviceConfirmation, showConfirmationRebootDevice, rebootDeviceConfirmationResult,
    showRemoveDeviceConfirmation, showConfirmationRemoveDevice, removeDeviceConfirmationResult,
    showResetAllSettingsConfirmation, showConfirmationResetAllSettings, resetAllSettingsConfirmationResult,
    changeSelectedGroup, groupId, set_groupId, checked_groups, groups, setChecked_groups }) {


    function detachHeader() {
        if (multipleEdit) {
            return 'Detach devices from cloud';
        } else {
            return 'Detach device from cloud';
        }
    }

    function detachText() {
        if (multipleEdit) {
            return 'This will detach the selected devices from the AirServer Cloud Management';
        } else {
            return 'This will detach this device from the AirServer Cloud Management';
        }
    }

    function detachExtraQuestion() {
        if (multipleEdit) {
            return 'Also reset all settings on all selected devices';
        } else {
            return 'Also reset all settings on device';
        }
    }

    function plusXgroupsChecked(enabled) {
        setChecked_groups(enabled);
        if (enabled) {
            set_groupId(groups[0].deviceId)
        }
    }

    return (
        <Form>
            <Form.Label className='tabLabel'>Cloud Management</Form.Label>
            <Container className='toolsContainer'>

                <Form.Row>
                    <Form.Label>Group</Form.Label>
                </Form.Row>
                <Form.Row className='detailsRow'>
                    <Col xs='6' className='groupColumn'>
                        <Form.Control as="select" onChange={changeSelectedGroup} id='selChangeGroup' defaultValue={groupId}>
                            {multipleEdit && !checked_groups ? null : groups.map(group =>
                                <option key={group.deviceId} value={group.deviceId}>{group.groupName}</option>
                            )}
                        </Form.Control>
                    </Col>
                    <Col xs='6' className='plusX_groupCheck'>
                        <PlusX name='plusX_groups' checked={checked_groups} setChecked={plusXgroupsChecked} multipleEdit={multipleEdit} className='plusX_groups' />
                    </Col>
                </Form.Row>


                <Form.Row className='toolsButtonFirstRow'>
                    <Col xs='6'>
                        <Button id='toolsRebootButton' className='editButton toolsButton rebootDeviceButton' variant='primary' onClick={showRebootDeviceConfirmation} >
                            REBOOT DEVICE
                        </Button>
                        <ConfirmationDialog id='toolsRebootConfirm' show={showConfirmationRebootDevice} text={`Are you sure you want to reboot the device${multipleEdit ? 's' : ''}?`} result={rebootDeviceConfirmationResult} />
                    </Col>
                </Form.Row>
                <Form.Row className='toolsButtonRow'>
                    <Col xs='6'>
                        <Button className='editButton toolsButton powerOffDeviceButton' variant='primary' onClick={showPowerOffDeviceConfirmation} >
                            POWER OFF DEVICE
                        </Button>
                        <ConfirmationDialog show={showConfirmationPowerOffDevice} text={`Are you sure you want to power off the device${multipleEdit ? 's' : ''}?`} result={powerOffDeviceConfirmationResult} />
                    </Col>
                </Form.Row>


                {/*Reset all settings*/}
                <Form.Row className='toolsButtonRow'>
                    <Col xs='6'>
                        <Button className='editButton toolsButton resetSettingsButton' variant='primary' onClick={showResetAllSettingsConfirmation} >
                            RESET ALL SETTINGS
                    </Button>
                        <ConfirmationDialog show={showConfirmationResetAllSettings} header='Reset settings' text='This will reset all settings on the device to default. The device will still be attached to AirServer Cloud Management.' result={resetAllSettingsConfirmationResult} />
                    </Col>
                </Form.Row>
                <Form.Row className='toolsButtonRow'>
                    <Col xs='6'>
                        <Button className='editButton toolsButton removeDeviceButton' variant='primary' onClick={showRemoveDeviceConfirmation} >
                            DETACH FROM CLOUD
                    </Button>
                        <ConfirmationDialog show={showConfirmationRemoveDevice} header={detachHeader()} text={detachText()}
                            result={removeDeviceConfirmationResult} extraQuestion={detachExtraQuestion()} />
                    </Col>
                </Form.Row>

            </Container>
        </Form>
    );
}
