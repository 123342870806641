import React from 'react';
import { Form, Col } from 'react-bootstrap';
import AutofillStopper from '../AutofillStopper';
import PlusX from '../PlusX';
import Switch from '../Switch';
import InfoRow from './subComponents/InfoRow';

export default function NetworkTab({ networkReadOnly, multipleEdit, deviceInfo,
    checked_enterpriseSecurity, handleEnterpriseSecurityChange, enterpriseSecurity, setChecked_enterpriseSecurity, enterprisePassword, enterpriseUsername, set_enterprisePassword, set_enterpriseUsername,
    checked_proxy, handleChangeProxyHostname, handleProxyPortChange, proxyEnabled, proxyHostname, proxyHostnameValid, proxyPort, setChecked_proxy, set_proxyEnabled
}) {

    //ipSettings, checked_ipSettings, changeIpSettings, ipAddress, handleChangeIpAddress, ipAddressValid, netmask, handleChangeNetmask, netmaskValid, gateway, gatewayValid, handleChangeGateway, dns1, dns1Valid, handleChangeDns1, dns2, dns2Valid, handleChangeDns2,
    //wifiAdapterDetected, wifiEnabled, set_wifiEnabled, wifiStatus, wifiSsid, set_wifiSsid, wifiPassword, set_wifiPassword, wifiAuthUsername, set_wifiAuthUsername, wifiAuthPassword, set_wifiAuthPassword, wifiSecurityType, set_wifiSecurityType, activeIpv4WifiAddresses

	function formatIpv6(str) {
        return str.replace(/\s+/g, ",<br />\n");
    }

    return (
        <Form autoComplete='off'>
            <Form.Label className='tabLabel'>{networkReadOnly ? 'Network (read only)' : 'Network'}</Form.Label>

			{
				!multipleEdit &&
				<Form.Row className='networkBasicInfo'>
                    <Col>
                        <InfoRow label='Hostname' value={(deviceInfo && deviceInfo.hostName) || ''} />
                        <InfoRow label='IPv4 Address' value={(deviceInfo && (deviceInfo.activeIpv4EthernetAddresses || deviceInfo.activeIpv4Addresses)) || ''} />
                        <InfoRow label='IPv6 Address' value={(deviceInfo && deviceInfo.activeIpv6Addresses && formatIpv6(deviceInfo.activeIpv6Addresses)) || '' } />
                        <InfoRow label='Ethernet Mac Address' value={(deviceInfo && deviceInfo.ethernetMacAddress) || ''} />
                        <InfoRow label='Wi-Fi Mac address' value={(deviceInfo && deviceInfo.wifiMacAddress) || ''} />
						<InfoRow label='Wi-Fi IP Address' value={(deviceInfo && deviceInfo.activeIpv4WifiAddresses) || ''} />
					</Col>
				</Form.Row>
			}

            <Form.Row>
                <Col>
                    {/*802.1X Security*/}
                    <Form.Row>
                        <Col xs='6'>
                            <Form.Group controlId="form_security" as={Col} className='securityGroup'>
                                <Form.Label>802.1X Security</Form.Label>
                                
                                {/*Security None or Enterprise*/}
                                <Form.Row style={(multipleEdit && !checked_enterpriseSecurity) ? { display: 'none' } : null}>
                                    <Form.Group as={Col}>
                                        <Form.Control as='select' onChange={handleEnterpriseSecurityChange} value={enterpriseSecurity}>
                                            <option key='enterpriseSecurityOff' value='0'>None</option>
                                            <option key='enterpriseSecurityEnabled' value='1'>Enterprise</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>

                                {/* fake fields are a workaround for chrome autofill getting the wrong fields */}
                                <AutofillStopper />
                                {/*Proxy username and password*/}
                                <Form.Row style={enterpriseSecurity === 0 || (multipleEdit && !checked_enterpriseSecurity) ? { display: 'none' } : null}>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label style={enterpriseSecurity === 0 || (multipleEdit && !checked_enterpriseSecurity) ? { display: 'none' } : null}>Username</Form.Label>
                                            <Form.Control style={enterpriseSecurity === 0 || (multipleEdit && !checked_enterpriseSecurity) ? { display: 'none' } : null} type='text' value={enterpriseUsername}
                                                onChange={(e) => { set_enterpriseUsername(e.target.value) }} placeholder="Enter username" />
                                            </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Row style={enterpriseSecurity === 0 || (multipleEdit && !checked_enterpriseSecurity) ? { display: 'none' } : null}>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label style={enterpriseSecurity === 0 || (multipleEdit && !checked_enterpriseSecurity) ? { display: 'none' } : null}>Password</Form.Label>
                                            <Form.Control style={enterpriseSecurity === 0 || (multipleEdit && !checked_enterpriseSecurity) ? { display: 'none' } : null} type='password' value={enterprisePassword}
                                                onChange={(e) => { set_enterprisePassword(e.target.value) }} placeholder="Enter password" />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                        </Col>
                        <Col xs='6'>
                        {
                                networkReadOnly ? null : (<PlusX name='plusX_enterpriseSecurity' checked={checked_enterpriseSecurity} setChecked={setChecked_enterpriseSecurity} multipleEdit={multipleEdit} className='plusX_security' />)
                        }
                        </Col>
                    </Form.Row>

                    <Form.Row className="securityRow">
                        <Col className='switchCol' xs='6'>
                            <Switch isOn={proxyEnabled} handleToggle={() => { set_proxyEnabled(!proxyEnabled) }} labelText='Proxy' readonly={networkReadOnly} visible={!(multipleEdit && !checked_proxy)} />
                        </Col>
                        <Col xs='6'>
                            {
                                networkReadOnly ? null : (<PlusX name='plusX_proxy' checked={checked_proxy} setChecked={setChecked_proxy} multipleEdit={multipleEdit} className='plusX_proxy' />)
                            }
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col xs='6'>
                            <Form.Group>
                                <Form.Label style={!proxyEnabled || (multipleEdit && !checked_proxy) ? { display: 'none' } : null}>Hostname</Form.Label>
                                <Form.Control style={!proxyEnabled || (multipleEdit && !checked_proxy) ? { display: 'none' } : null} type='text' value={proxyHostname} onChange={(e) => handleChangeProxyHostname(e.target.value)}
                                    placeholder="proxy.example.com" readOnly={networkReadOnly} className={proxyHostnameValid ? '' : 'formIsInvalid'} />
                                {
                                    proxyHostnameValid ? null : (<Form.Label className='errorInput'>Please provide a valid url</Form.Label>)
                                }
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col xs='6'>
                            <Form.Group>
                                <Form.Label style={!proxyEnabled || (multipleEdit && !checked_proxy) ? { display: 'none' } : null}>Port</Form.Label>
                                <Form.Control style={!proxyEnabled || (multipleEdit && !checked_proxy) ? { display: 'none' } : null} type='text' value={proxyPort === 0 ? '' : proxyPort}
                                    onChange={(e) => { handleProxyPortChange(e.target.value) }} placeholder="8080" readOnly={networkReadOnly} />
                            </Form.Group>
                        </Col>
                    </Form.Row>

                </Col>
            </Form.Row>
        </Form>
    );
}
