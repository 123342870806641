import React, { useState, useEffect, Fragment } from 'react';
import Roles from "./Roles";
import Users from "./Users";
import UserAddEdit from "./UserAddEdit";
import RoleAddEdit from "./RoleAddEdit";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import ConfirmationDialog from '../ConfirmationDialog';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserHandler from "./UserHandler";
import RoleHandler from "./RoleHandler";
import IOTHandler from "./IOTHandler";
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, } from 'react-tippy';
import { faPen } from '@fortawesome/pro-regular-svg-icons';

export default function UserManagement() {
	const [roles, set_roles] = useState([]);
	const [users, set_users] = useState([]);
	const [error500, set_error500] = useState('');
	const [iotGroups, set_iotGroups] = useState([]);
	const [fetchedIotGroups, set_fetchedIotGroups] = useState(false);
	const [addOrEdit, set_addOrEdit] = useState('add');
	const [orgName, set_orgName] = useState('');
	const [orgNameForRenaming, set_orgNameForRenaming] = useState(null);
	const [isRenamingOrgName, set_isRenamingOrgName] = useState(false);

	const [userAddEditVisible, set_userAddEditVisible] = useState(false);
	const hideUserModal = () => {
		set_userAddEditVisible(false);
		set_userForEditing(null);
	}
	const showAddUserModal = () => {
		set_userAddEditVisible(true);
		set_addOrEdit('add');
		set_userForEditing(null);
	}
	const showEditUserModal = (user) => {
		set_userAddEditVisible(true);
		set_addOrEdit('edit');
		set_userForEditing(user);
	}

	const [showRemoveUserConfirmation, set_showRemoveUserConfirmation] = useState(false);
	const [userForRemoval, set_userForRemoval] = useState(null);
	const [userForEditing, set_userForEditing] = useState(null);

	const [roleAddEditVisible, set_roleAddEditVisible] = useState(false);
	const hideRoleModal = () => {
		set_roleAddEditVisible(false);
		set_roleForEditing(null);
	}
	const showAddRoleModal = () => {
		set_roleAddEditVisible(true);
		set_addOrEdit('add');
		set_roleForEditing(null);
	}
	const showEditRoleModal = (role) => {
		// Select the groups in iotGroups that are selected for 'roleForEditing':
		if (role) {	// Should always be true.
			let tmpGroups = [...iotGroups];

			for (const group of iotGroups) {
				const groupId = role.deviceGroupsManaged.find(d => d === group.deviceId);
				if (groupId) {
					group.selected = true;
				}
			}

			set_iotGroups(tmpGroups);
		}

		set_roleAddEditVisible(true);
		set_addOrEdit('edit');
		set_roleForEditing(role);
	}

	const [showRemoveRoleConfirmation, set_showRemoveRoleConfirmation] = useState(false);
	const [roleForRemoval, set_roleForRemoval] = useState(null);
	const [roleForEditing, set_roleForEditing] = useState(null);

	useEffect(() => {
		async function fetchAll() {
			const { organizationId, organizationName } = await UserHandler.fetchOrganizationData(set_error500);

			// Fetch all the data to display on the User Management page:
			UserHandler.fetchUserManagementData(set_roles, set_users, set_error500);

			// Simultaneously, fetch the list of all groups:
			IOTHandler.fetchGroups(set_iotGroups, set_error500, organizationId, set_fetchedIotGroups);

			set_orgName(organizationName);
		}
		
		fetchAll();
	}, []);

	async function handleAddUser(RoleId, Name, Email) {
		UserHandler.addUser(RoleId, Name, Email, users, set_users, hideUserModal, set_error500, roles);
	}

	async function handleEditUser(UserId, RoleId, Name, Email) {
		UserHandler.editUser(UserId, RoleId, Name, Email, users, roles, hideUserModal, set_error500, set_users);
	}

	function removeUserClick(user) {
		UserHandler.removeUserClick(user, set_showRemoveUserConfirmation, set_userForRemoval, users)
	}

	function removeUserConfirmationResult(result) {
		UserHandler.removeUserConfirmationResult(result, set_showRemoveUserConfirmation, set_userForRemoval, userForRemoval, users, set_users, set_error500);
	}

	function removeRoleClick(role) {
		RoleHandler.removeRoleClick(role, set_showRemoveRoleConfirmation, set_roleForRemoval, roles)
	}

	async function handleAddRole(name, canManageUsers, managesAllGroups) {
		RoleHandler.addRole(name, canManageUsers, managesAllGroups, iotGroups, set_error500, roles, set_roles);
	}

	async function handleEditRole(roleId, name, canManageUsers, managesAllGroups) {
		RoleHandler.editRole(roleId, name, canManageUsers, managesAllGroups, iotGroups, set_error500, set_roles, roles, users, set_users);
	}

	function removeRoleConfirmationResult(result) {
		RoleHandler.removeRoleConfirmationResult(result, set_showRemoveRoleConfirmation, set_roleForRemoval, roleForRemoval, roles, set_roles, set_error500);
	}

	function getRoleClassByName(role) {
		switch (role) {
			case 'Cloud Admin':
				return 'cloudAdmin';
			case 'East Building Admin':
				return 'eastBuildingAdmin';
			case 'West Building Admin':
				return 'westBuildingAdmin';
			default:
				return 'deviceAdmin';
		}
	}

	function startRenamingOrgName() {
		set_orgNameForRenaming(orgName);
		set_isRenamingOrgName(true);
	}

	function typingOrgName(orgNameValue) {
        set_orgNameForRenaming(orgNameValue);
    }

	function changeOrganizationName() {
		UserHandler.changeOrganizationName(orgName, set_orgName, orgNameForRenaming, set_orgNameForRenaming, set_isRenamingOrgName, set_error500);
	}

	function handleOrgKeyPress(e) {
        if (e.key === 'Enter') {
            changeOrganizationName();
        }
    }

	return (
		<div className='usersDiv'>
			<div className='spaceDiv'></div>
			{
				error500 &&
				<div className='errorMessage'><h3>Internal error, try again later...</h3></div>
			}
			<Form.Group className='orgNameFormGroup'>
				<span>
					{
						isRenamingOrgName === true ?
						<Form.Control type='text' value={orgNameForRenaming} onChange={(e) => { typingOrgName(e.target.value) }} maxLength='50'
							onBlur={() => { changeOrganizationName() }} autoFocus onFocus={(e) => e.target.select()} className='lblGroup'
							onKeyPress={(e) => { handleOrgKeyPress(e); }}
						/>
						:
						<h3 className='orgNameTitle'>{orgName}</h3>
					}
					<Tooltip title='Rename organization' position='bottom' trigger='mouseenter' arrow='true'>
						<span>
							<FontAwesomeIcon icon={faPen} size='lg' className={orgNameForRenaming === null ? 'hoverButton' : 'invisible'}
								onClick={() => startRenamingOrgName()} />
						</span>
					</Tooltip>
				</span>
			</Form.Group>
			{
				(roles.length === 0 || !fetchedIotGroups) ?
					<Loader
						type="TailSpin"
						color="#00BFFF"
						height={30}
						width={30}
						timeout={10000} // 10 secs
					/>
					:
					!error500 &&
					<Fragment>
						{/*Roles*/}
						<Roles roles={roles} handleShowAdd={showAddRoleModal} handleShowEdit={showEditRoleModal}
							getRoleClassByName={getRoleClassByName} removeRoleClick={removeRoleClick} iotGroups={iotGroups} />

						{/*Users*/}
						<Users users={users} handleShowAdd={showAddUserModal} handleShowEdit={showEditUserModal} removeUserClick={removeUserClick} />

						{/*AD Groups*/}
						{/*<ActiveDirectoryGroups aadGroups={aadGroups} handleShow={showAddUserModal} getRoleClassByName={getRoleClassByName} />*/}

						{/*Various modal popups and confirmation dialogs*/}
						<UserAddEdit showModal={userAddEditVisible} handleAddUser={handleAddUser} handleEditUser={handleEditUser} hideModal={hideUserModal}
							roles={roles} type={addOrEdit} userForEditing={userForEditing}/>

						<RoleAddEdit showModal={roleAddEditVisible} handleAddRole={handleAddRole} handleEditRole={handleEditRole} hideModal={hideRoleModal}
							iotGroups={iotGroups} type={addOrEdit} roleForEditing={roleForEditing} />

						<ConfirmationDialog show={showRemoveUserConfirmation} text={`Are you sure you want to remove ${userForRemoval && userForRemoval.name}?`}
							result={removeUserConfirmationResult} />

						<ConfirmationDialog show={showRemoveRoleConfirmation} text={`Are you sure you want to remove ${roleForRemoval && roleForRemoval.name}?`}
							result={removeRoleConfirmationResult} />
						
						<ToastContainer pauseOnFocusLoss={false} hideProgressBar={true} autoClose={3000} />
					</Fragment>
            }
		</div>
	);
}
