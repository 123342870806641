import { v4 as uuidv4 } from 'uuid';
import authService from '../api-authorization/AuthorizeService';
import axios from "axios";
import { toast } from 'react-toastify';

export default class RoleHandler {
	//TIMEOUT = 20000; // Timeout in milliseconds.

	static getGroupIds(managesAllGroups, iotGroups) {
		let groupIds = [];
		if (!managesAllGroups) {
			for (const group of iotGroups) {
				if (group.selected) {
					groupIds.push(group.deviceId);
				}
			}
		}
		return groupIds;
	}

	static async addRole(name, canManageUsers, managesAllGroups, iotGroups, set_error500, roles, set_roles) {
		// The front end creates the uuid for the role id:
		const roleId = uuidv4();

		// Create a list of the groups this role should manage:
		let groupIds = this.getGroupIds(managesAllGroups, iotGroups);

		try {
			// Call the backend:
			set_error500('');

			let token = await authService.getAccessToken();

			await axios({
				url: 'UserManagement/AddRole',
				method: 'POST',
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
				data: { roleId, name, canManageUsers, managesAllGroups, groupIds }
			});

			// Update the UI:
			const newRole = this.constructRole(roleId, name, canManageUsers, managesAllGroups, groupIds);
			let newRoles = [...roles, newRole];
			set_roles(newRoles.sort((r1, r2) => r1.name.localeCompare(r2.name)));

			toast.success(`Role ${name} created`);
		} catch (error) {
			if (error.response && error.response.status && error.response.status === 409) {
				toast.error('Role with that name already exists');
			} else {
				console.log('Unexpected error from AddRole: ' + JSON.stringify(error));
            }
		}

		//hideModal();
	}

	static removeRoleClick(role, set_showRemoveRoleConfirmation, set_roleForRemoval, roles) {
		if (role.canManageUsers && RoleHandler.noOtherCloudAdmin(role, roles)) {
			// The end user is about to remove the very last Cloud Admin role.
			// This is not allowed because then no one will be able to create new users.
			toast.error('There has to be at least one role that can manage users');
		} else {
			// Present confirmation about the user removal:
			set_showRemoveRoleConfirmation(true);
			set_roleForRemoval(role);
		}
	}

	static removeRoleConfirmationResult(result, set_showRemoveRoleConfirmation, set_roleForRemoval, roleForRemoval, roles, set_roles, set_error500) {
		set_showRemoveRoleConfirmation(false);
		if (result === true) {
			RoleHandler.removeRole(roleForRemoval, roles, set_roles, set_error500);
		}
		set_roleForRemoval(null);
	}

	static async removeRole(roleForRemoval, roles, set_roles, set_error500) {
		try {
			set_error500('');

			let token = await authService.getAccessToken();

			await axios({
				url: 'UserManagement/RemoveRole',
				method: 'DELETE',
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
				data: { RoleId: roleForRemoval.id }
			});

			// Remove role from 'roles':
			let tmp = [...roles];
			tmp = tmp.filter(r => r.id !== roleForRemoval.id);
			set_roles(tmp);
			toast.success(roleForRemoval.name + ' removed');
		} catch (error) {
			if (error.response && error.response.status && error.response.status === 409) {
				toast.error('Failed to remove role because it has users attached to it');
			} else {
				console.log('Unexpected error from RemoveRole: ' + JSON.stringify(error));
            }
		}
	}

	static noOtherCloudAdmin(role, roles) {
		return !roles.find(r => r.canManageUsers && r.id !== role.id);
	}

	static constructRole(roleId, name, canManageUsers, managesAllGroups, groupIds) {
		const retval = {
			id: roleId,
			name,
			deviceGroupsManaged: groupIds,
			canManageUsers,
			managesAllGroups
		};

		return retval;
	}

	static async editRole(roleId, name, canManageUsers, managesAllGroups, iotGroups, set_error500, set_roles, roles, users, set_users) {
		const tmpRoles = JSON.parse(JSON.stringify(roles)); // Deep copy.
		let prevRoleIndex = tmpRoles.findIndex(r => r.id === roleId);
		if (prevRoleIndex === -1) {
			console.log('Previous role found. Contact administrator');
			toast.error('Unexpected error happened. Please contact administrator');
		}
		let prevRole = tmpRoles[prevRoleIndex];

		if (prevRole.canManageUsers && !canManageUsers && RoleHandler.noOtherCloudAdmin(prevRole, roles)) {
			// This change is not allowed because there has to be at least one Cloud Admin.
			toast.error('There has to be at least one Cloud Admin');
			//hideModal();
			return;
		}

		let groupIds = this.getGroupIds(managesAllGroups, iotGroups);

		try {
			// Call the backend:
			set_error500('');

			let token = await authService.getAccessToken();

			await axios({
				url: `UserManagement/EditRole/${roleId}`,
				method: 'PUT',
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
				data: { name, canManageUsers, managesAllGroups, groupIds }
			});

			// Update the UI. First the roles:
			tmpRoles[prevRoleIndex] = { id: roleId, name, canManageUsers, managesAllGroups, deviceGroupsManaged: groupIds };
			set_roles(tmpRoles);

			// If the role name was changed, we have to update 'users' as well:
			if (prevRole.name !== name) {
				const tmpUsers = JSON.parse(JSON.stringify(users));; // Deep copy.
				for (const user of tmpUsers) {
					if (user.role.id === roleId) {
						user.role.name = name;
					}
				}
				set_users(tmpUsers);
			}

			toast.success(`Role ${name} was changed`);
		} catch (error) {
			if (error.response.status === 409) {
				toast.error('Role with that name already exists');
			} else if (error.response.status === 404) {
				toast.error(`Role ${name} not found in the database. Please contact administrator`);
			} else {
				console.log('Unexpected error from EditRole: ' + JSON.stringify(error));
				toast.error('Unexpected error happened');
            }
		}

		//hideModal();
	}
}
