import React from 'react';
import { Jumbotron, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPen, faUserFriends } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from 'react-tippy';

export default function Users({ users, handleShowAdd, handleShowEdit, removeUserClick }) {
	function getRoleClass(role) {
        switch (role) {
			case 0:
				return 'cloudAdmin';
			case 1:
				return 'eastBuildingAdmin';
			case 2:
				return 'westBuildingAdmin';
			default:
				return 'deviceAdmin';
        }
	}

	function getRoleText(role) {
		return role.name;
	}

	function getRoleCreated(user) {
		return new Date(user.created).toISOString().split('T')[0];
    }

	return (
		<Jumbotron>
			<Table className='usersTable'>
				<thead>
					<tr class="userPageHeaderRow">
						<td colSpan='4' className='titleTd'>
							<FontAwesomeIcon icon={faUserFriends} size='lg' />
							<h3>Users</h3>
						</td>
						<td colSpan='2' className='addUserTd'><Button variant='primary' className='addUserButton' onClick={() => handleShowAdd() }>ADD USER</Button></td>
					</tr>
					<tr>
						<th>NAME</th>
						<th>EMAIL</th>
						<th>ROLE</th>
						<th>CREATED</th>
						<th>EDIT</th>
						<th>REMOVE</th>
					</tr>
				</thead>
				<tbody>
					{
						users && users.map(user =>
							<tr key={user.name}>
								<td className='breakWord'>{user.name}</td>
								<td>{user.email}</td>
								<td className={getRoleClass(user.role)}>{getRoleText(user.role)}</td>
								<td>{getRoleCreated(user)}</td>
								<td>
									<Tooltip title={'Edit ' + user.name} position='bottom' trigger='mouseenter' arrow='true'>
										<span>
											<FontAwesomeIcon icon={faPen} size='lg' className='editUserButton' onClick={() => { handleShowEdit(user); }} />
										</span>
									</Tooltip>
								</td>
								<td>
									<Tooltip title={'Remove ' + user.name} position='bottom' trigger='mouseenter' arrow='true'>
										<span>
											<FontAwesomeIcon icon={faTrashAlt} size='lg' className='removeUserButton' onClick={() => removeUserClick(user) } />
										</span>
									</Tooltip>
								</td>
							</tr>
						)
					}
				</tbody>
			</Table>
		</Jumbotron>
	);
}
