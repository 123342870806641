import React, { useState } from 'react';
import { Form, Button } from "react-bootstrap";
import UserHandler from "./UserHandler";
import history from './History';
import Loader from 'react-loader-spinner';

function FirstLogin() {
	const [password, set_password] = useState('');
	const [passwordRetyped, set_passwordRetyped] = useState('');
	const [illegalPassword, set_illegalPassword] = useState(false);
	const [loggingIn, set_loggingIn] = useState(false);

	let search = window.location.search;
	let params = new URLSearchParams(search);
	let name = params.get('name');
	let email = params.get('email');
	let token = params.get('token');

	function succeeded() {
		history.push('./');
		window.location.reload();
	}

	function failed() {
		set_loggingIn(false);
		set_illegalPassword(true);
	}

	function clickLogin() {
		set_illegalPassword(false);
		set_loggingIn(true);
		UserHandler.confirmAccount(email, token, password, succeeded, failed);
	}

	return (
		<div className='firstLoginDiv'>
			<Form.Group>
				<h1>Welcome to Cloud Management, {name}</h1>
			</Form.Group>

			<Form.Group>
				<div>
					<Form.Label className='whitesmokeText'>Please create a password</Form.Label>
				</div>
				<div>
					<Form.Label className='whitesmokeText'>NOTE! Passwords have to contain both upper and lower case letters in addition to alphanumeric and special characters.</Form.Label>
				</div>
			</Form.Group>

			<Form.Group className='firstLoginPwGroup'>
				<Form.Control type='password' value={password} onChange={e => {set_password(e.target.value)}}></Form.Control>
			</Form.Group>

			<Form.Group className='firstLoginPwGroup'>
				<Form.Label className='whitesmokeText'>Re-type your password here</Form.Label>
				<Form.Control type='password' value={passwordRetyped} onChange={e => {set_passwordRetyped(e.target.value)}}></Form.Control>
			</Form.Group>

			<Form.Group>
				{
					loggingIn ?
					<Loader
						type="TailSpin"
						color="#00BFFF"
						height={30}
						width={30}
						timeout={10000} // 10 secs
					/>
					:
					<Button variant='primary' onClick={clickLogin}
							disabled={password.length === 0 || password !== passwordRetyped}>
						LOGIN
					</Button>
				}
			</Form.Group>

			{
				illegalPassword &&
				<Form.Group>
					<Form.Label className='errorText'>
						<strong>Passwords have to contain both upper and lower case letters in addition to alphanumeric and special characters.</strong>
					</Form.Label>
				</Form.Group>
			}
		</div>
	);
}

export default FirstLogin;
