import React from 'react';
import { Form, Col } from 'react-bootstrap';
import PlusX from '../PlusX';

export default function ScreenMirroringTab({ multipleEdit, airplayMode, set_airplayMode, airplayPassword, set_airplayPassword, checked_airplay, setChecked_airplay, googleCastMode, set_googleCastMode, checked_googleCast, setChecked_googleCast, miracastMode, set_miracastMode, checked_miracast, setChecked_miracast }) {

    function fillAirPlayOptions() {
        if (multipleEdit && !checked_airplay) return null;
        let retval = [];
        retval.push(<option key='airplayOff' value='0'>Off</option>);
        retval.push(<option key='airplayEveryone' value='1'>Everyone</option>);
        retval.push(<option key='airplayCode' value='2'>Code</option>);
        retval.push(<option key='airplayPassword' value='3'>Password</option>);
        return retval;
    }

    function fillGoogleCastOptions() {
        if (multipleEdit && !checked_googleCast) return null;
        let retval = [];
        retval.push(<option key='googleCastOff' value='0'>Off</option>);
        retval.push(<option key='googleCastEveryone' value='1'>Everyone</option>);
        retval.push(<option key='googleCastPrompt' value='2'>Prompt</option>);
        return retval;
    }

    function fillMiracastOptions() {
        if (multipleEdit && !checked_miracast) return null;
        let retval = [];
        retval.push(<option key='miracastOff' value='0'>Off</option>);
        retval.push(<option key='miracastEveryone' value='1'>Everyone</option>);
        retval.push(<option key='miracast8Code' value='2'>8-digit code</option>);
        retval.push(<option key='miracast4Code' value='3'>4-digit code</option>);
        return retval;
    }

    return (
        <Form>
            <Form.Group>
                <Form.Label className='tabLabel'>Screen Mirroring</Form.Label>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Label>AirPlay</Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Control as='select' onChange={(e) => set_airplayMode(Number(e.target.value))} value={airplayMode}>
                            {fillAirPlayOptions()}
                        </Form.Control>
                    </Col>
                    <Col xs='1'></Col>
                    <Col xs='5'>
                        <PlusX name='plusX_airplay' checked={checked_airplay} setChecked={setChecked_airplay} multipleEdit={multipleEdit} className='plusX_airplay' />
                    </Col>
                </Form.Row>
            </Form.Group>
            <Form.Group>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Control style={airplayMode === 3 ? null : { display: 'none' }} type="text" placeholder="AirPlay Password" value={airplayPassword} onChange={(e) => set_airplayPassword(e.target.value)} maxLength="32" />
                    </Col>
                    </Form.Row>
            </Form.Group>
            <Form.Group>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Label>Google Cast</Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Control as='select' onChange={(e) => { set_googleCastMode(e.target.value); }} value={googleCastMode}>
                            {fillGoogleCastOptions()}
                        </Form.Control>
                    </Col>
                    <Col xs='1'></Col>
                    <Col xs='5'>
                        <PlusX name='plusX_googleCast' checked={checked_googleCast} setChecked={setChecked_googleCast} multipleEdit={multipleEdit} className='plusX_googleCast' />
                    </Col>
                </Form.Row>
            </Form.Group>
            <Form.Group>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Label>Miracast</Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs='6'>
                        <Form.Control as='select' onChange={(e) => { set_miracastMode(e.target.value); }} value={miracastMode}>
                            {fillMiracastOptions()}
                        </Form.Control>
                    </Col>
                    <Col xs='1'></Col>
                    <Col xs='5'>
                        <PlusX name='plusX_miracast' checked={checked_miracast} setChecked={setChecked_miracast} multipleEdit={multipleEdit} className='plusX_miracast' />
                    </Col>
                </Form.Row>
            </Form.Group>
        </Form>
    );
}
